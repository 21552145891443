export const statuses = [
  { id: 1, label: "قيد الانتظار", englishLabel: "pending" },
  { id: 2, label: "نشط", englishLabel: "active" },
  { id: 3, label: "مدفوع", englishLabel: "paid" },
  { id: 4, label: "ملغي", englishLabel: "canceled" },
];


export const jopStatuses = [
  { id: 1, label: "قيد الانتظار", englishLabel: "Pending" },
  { id: 2, label: "مقبول", englishLabel: "Approved" },
  { id: 3, label: "مرفوض", englishLabel: "Declined" },
];