import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { BASE_URL } from "../../components/Axios/base_uri";
import toast from "react-hot-toast";
import TableLayout from "../../components/table";
import { Menu, add, edit, eyeOff, eyeOn } from "../../assets/svgIcons";
import PopUp from "../../components/popup";
import { Loader } from "rsuite";
import { Axios } from "../../components/Axios";
import UseGeneral from "../../customHooks/useGeneral";

const CategoryBorductsPage = () => {
  const { language } = UseGeneral();
  const navigate = useNavigate();
  const { id, category_id } = useParams();
  const [params] = useSearchParams();
  const [dataLoading, setDataLoading] = useState(false);
  const [catProds, setCatProds] = useState(null);

  const location = useLocation();
  const subCategoryData = location?.state?.subCategoryData;

  const [originalData, setOriginalData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [rowData, setRowData] = useState({});
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [changeStatusloading, setChangeStatusLoading] = useState(false);
  const [showAddCatPordModal, setShowAddCatPordModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [product_id, set_product_id] = useState({});
  const [loader, setLoader] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [productData, setProductData] = useState({});

  const [newProd, setNewProd] = useState({
    title_ar: "",
    title_en: "",
    description_ar: "",
    description_en: "",
    price: "",
    discount: "",
    hasOptions: 0,
    // images:image1**green**image2**green**image3,
    subcategory_id: id,
  });
  const [finalDraggedRow, setFinalDraggedRow] = useState(null);
  const [c_id, setId] = useState(null);
  const [draggedRow, setDraggedRow] = useState(null);
  useEffect(() => {
    if (finalDraggedRow && draggedRow) {
      const draggedIndex = catProds?.findIndex(
        (item) => item?.id == draggedRow?.id
      );
      const draggedFinalIndex = catProds?.findIndex(
        (item) => item?.id == finalDraggedRow?.id
      );
      const draggedID = catProds?.filter(
        (item) => item?.id == finalDraggedRow?.id
      )[0]?.id
      setArrangeNumber(c_id, draggedFinalIndex, draggedIndex);
      setArrangeNumber(draggedID, draggedFinalIndex+1);
    
    }
  }, [finalDraggedRow]);
  useEffect(() => {
    if (draggedRow) {
      setCurrentNumber(draggedRow?.number);
      setId(draggedRow?.id);
    }
  }, [draggedRow]);
  const setArrangeNumber = async (video_id, video_number, currentNumberTwo) => {
    // console.log(video_number)
    // console.log(video_id)
    // return
    const num = currentNumberTwo ? currentNumberTwo : currentNumber;
    setLoader(true);
    if (video_number == num) {
      // alert(video_number, num);
      return setLoader(false);
    }

    await Axios({
      method: "POST",
      url: "products/change_product_number/" + video_id,
      data: { number: video_number },
    })
      .then(async (response) => {
        console.log(response);
        toast.success(response?.message);
        getCategoryProds();
        setFinalDraggedRow(null);
        setDraggedRow(null);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoader(false);
      });
  };
  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const catProdsHeader = [
    {
      label: language == 'ar' ? "اسم المنتج" : "Product name",
      dataIndex: language == 'ar' ? "title_ar" : "title_en",
    },
    // {
    //   label: "Number",
    //   dataIndex: "number",
    //   search: true,
    //   sort: true,
    //   type: "children",
    //   children: ({ headers, row }) => {
    //     const handleBlur = async (product_id, product_number) => {
    //       set_product_id(null);
    //       setArrangeNumber(product_id, product_number);
    //     };

    //     const handleFocus = () => {
    //       setCurrentNumber(row?.number);
    //       set_product_id(row?.id);
    //     };

    //     const handleClick = () => {
    //       set_product_id(row?.id);
    //     };

    //     return (
    //       <div
    //         style={{ width: "fit-content", cursor: "pointer" }}
    //         onClick={handleClick}
    //       >
    //         <input
    //           style={{ width: "120px", cursor: "pointer" }}
    //           type="text"
    //           onChange={(e) =>
    //             setProductData({ id: row?.id, number: e.target.value })
    //           }
    //           onFocus={() => handleFocus()}
    //           defaultValue={
    //             productData?.id == row?.id ? productData?.number : row?.number
    //           }
    //           value={
    //             productData?.id == row?.id ? productData?.number : row?.number
    //           }
    //           disabled={loader}
    //           className={
    //             product_id === row?.id
    //               ? "MR_input_form_element active"
    //               : "MR_input_form_element lazy"
    //           }
    //           onBlur={async (e) => {
    //             await handleBlur(row?.id, e.target.value);
    //           }}
    //         />
    //       </div>
    //     );
    //   },
    // },
    {
      label: language == 'ar' ? "سعر المنتج" : "Product Price",
      dataIndex: "price",
    },
    {
      label: language == 'ar' ? "الخصم" : "Discount",
      dataIndex: "discount",
    },
    // {
    //   label: "وقت الانشاء",
    //   dataIndex: "time",
    //   type: "children",
    //   children: ({ row }) => {
    //     return <div>{getOlnlyDate(row.created_at)}</div>;
    //   },
    // },

    {
      label: language == 'ar' ? "الحالة" : "Status",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
          >
            {row.hidden == "0"
              ? language == 'ar'
                ? "ظاهر"
                : "Showen"
              : language == 'ar'
              ? "مخفي"
              : "Hidden"}
          </div>
        );
      },
    },
    {
      label: language == 'ar' ? " أوامر" : "actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${
                row.hidden == "0" ? "text-success" : "text-danger"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              <button className="btn btn-success">
                {row.hidden == "0"
                  ? language == "ar"
                    ? "إخفاء"
                    : "Hide"
                  : language == "ar"
                  ? "إظهار"
                  : "Show"}
              </button>
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                // setUpdateModal(true);
                // setImgUrl(row.image)
                // console.log(row.image);
                window.open(`/editporduct/${row.id}`, "_blanck", "noopener");
              }}
            >
              <button className="btn btn-success">
                {language == "ar" ? "تعديل" : "Edit"}
              </button>
            </div>
            {/* <div
              className='text-primary'
              style={{cursor: "pointer"}}
              onClick={() => {
                console.log(row);
              }}
            >
             <button className="btn btn-success">{language =="ar" ?"عرض":"View"}</button>
            </div> */}
          </div>
        );
      },
    },
  ];

  const handleShow_hide = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    const dataset = {
      id: rowData.id,
    };

    setChangeStatusLoading(true);

    await axios
      .post(`${BASE_URL}products/update_status?${token}`, dataset)
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success(
            `تم ${rowData.hidden == "0" ? " إخفاء " : " إظهار "} المنتج بنجاح`
          );
          getCategoryProds();
        } else if (res.data.status == "error") {
          toast.error("هناك مشكلة ! حاول مجدداً");
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      });
  };

  const getCategoryProds = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}subcategories/subcategory_products/${id}?${token}`)
      .then((res) => {
        if (res?.data && res.data.status == "success") {
          setCatProds(
            res?.data?.result
              ?.filter((item) => item?.hidden == "0")
              ?.sort((a, b) => a.number - b?.number)
          );
          setOriginalData(
            res?.data?.result
              ?.filter((item) => item?.hidden == "0")
              ?.sort((a, b) => a.number - b?.number)
          );
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getCategoryProds();

    console.log(subCategoryData);
  }, []);

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5>
              {language == 'ar'
                ? 'منتجات للفئة الفرعية'
                : 'Products For Subcategory'}{" "}
              (
              {language == 'ar'
                ? subCategoryData?.title_ar
                : subCategoryData?.title_en}
              )
            </h5>
            <div
              className="btn btn-success"
              onClick={() =>
                navigate('/addproduct', {
                  state: {
                    subCat: id,
                    subCatName:
                      language == 'ar'
                        ? subCategoryData?.title_ar
                        : subCategoryData?.title_en,
                    category_id: category_id,
                    type: "sub",
                  },
                })
              }
            >
              {language != "ar" ? "Add New Product" : "إضافة منتج جديد"}
            </div>{" "}
          </div>

          <div className="searchInput field_input">
            <input
              type="text"
              placeholder={language == 'ar' ? "بحث..." : 'Search...'}
              onChange={(e) => {
                setSearchValue(e.target.value?.toLowerCase());
              }}
            />
          </div>

          {dataLoading ? (
            <Loader />
          ) : (
            <TableLayout
              headers={catProdsHeader}
              data={catProds}
              finalDraggedRow={finalDraggedRow}
              setFinalDraggedRow={setFinalDraggedRow}
              draggedRow={draggedRow}
              setDraggedRow={setDraggedRow}
            />
          )}
        </div>
      </div>

      <PopUp
        open={showAddCatPordModal}
        setOpen={setShowAddCatPordModal}
        title={"إضافة منتج لهذة الفئة "}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // handleAddNewSubCat();
            }}
          >
            <div className="inputs ">
              <div className="field_input">
                <label htmlFor="">اسم المنتج باللغة العربية</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">اسم المنتج باللغة الإنجليزية</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">وصف المنتج باللغة العربية</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      description_ar: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="field_input">
                <label htmlFor="">وصف المنتج باللغة الإنجليزية</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      description_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">سعر المنتج </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      price: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">قيمة الخصم</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      discount: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="">
                <label htmlFor="options" className="ms-3">
                  له إضافات؟
                </label>
                <input
                  id="options"
                  type="checkbox"
                  onChange={(e) => {
                    console.log(e.target.checked);
                    setNewProd({
                      ...newProd,
                      hasOptions: e.target.checked ? 1 : 0,
                    });
                  }}
                />
              </div>
            </div>

            <button className="popup_agree_btn">
              {addLoading ? <Loader /> : "إضافة"}
            </button>
          </form>
        }
      />
      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={
          language == 'ar' ? "تغيير حالة الظهور" : "Change Appearance Status"
        }
        children={
          <div className="">
            <h5 className="">
              {language == 'ar' ? "هل تريد بالفعل " : "Do You Really Want To "}
              {rowData.hidden == "0"
                ? language == 'ar'
                  ? " إخفاء "
                  : " Hide "
                : language == 'ar'
                ? " إظهار "
                : ' Show '}
              {language == 'ar' ? ' هذا المنتج ' : ' This Product '}
            </h5>

            <button
              onClick={() => handleShow_hide()}
              className="popup_agree_btn"
            >
              {changeStatusloading ? (
                <Loader />
              ) : language == 'ar' ? (
                "تأكيد"
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        }
      />
    </>
  );
};

export default CategoryBorductsPage;
