import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Loader } from "rsuite";
import { add, edit, eyeOff, eyeOn } from "../../assets/svgIcons";
import { BASE_URL } from "../../components/Axios/base_uri";
import PopUp from "../../components/popup";
import TableLayout from "../../components/table";
import UseGeneral from "../../customHooks/useGeneral";

const CategoryProducts = () => {
  const navigate = useNavigate();
  const { language } = UseGeneral();

  const { category_id } = useParams();
  const [dataLoading, setDataLoading] = useState(false);
  const [catProds, setCatProds] = useState(null);

  const location = useLocation();
  const subCategoryData = location?.state?.subCategoryData;
  const handleShow_hide = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    const dataset = {
      id: rowData.id,
    };

    setChangeStatusLoading(true);

    await axios
      .post(`${BASE_URL}products/update_status?${token}`, dataset)
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success(
            `تم ${rowData.hidden == "0" ? " إخفاء " : " إظهار "} المنتج بنجاح`
          );
          getCategoryProds();
        } else if (res.data.status == "error") {
          toast.error("هناك مشكلة ! حاول مجدداً");
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      });
  };
  const [originalData, setOriginalData] = useState(null);
  const [searchValue,  setSearchValue] = useState("");
  const [rowData, setRowData] = useState({});
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [showAddCatPordModal, setShowAddCatPordModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [hasOptions, setHasOptions] = useState(0);

  const [newProd, setNewProd] = useState({
    title_ar: "",
    title_en: "",
    description_ar: "",
    description_en: "",
    price: "",
    discount: "",
    hasOptions: 0,
    // images:image1**green**image2**green**image3,
    category_id: category_id,
  });

  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const catProdsHeader = [
    {
      label: "اسم المنتج",
      dataIndex: language == 'ar' ? "title_ar" : "title_en",
    },
    {
      label: "سعر المنتج",
      dataIndex: "price",
    },
    {
      label: "الخصم",
      dataIndex: "discount",
    },

    {
      label: "الخصم",
      dataIndex: "discount",
    },
    // {
    //   label: "وقت الانشاء",
    //   dataIndex: "time",
    //   type: "children",
    //   children: ({ row }) => {
    //     return <div>{getOlnlyDate(row.created_at)}</div>;
    //   },
    // },

    {
      label: "الحالة",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
          >
            {row.hidden == "0" ? "ظاهر" : "مخفي"}
          </div>
        );
      },
    },
    {
      label: " أوامر",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${
                row.hidden == "0" ? "text-success" : "text-danger"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              <button className="btn btn-success">
                {row.hidden == "0"
                  ? language == "ar"
                    ? "إخفاء"
                    : "Hide"
                  : language == "ar"
                  ? "إظهار"
                  : "Show"}
              </button>
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                // setUpdateModal(true);
                // setImgUrl(row.image)
                // console.log(row.image);
                window.open(`/editporduct/${row.id}`, "_blanck", "noopener");
              }}
            >
              <button className="btn btn-success">{language =="ar" ?"تعديل":"Edit"}</button>
            </div>
            {/* <div
              className='text-primary'
              style={{cursor: "pointer"}}
              onClick={() => {
                console.log(row);
              }}
            >
             <button className="btn btn-success">{language =="ar" ?"عرض":"View"}</button>
            </div> */}
          </div>
        );
      },
    },
  ];

  const getCategoryProds = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    setDataLoading(true);
    await axios
      .post(
        `${BASE_URL}categories/get_main_category_product_by_category_id/${parseInt(
          category_id
        )}?${token}`,
        {
          category_id: parseInt(category_id),
          id: parseInt(category_id),
          user_id: 1,
        }
      )
      .then((res) => {
        if (res?.data && res.data.status == "success") {
          setCatProds(res?.data?.result?.filter((item) => item?.hidden == "0"));
          setOriginalData(
            res?.data?.result?.filter((item) => item?.hidden == "0")
          );
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getCategoryProds();

    console.log(subCategoryData);
  }, []);

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            {/* <div
              onClick={() =>
                navigate('/addproduct', {
                  state: {
                    subCat: undefined,
                    category_id: category_id,
                    subCatName: subCategoryData?.title_ar,
                    type: "main",
                  },
                })
              }
            >
              {add}
            </div> */}
          </div>

          <div className="searchInput field_input">
            <input
              type="text"
              placeholder="بحث..."
              onChange={(e) => {
                setSearchValue(e.target.value?.toLowerCase());
              }}
            />
          </div>

          {dataLoading ? (
            <Loader />
          ) : (
            <TableLayout headers={catProdsHeader} data={catProds} />
          )}
        </div>
      </div>

      <PopUp
        open={showAddCatPordModal}
        setOpen={setShowAddCatPordModal}
        title={"إضافة منتج لهذة الفئة "}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // handleAddNewSubCat();
            }}
          >
            <div className="inputs ">
              <div className="field_input">
                <label htmlFor="">اسم المنتج باللغة العربية</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">اسم المنتج باللغة الإنجليزية</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">وصف المنتج باللغة العربية</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      description_ar: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="field_input">
                <label htmlFor="">وصف المنتج باللغة الإنجليزية</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      description_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">سعر المنتج </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      price: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">قيمة الخصم</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      discount: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="">
                <label htmlFor="options" className="ms-3">
                  له إضافات؟
                </label>
                <input
                  id="options"
                  type="checkbox"
                  onChange={(e) => {
                    console.log(e.target.checked);
                    setNewProd({
                      ...newProd,
                      hasOptions: e.target.checked ? 1 : 0,
                    });
                  }}
                />
              </div>
            </div>

            <button className="popup_agree_btn">
              {addLoading ? <Loader /> : "إضافة"}
            </button>
          </form>
        }
      />
      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={"تغيير حالة الظهور"}
        children={
          <div className="">
            <h5 className="">
              هل تريد بالفعل {rowData.hidden == "0" ? " إخفاء " : " إظهار "} هذه
              الفئة؟
            </h5>

            <button
              onClick={() => handleShow_hide()}
              className="popup_agree_btn"
            >
              {changeStatusLoading ? <Loader /> : "تأكيد"}
            </button>
          </div>
        }
      />
    </>
  );
};

export default CategoryProducts;
