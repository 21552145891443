import axios from "axios";
import TableLayout from "../../components/table";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import UseGeneral from "../../customHooks/useGeneral";
import PopUp from "../../components/popup";
import { generateRandomString } from "../../utils";
// import { generateRandomString } from "../../utils";

export default function Coupons() {
    const language = UseGeneral();
    const [randomValue , setRandomValue]=useState("IQ-")
    const [isLoading , setIsLoading] = useState(false);
    const [allCoupons , setAllCoupons] = useState([]);
    const [originalData , setOriginalData] = useState([]);
    const [searchValue , setSearchValue] = useState("");
    const [isShowAddModal , setIsShowAddModal] = useState(false);
    const [isShowEditModal , setIsShowEditModal] = useState(false);
    const [isShowDeleteModal , setIsShowDeleteModal] = useState(false);
    const [rowData , setRowData] = useState({});
    const [delItem , setDelItem] = useState(null);
    const [couponData , setCouponData] = useState({
        coupon_code:"",
        type:"Fixed",
        value:"",
        expiry_date:"",
    })

  

    const headers = [
        {
            label:"#",
            dataIndex:"id",
        },
        {
            label:"Coupon code",
            dataIndex:"coupon_code",
        },
        {
            label:"Type",
            dataIndex:"type_coupon",
        },
        {
            label:"Value",
            dataIndex:"value",
        },
        {
            label:"Creation Date",
            dataIndex:"createdAt",
            type:"children",
            children : ({row}) => {
                return (
                    <p>{row.createdAt}</p>
                )
            }
        },
        {
            label:"Expiration Date",
            dataIndex:"expiry_date",
            type:"children",
            children : ({row}) => {
                return (
                    <p>{row.expiry_date}</p>
                )
            }
        },
        {
            label:"أوامر",
            type:"children",
            children : ({row}) => {
              return (
                <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                    <button onClick={() => {
                      setDelItem(row.id)
                      setIsShowDeleteModal(true);
                    }} style={{backgroundColor :"red"  , color:"white" , borderRadius:"5px"}}>
                    <svg style={{width:"20px" , height:"15px"}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="size-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
      </svg>
                    </button>  

                     <button onClick={() => {
                      setRowData(row)
                      setIsShowEditModal(true);
                    }}style={{backgroundColor:"#146c43",color:"white" , borderRadius:"5px"}}>
                    <svg style={{width:"20px" , height:"15px"}}  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
</svg>

                    </button>    
                </div>
              )
            }
          }
    ]

    function handleGetAllCoupons() {
        setIsLoading(true);
        axios.get(' https://api.camp-coding.site/coupons/findall')
        .then(res => {
           if(res.status == 200) {
            setAllCoupons(res.data);
            setOriginalData(res.data);
           }
           else {
            toast.error(language == "ar" ?"حدث خطأ ما" : "Something went wrong!")
           }
        })
        .finally(() => {
            setIsLoading(false);
        })
        .catch(e => console.log(e))
    }

    useEffect(() => {
        handleGetAllCoupons();
    } , [])

    useEffect(() => {
        console.log(originalData);
        if(searchValue.length > 0) {
            setAllCoupons(originalData.filter(data => data.coupon_code.toLowerCase().includes(searchValue) || data.type_coupon.toLowerCase().includes(searchValue)))
           return;
        }
        else {
            setAllCoupons(originalData);
        }
    } , [searchValue])


    function handleAutoGenerateCode() {
        const randomString = generateRandomString(8); // Generate 7-character string
    const randomDigit = Math.floor(Math.random() * 10); // Generate random digit (0-9)
    const newValue = `IQ-${randomString}${randomDigit}`;
    console.log(newValue);
    setCouponData({...couponData , coupon_code : newValue})
    setRowData({...rowData , coupon_code : newValue})
    }

    function handleSubmit(e) {
        e.preventDefault();
        const data_send = {
            ...couponData
        }
        axios.post('https://api.camp-coding.site/coupons/create', data_send)
        .then(res => {
            if(res.status == 201) {
                toast.success(language == "ar" ? "تم اضافه الكوبون بنجاح" :"Added Coupon Successfully")
                setIsShowAddModal(false);
                setCouponData({
                    coupon_code : "",
                    value:"",
                    type:"Fixed",
                    expiry_date:"",
                })
            }
            else {
                toast.error(language == "ar" ? "Something went wrong!" : "حدث خطأ ما!")
            }
        })
        .finally(() => {
            setIsLoading(false);
        })
        .catch(e => console.log(e))
    }    

   function handleDelete() {
     axios.get(`https://api.camp-coding.site/coupons/delete/${delItem}`)
     .then(res => { 
     if(res.status == 200) {
        toast.success(language == "ar" ? "تم حذف العنصر بنجاح " :"Item Deleted Successfully!")
        handleGetAllCoupons();
        setIsShowDeleteModal(false);
     }else {
        toast.error(language == "ar" ? "حدث خطأ ما" : "Something went wrong!")
     }
     }).catch(e => console.log(e))
   }

   function handleEdit(e) {
    e.preventDefault();
     const data_send = {
        ...rowData,
        type:rowData.type_coupon
     }
     setIsLoading(true);
     axios.post(`https://api.camp-coding.site/coupons/update/${rowData.id}`,data_send)
     .then(res => {
        if(res.status == 201) {
            toast.success("Coupon Edited successfully");
            setIsShowEditModal(false);
            handleGetAllCoupons();
        }else {
            toast.error(language == "ar" ? "حدث خطأ ما!":"something went wrong")
        }
     })
     .finally(() => {
        setIsLoading(false);
     })
     .catch(e => console.log(e))
   }

    
  return (
    <div className="rowDiv flex-2-1 page_padding">
        <div>
        <div className="title_add">
            <h5>{language=='ar'?"كوبون":"Coupons"}</h5>
            <div onClick={() => setIsShowAddModal(true)} className="btn btn-success">{language == 'ar' ? 'إضافة كوبون' : 'Add Coupon'}</div>
          </div>
        </div>

        <div className="searchInput field_input">
             <input
              type="text"
              placeholder={language == "ar" ? "بحث..." : "Search"}
              onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            />
          </div>

        <PopUp title={language =="ar" ? "اضافه كوبون":"Add Coupon"} open={isShowAddModal} setOpen={setIsShowAddModal}>
            <form onSubmit={handleSubmit}>
                 <div className="inputs">
                    <div className="field_input">
                        <label>{language == "ar" ? "": "Enter or automatically generate random value"}</label>
                        <div style={{display:"flex" ,gap:"5px",alignItems:"center"}}>
                        <input type="text" value={couponData.coupon_code} onChange={(e) => setCouponData({...couponData , coupon_code : e.target.value})} placeholder="Enter or automatically generate random value"/>
                        <span style={{backgroundColor:"#009418",color:"white",borderRadius :"7px",width:"150px",padding:"5px 10px" , cursor:"pointer"}} onClick={handleAutoGenerateCode}>Auto Generate</span>
                        </div>
                    </div>

                    <div className="field_input">
                        <label>{language == "ar" ?"النوع" :"Type"}</label>
                        <select onChange={(e) => setCouponData({...couponData , type : e.target.value})}>
                            <option value="Fixed">Fixed</option>
                            <option value="Percentage">Percentage</option>
                        </select>
                    </div>

                    <div className="field_input">
                        <label>{language == "ar" ? "القيمه (%)" :"Value (%)"}</label>
                        <input type="number" onChange={(e) => setCouponData({...couponData , value : Number(e.target.value)})}/>
                    </div>

                    <div className="field_input">
                        <label>{language == "ar" ?"تاريخ الانتهاء" :"Expiration Date"}</label>
                        <input type="date" onChange={(e) => setCouponData({...couponData,expiry_date : e.target.value})}/>
                    </div>

                    <div style={{display:"flex" , gap:"10px"}}>
                        <button type="submit" className='popup_agree_btn'>Add</button>
                        <button  onClick={() => setIsShowAddModal(false)} style={{padding:"5px 10px" , marginTop :"20px" ,borderRadius:"3px"}}>Cancel</button>
                        </div>
                 </div>
            </form>
        </PopUp>

        <PopUp title={language =="ar" ? "تعديل كوبون":"Edit Coupon"} open={isShowEditModal} setOpen={setIsShowEditModal}>
            <form onSubmit={handleEdit}>
                 <div className="inputs">
                    <div className="field_input">
                        <label>{language == "ar" ? "": "Enter or automatically generate random value"}</label>
                        <div style={{display:"flex" ,gap:"5px",alignItems:"center"}}>
                        <input type="text" value={rowData.coupon_code} onChange={(e) => setRowData({...rowData , coupon_code : e.target.value})} placeholder="Enter or automatically generate random value"/>
                        <span style={{backgroundColor:"#009418",color:"white",borderRadius :"7px",width:"150px",padding:"5px 10px" , cursor:"pointer"}} onClick={handleAutoGenerateCode}>Auto Generate</span>
                        </div>
                    </div>

                    <div className="field_input">
                        <label>{language == "ar" ?"النوع" :"Type"}</label>
                        <select  value={rowData.type} onChange={(e) => setRowData({...rowData , type_coupon : e.target.value})}>
                            <option value="Fixed">Fixed</option>
                            <option value="Percentage">Percentage</option>
                        </select>
                    </div>

                    <div className="field_input">
                        <label>{language == "ar" ? "القيمه (%)" :"Value (%)"}</label>
                        <input type="number" value={rowData.value} onChange={(e) => setRowData({...rowData , value : Number(e.target.value)})}/>
                    </div>

                    <div className="field_input">
                        <label>{language == "ar" ?"تاريخ الانتهاء" :"Expiration Date"}</label>
                        <input type="date" value={rowData.expiry_date} onChange={(e) => setRowData({...rowData,expiry_date : e.target.value})}/>
                    </div>

                    <div style={{display:"flex" , gap:"10px"}}>
                        <button type="submit" className='popup_agree_btn'>Edit</button>
                        <button  onClick={() => setIsShowEditModal(false)} style={{padding:"5px 10px" , marginTop :"20px" ,borderRadius:"3px"}}>Cancel</button>
                        </div>
                 </div>
            </form>
        </PopUp>
     

        <PopUp open={isShowDeleteModal} setOpen={setIsShowDeleteModal}>
        <div style={{display:"flex" , flexDirection:"column" , justifyContent:"center"}}>
                <img style={{width:"120px" , height:"120px" , margin:"5px auto"}} src="/warning.png"/>
                 <h4 style={{textAlign:"center"}}>{language == "ar" ? "هل تريد مسح هذا العنصر؟":"Are you sure you want to delete this item?"}</h4>
                 <div style={{display:"flex",margin:"0px auto" , gap:"10px" , justifyContent:"between"}}>
                        <button onClick={() => setIsShowDeleteModal(false)} style={{padding:"5px 10px", color:"white" , marginTop :"20px" ,borderRadius:"3px",backgroundColor:"#0388CC"}}>Cancel</button>
                        <button style={{padding:"5px 10px", color:"white" , marginTop :"20px" ,borderRadius:"3px",backgroundColor:"red"}} onClick={handleDelete}>Delete</button>
                        </div>
                </div>
        </PopUp>
        <TableLayout headers={headers} data={allCoupons} />
    </div>
  )
}
