import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Loader } from "rsuite";
import { Axios } from "../../components/Axios";
import { BASE_URL } from '../../components/Axios/base_uri';
import PopUp from "../../components/popup";
import TableLayout from "../../components/table";
import UseGeneral from "../../customHooks/useGeneral";
import { calculateAge, formatDate } from "../../utils";
import "./style.css";
import EditJobForm from "./editJop";
import axios from "axios";

const JopsManagment = () => {
  const [jopData, setJopData] = useState(null);
  const { language } = UseGeneral();
  const [openEdit, setOpenEdit] = useState(false);
  const [dateFilters, setDateFilters] = useState({
    fromDate: null,
    toDate: null,
  });
  const [jopStatus, setJopStatus] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState({
    oldStatus: {
      id: null,
      status: null,
    },
    newStatus: {
      id: null,
      status: null,
    },
  });
  const [open, setOpen] = useState({ open: false });
  const [loading, setLoading] = useState({
    change: false,
  });
  const [jops, setJops] = useState(null);
  const [filteredJops, setFilteredJops] = useState(null);
  const [newJopOpen, setNewJopOpen] = useState(false);

  const [newJopData, setNewJopData] = useState({
    job_name_ar: "",
    job_name_en: "",
    cv_required: false,
    location: "",
    questions: [],
    multiple_choices: [],
    multiple_question_multi: [],
  });

  const headers = [
    { label: "Job Number", dataIndex: "id" },
    { label: "Location", dataIndex: "location" },
    { label: "Job Name (Arabic)", dataIndex: "name_ar" },
    { label: "Job Name (English)", dataIndex: "name_en" },
    { label: "CV Required", dataIndex: "cvRequired" },
    { label: "Active", dataIndex: "active" },
    { label: "Created At", dataIndex: "CreatedAt" },
    { label: "Updated At", dataIndex: "updatedAt" },
    {
      label: "Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="rowDiv">
            <button
              className="btn btn-primary"
              onClick={() => {
                setJopData(row);
                setOpenEdit(row);
              }}
            >
              تعديل
            </button>
          </div>
        );
      },
    },
  ];

  const ProductsHeaders = [
    {
      label: language == 'ar' ? "رقم المنتج" : "Product Number",
      dataIndex: "id",
    },
    {
      label: language == 'ar' ? "اسم المنتج" : "Product Name",
      type: "children",
      children: ({ row }) => {
        return <p className="text-dark">{row?.product?.title_ar}</p>;
      },
    },
    {
      label: language == 'ar' ? "الكمية المطلوبة " : "Required Quantity",
      dataIndex: "product_count",
    },
    {
      label: language == 'ar' ? "سعر المنتج " : "Product Price",
      dataIndex: "product_price",
    },
  ];

  useEffect(() => {
    getJops();
  }, []);

  const getJops = async () => {
    Axios({ method: "GET", url: "careers/jobmanagement/findall" })
      .then((res) => {
        setJops(res?.reverse());
        setFilteredJops(res?.reverse());
      })
      .catch((err) => err);
  };

  const changeStatus = () => {
    setLoading({ ...loading, change: true });
    Axios({
      method: "GET",
      url:
        "careers/jobapplication/update/" +
        status?.newStatus?.id +
        "/" +
        status?.newStatus?.status,
      data: {},
    })
      .then((res) => {
        toast.success(res);
        getJops();
        setOpen(null);
      })
      .finally(() => setLoading({ ...loading, change: false }));
  };

  const handleNewJopChange = (e) => {
    const { name, value } = e.target;
    setNewJopData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleQuestionChange = (e, index) => {
    const { value } = e.target;
    setNewJopData((prevState) => {
      const updatedQuestions = [...prevState.questions];
      updatedQuestions[index] = value;
      return { ...prevState, questions: updatedQuestions };
    });
  };

  const removeQuestion = (index) => {
    setNewJopData((prevState) => {
      const questions = [...prevState.questions];
      questions.splice(index, 1);
      return { ...prevState, questions };
    });
  };

  const addQuestion = () => {
    setNewJopData((prevState) => ({
      ...prevState,
      questions: [...prevState.questions, ""],
    }));
  };

  const handleMultipleChoiceQuestionChange = (e, index) => {
    const { value } = e.target;
    setNewJopData((prevState) => {
      const multiple_choices = [...prevState.multiple_choices];
      multiple_choices[index].question = value;
      return { ...prevState, multiple_choices };
    });
  };

  const handleChoiceChange = (e, index, choiceIndex) => {
    const { value } = e.target;
    setNewJopData((prevState) => {
      const multiple_choices = [...prevState.multiple_choices];
      multiple_choices[index].choices[choiceIndex] = value;
      return { ...prevState, multiple_choices };
    });
  };

  const removeChoice = (index, choiceIndex) => {
    setNewJopData((prevState) => {
      const multiple_choices = [...prevState.multiple_choices];
      multiple_choices[index].choices.splice(choiceIndex, 1);
      return { ...prevState, multiple_choices };
    });
  };

  const addChoice = (index) => {
    setNewJopData((prevState) => {
      const multiple_choices = [...prevState.multiple_choices];
      multiple_choices[index].choices.push("");
      return { ...prevState, multiple_choices };
    });
  };

  const removeMultipleChoiceQuestion = (index) => {
    setNewJopData((prevState) => {
      const multiple_choices = [...prevState.multiple_choices];
      multiple_choices.splice(index, 1);
      return { ...prevState, multiple_choices };
    });
  };

  const addMultipleChoiceQuestion = () => {
    setNewJopData((prevState) => ({
      ...prevState,
      multiple_choices: [
        ...prevState.multiple_choices,
        { question: "", choices: [""] },
      ],
    }));
  };

  const handleMultipleQuestionMultiChange = (e, index) => {
    const { value } = e.target;
    setNewJopData((prevState) => {
      const multiple_question_multi = [...prevState.multiple_question_multi];
      multiple_question_multi[index].question = value;
      return { ...prevState, multiple_question_multi };
    });
  };

  const handleFieldChange = (e, index, fieldIndex) => {
    const { value } = e.target;
    setNewJopData((prevState) => {
      const updatedFields = [
        ...prevState.multiple_question_multi[index].fields,
      ];
      updatedFields[fieldIndex] = { ...updatedFields[fieldIndex], text: value };
      return {
        ...prevState,
        multiple_question_multi: prevState.multiple_question_multi.map(
          (mqm, i) => {
            if (i === index) {
              return { ...mqm, fields: updatedFields };
            }
            return mqm;
          }
        ),
      };
    });
  };

  const handleFieldChoiceChange = (e, index, fieldIndex, choiceIndex) => {
    const { value } = e.target;
    setNewJopData((prevState) => {
      const multiple_question_multi = [...prevState.multiple_question_multi];
      multiple_question_multi[index].fields[fieldIndex].choices[choiceIndex] =
        value;
      return { ...prevState, multiple_question_multi };
    });
  };

  const removeField = (index, fieldIndex) => {
    setNewJopData((prevState) => {
      const multiple_question_multi = [...prevState.multiple_question_multi];
      multiple_question_multi[index].fields.splice(fieldIndex, 1);
      return { ...prevState, multiple_question_multi };
    });
  };

  const removeFieldChoice = (index, fieldIndex, choiceIndex) => {
    setNewJopData((prevState) => {
      const multiple_question_multi = [...prevState.multiple_question_multi];
      multiple_question_multi[index].fields[fieldIndex].choices.splice(
        choiceIndex,
        1
      );
      return { ...prevState, multiple_question_multi };
    });
  };

  const addField = (index) => {
    setNewJopData((prevState) => {
      const multiple_question_multi = [...prevState.multiple_question_multi];
      multiple_question_multi[index].fields.push({ text: "", choices: [""] });
      return { ...prevState, multiple_question_multi };
    });
  };

  const addFieldChoice = (index, fieldIndex) => {
    setNewJopData((prevState) => {
      const multiple_question_multi = [...prevState.multiple_question_multi];
      multiple_question_multi[index].fields[fieldIndex].choices.push("");
      return { ...prevState, multiple_question_multi };
    });
  };

  const removeMultipleQuestionMulti = (index) => {
    setNewJopData((prevState) => {
      const multiple_question_multi = [...prevState.multiple_question_multi];
      multiple_question_multi.splice(index, 1);
      return { ...prevState, multiple_question_multi };
    });
  };

  const addMultipleQuestionMulti = () => {
    setNewJopData((prevState) => ({
      ...prevState,
      multiple_question_multi: [
        ...prevState.multiple_question_multi,
        { question: "", fields: [{ text: "", choices: [""] }] },
      ],
    }));
  };

  const handleSubmitNewJop = async () => {
    setLoading({ ...loading, add: true });

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        job_name_ar: newJopData.job_name_ar,
        job_name_en: newJopData.job_name_en,
        cv_required: newJopData.cv_required,
        location: newJopData.location,
        questions: newJopData.questions.map((question, index) => ({
          text: question,
          id: index,
        })),
        multiple_choices: newJopData.multiple_choices.map((choice, index) => ({
          text: choice.question,
          id: index,
          choices: choice.choices.map((answer, answerIndex) => ({
            text: answer,
            id: answerIndex,
          })),
        })),
        multiple_question_multi: newJopData.multiple_question_multi.map(
          (multi, index) => ({
            text: multi.question,
            id: index,
            fields: multi.fields.map((field, fieldIndex) => ({
              text: field.text,
              id: fieldIndex,
              choices: field.choices.map((choice, choiceIndex) => ({
                text: choice,
                id: choiceIndex,
              })),
            })),
          })
        ),
      }),
    };

    try {
      const response = await fetch(
        `${BASE_URL}/careers/jobmanagement`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error('Failed to add job');
      }
      const responseData = await response.json();
      toast.success("Job added successfully");
      getJops();
      setNewJopOpen(false);
      setNewJopData({
        job_name_ar: "",
        job_name_en: "",
        cv_required: false,
        location: "",
        questions: [],
        multiple_choices: [],
        multiple_question_multi: [],
      });
    } catch (error) {
      toast.error("Failed to add job");
    } finally {
      setLoading({ ...loading, add: false });
    }
  };
  const [selectedRows, setSelectedRows] = useState(null);
  const [deletedData, setDeleteData] = useState(false);
  const deleteCategory = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");
    if (selectedRows && selectedRows.length) {
      selectedRows.forEach(async (item) => {
        await axios
          .get(`${BASE_URL}careers/jobmanagement/delete/${item.id}?${token}`)
          .then((res) => {
            toast.success(res?.data?.message);
            getJops();
            setSelectedRows(null);
            setDeleteData(false)
          })
          .catch((e) => console.log(e))
          .finally(() => {});
      });
    }
  };
  return (
    <>
      <div className="rowDivBtns">
        <h1></h1>
        <h1></h1>
        <h1>{language == "ar" ? "الوظائف" : "Jops"}</h1>
        <button className="btn btn-success" onClick={() => setNewJopOpen(true)}>
          {language === "ar" ? "إضافة وظيفة جديدة" : "Add New Job"}
        </button>
      </div>
      {selectedRows && selectedRows?.length ? (
        <div className="rowDiv">
          {/* <button
                className="btn btn-primary"
                onClick={() => {
                  handleEditMulti();
                }}
              >
                {language == "ar" ? "تعديل" : "Edit"}
              </button>{" "}
              <button
                className="btn btn-danger"
                onClick={() => {
                  setHideMulti(true);
                }}
              >
                {language == "ar" ? "إخفاء" : "Hide"}
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  setChangeMultiQuantity(true);
                }}
              >
                {language == "ar" ? "تعديل الكمية" : "Change Quantity"}
              </button>{" "}
              <button
                className="btn btn-primary"
                onClick={() => {
                  setSubCatsMulti(true);
                }}
              >
                {language == "ar" ? "تعديل الفئات" : "Change Sub-Category"}
              </button>{" "} */}
          <button
            className="btn btn-danger"
            style={{ cursor: "pointer", margin: "20px" }}
            onClick={() => {
              setDeleteData(true);
            }}
          >
            {language == "ar" ? "حذف" : "Delete"}
          </button>
        </div>
      ) : null}
      <PopUp
        open={deletedData}
        setOpen={setDeleteData}
        title={""}
        children={
          <div className="">
            <h5 className="">
              {language == 'ar' ? 'هل تريد بالفعل ' : 'Do You Want'}{" "}
              {language == 'ar' ? " حذف " : 'Delete'}
              {language == 'ar' ? 'هذه الوظائف ' : "This Jsops"}
            </h5>
            {/* <p>{language == "ar" ? "كلمة السر" : "Password"}</p>
            <input type="text" onChange={(e) => setPassword(e.target.value)} /> */}
            <button
              onClick={() => deleteCategory(true)}
              className="popup_agree_btn"
            >
              {language == 'ar' ? "تأكيد" : "Confirm"}
            </button>
          </div>
        }
      />{" "}
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <TableLayout
            headers={headers}
            data={filteredJops}
            setSelectedRows_t={setSelectedRows}
          />
        </div>
      </div>
      <PopUp
        title={language == 'ar' ? "تغيير حالة الوظيفة" : "Change Jop Status"}
        open={status?.newStatus?.id}
        setOpen={() => {
          setStatus({
            oldStatus: {
              id: null,
              status: null,
            },
            newStatus: {
              id: null,
              status: null,
            },
          });
        }}
      >
        <p>
          {language == 'ar'
            ? "هل أنت متأكد من تغيير حالة الوظيفة ؟"
            : "are You Suure To Change this jop status"}
        </p>
        <div className="modalButtons">
          <button
            className="btn btn-success"
            onClick={() => (loading.change ? null : changeStatus())}
          >
            {loading.change ? (
              <Loader />
            ) : language == 'ar' ? (
              " تأكيد"
            ) : (
              "Confirm"
            )}
          </button>
          <button
            className="btn btn-success"
            onClick={() => {
              setStatus({
                oldStatus: {
                  id: null,
                  status: null,
                },
                newStatus: {
                  id: null,
                  status: null,
                },
              });
            }}
          >
            {language == 'ar' ? "إلغاء" : "Cancel"}
          </button>
        </div>
      </PopUp>
      <PopUp
        title={language === 'ar' ? "إضافة وظيفة جديدة" : "Add New Job"}
        open={newJopOpen}
        setOpen={setNewJopOpen}
      >
        <div
          className="popup-container-xyz987"
          style={{ display: newJopOpen ? 'block' : 'none' }}
        >
          <div className="popup-abc123">
            <h2>{language === 'ar' ? 'إضافة وظيفة جديدة' : 'Add New Job'}</h2>

            <div className="form-group-xyz321">
              <label htmlFor="job_name_ar">
                {language === 'ar'
                  ? 'اسم الوظيفة (عربي):'
                  : 'Job Name (Arabic):'}
              </label>
              <input
                type="text"
                id="job_name_ar"
                name="job_name_ar"
                value={newJopData.job_name_ar}
                onChange={handleNewJopChange}
              />
            </div>

            <div className="form-group-xyz321">
              <label htmlFor="job_name_en">
                {language === 'ar'
                  ? 'اسم الوظيفة (إنجليزي):'
                  : 'Job Name (English):'}
              </label>
              <input
                type="text"
                id="job_name_en"
                name="job_name_en"
                value={newJopData.job_name_en}
                onChange={handleNewJopChange}
              />
            </div>

            <div className="form-group-xyz321">
              <label htmlFor="cv_required">
                {language === 'ar' ? 'مطلوب سيرة ذاتية:' : 'CV Required:'}
              </label>
              <input
                type="checkbox"
                id="cv_required"
                name="cv_required"
                checked={newJopData.cv_required}
                onChange={(e) =>
                  setNewJopData((prevState) => ({
                    ...prevState,
                    cv_required: e.target.checked,
                  }))
                }
              />
            </div>

            <div className="form-group-xyz321">
              <label htmlFor="location">
                {language === 'ar' ? 'الموقع:' : 'Location:'}
              </label>
              <input
                type="text"
                id="location"
                name="location"
                value={newJopData.location}
                onChange={handleNewJopChange}
              />
            </div>

            <div className="form-group-xyz321">
              <label htmlFor="questions">
                {language === 'ar' ? 'الأسئلة:' : 'Questions:'}
              </label>
              {newJopData.questions.map((question, index) => (
                <div key={index} className="choice-field-rst890">
                  <label htmlFor={`question_${index}`}>
                    {`${language === 'ar' ? 'سؤال' : 'Question'} ${index + 1}:`}
                  </label>
                  <input
                    type="text"
                    id={`question_${index}`}
                    name="question"
                    value={question}
                    onChange={(e) => handleQuestionChange(e, index)}
                  />
                  <button
                    className="btn btn-danger"
                    onClick={() => removeQuestion(index)}
                  >
                    {language === 'ar' ? 'حذف' : 'Remove'}
                  </button>
                </div>
              ))}

              <button className="btn btn-primary" onClick={addQuestion}>
                {language === 'ar' ? 'إضافة سؤال' : 'Add Question'}
              </button>
            </div>

            <div className="form-group-xyz321">
              <label htmlFor="multiple_choices">
                {language === 'ar'
                  ? 'أسئلة متعددة الاختيارات:'
                  : 'Multiple Choice Questions:'}
              </label>
              {newJopData.multiple_choices.map((multipleChoice, index) => (
                <div key={index} className="choices-container-pqr789">
                  <label htmlFor={`multiple_choice_question_${index}`}>
                    {`${
                      language === 'ar'
                        ? 'سؤال متعدد الاختيارات'
                        : 'Multiple Choice Question'
                    } ${index + 1}:`}
                  </label>
                  <input
                    type="text"
                    id={`multiple_choice_question_${index}`}
                    name="multiple_choice"
                    value={multipleChoice.question}
                    onChange={(e) =>
                      handleMultipleChoiceQuestionChange(e, index)
                    }
                  />
                  <div className="choice-fields-xyz567">
                    {multipleChoice.choices.map((choice, choiceIndex) => (
                      <div key={choiceIndex} className="choice-field-rst890">
                        <label htmlFor={`choice_${index}_${choiceIndex}`}>
                          {`${language === 'ar' ? 'الاختيار' : 'Choice'} ${
                            choiceIndex + 1
                          }:`}
                        </label>
                        <input
                          type="text"
                          id={`choice_${index}_${choiceIndex}`}
                          name="choice"
                          value={choice}
                          onChange={(e) =>
                            handleChoiceChange(e, index, choiceIndex)
                          }
                        />
                        <button
                          className="btn btn-danger"
                          onClick={() => removeChoice(index, choiceIndex)}
                        >
                          {language === 'ar' ? 'حذف' : 'Remove'}
                        </button>
                      </div>
                    ))}
                  </div>
                  <button
                    className="btn btn-primary"
                    onClick={() => addChoice(index)}
                  >
                    {language === 'ar' ? 'إضافة اختيار' : 'Add Choice'}
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => removeMultipleChoiceQuestion(index)}
                  >
                    {language === 'ar' ? 'حذف السؤال' : 'Remove Question'}
                  </button>
                </div>
              ))}
              <button
                className="btn btn-primary"
                onClick={addMultipleChoiceQuestion}
              >
                {language === 'ar'
                  ? 'إضافة سؤال متعدد الاختيارات'
                  : 'Add Multiple Choice Question'}
              </button>
            </div>

            <div className="form-group-xyz321">
              <label htmlFor="multiple_question_multi">
                {language === 'ar'
                  ? 'أسئلة متعددة الاختيارات مع حقول إجابة:'
                  : 'Multiple Choice Questions with Answer Fields:'}
              </label>
              {newJopData.multiple_question_multi.map(
                (multipleQuestionMulti, index) => (
                  <div key={index} className="multiple-choice-card">
                    <label htmlFor={`multiple_question_multi_${index}`}>
                      {`${
                        language === 'ar'
                          ? 'سؤال متعدد الاختيارات مع حقول إجابة'
                          : 'Multiple Choice Question with Answer Fields'
                      } ${index + 1}:`}
                    </label>
                    <input
                      type="text"
                      id={`multiple_question_multi_${index}`}
                      name="multiple_question_multi"
                      value={multipleQuestionMulti.question}
                      onChange={(e) =>
                        handleMultipleQuestionMultiChange(e, index)
                      }
                    />
                    <div className="fields-container">
                      {multipleQuestionMulti.fields.map((field, fieldIndex) => (
                        <div key={fieldIndex} className="field">
                          <label htmlFor={`field_${index}_${fieldIndex}`}>
                            {`${language === 'ar' ? 'الحقل' : 'Field'} ${
                              fieldIndex + 1
                            }:`}
                          </label>
                          <input
                            type="text"
                            id={`field_${index}_${fieldIndex}`}
                            name="field"
                            value={field.text}
                            onChange={(e) =>
                              handleFieldChange(e, index, fieldIndex)
                            }
                          />

                          <div className="choices-container">
                            {field.choices.map((choice, choiceIndex) => (
                              <div key={choiceIndex} className="choice">
                                <label
                                  htmlFor={`field_choice_${index}_${fieldIndex}_${choiceIndex}`}
                                >
                                  {`${
                                    language === 'ar' ? 'الاختيار' : 'Choice'
                                  } ${choiceIndex + 1}:`}
                                </label>
                                <input
                                  type="text"
                                  id={`field_choice_${index}_${fieldIndex}_${choiceIndex}`}
                                  name="field_choice"
                                  value={choice}
                                  onChange={(e) =>
                                    handleFieldChoiceChange(
                                      e,
                                      index,
                                      fieldIndex,
                                      choiceIndex
                                    )
                                  }
                                />
                                <button
                                  className="btn btn-danger btn-remove"
                                  onClick={() =>
                                    removeFieldChoice(
                                      index,
                                      fieldIndex,
                                      choiceIndex
                                    )
                                  }
                                >
                                  {language === 'ar' ? 'حذف' : 'Remove'}
                                </button>
                              </div>
                            ))}
                          </div>
                          <button
                            className="btn btn-primary"
                            onClick={() => addFieldChoice(index, fieldIndex)}
                          >
                            {language === 'ar' ? 'إضافة اختيار' : 'Add Choice'}
                          </button>
                          <button
                            className="btn btn-danger"
                            onClick={() => removeField(index, fieldIndex)}
                          >
                            {language === 'ar' ? 'حذف' : 'Remove Field'}
                          </button>
                        </div>
                      ))}
                    </div>
                    <button
                      className="btn btn-primary"
                      onClick={() => addField(index)}
                    >
                      {language === 'ar' ? 'إضافة حقل' : 'Add Field'}
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => removeMultipleQuestionMulti(index)}
                    >
                      {language === 'ar' ? 'حذف السؤال' : 'Remove Question'}
                    </button>
                  </div>
                )
              )}
              <button
                className="btn btn-primary"
                onClick={addMultipleQuestionMulti}
              >
                {language === 'ar'
                  ? 'إضافة سؤال متعدد الاختيارات مع حقول إجابة'
                  : 'Add Multiple Choice Question with Answer Fields'}
              </button>
            </div>

            <div className="button-container-stu456">
              <button className="btn btn-success" onClick={handleSubmitNewJop}>
                {language === 'ar' ? 'إضافة' : 'Add'}
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setNewJopOpen(false)}
              >
                {language === 'ar' ? 'إلغاء' : 'Cancel'}
              </button>
            </div>
          </div>
        </div>
      </PopUp>
      <PopUp open={openEdit} setOpen={setOpenEdit}>
        <EditJobForm
          jobData={jopData}
          language={language}
          getJobs={getJops}
          setEditOpen={setOpenEdit}
        />
      </PopUp>
    </>
  );
};

export default JopsManagment;
