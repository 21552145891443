import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./style.css";
import { Loader } from "rsuite";
import axios from "axios";
import { BASE_URL } from "../../components/Axios/base_uri";
import SelectWithSearch from "../../components/selectWithSearch/SelectWithSearch";
import { addition, exitModal, plus } from "../../assets/svgIcons";
import Select from "react-select";
import imageAvatar from "../../assets/image_placeholder.png";

import toast from "react-hot-toast";

const AddIdeaPage = () => {
  const location = useLocation();

  const subCatId = location?.state?.subCat;
  const subCatName = location?.state?.subCatName;
  const [addLoading, setAddLoading] = useState(false);

  const [newProd, setNewProd] = useState({
    title_ar: "",
    title_en: "",
    description_ar: "",
    description_en: "",
    price: "",
    discount: "",
    hasOptions: 0,
    // images:image1**green**image2**green**image3,
  });

  const [categories, setCategories] = useState(null);
  const [subCategories, setSubCategories] = useState(null);
  const [allProducts, setAllProducts] = useState(null);
  const [arIngs, setArIngs] = useState([
    {
      id: 1,
      ar_value: "",
      en_value: "",
      englishLang: false,
    },
  ]);

  const [steps, setSteps] = useState([
    {
      id: 1,
      ar_value: "",
      en_value: "",
      englishLang: false,
    },
  ]);

  const [products, setProducts] = useState([
    {
      id: 1,
      product_id: "",
      price: "",
      count: "",
    },
  ]);

  const [policies, setPolicies] = useState([
    {
      id: 1,
      value: "",
    },
  ]);

  const getCategories = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    await axios
      .get(`${BASE_URL}categories/get_categories_for_admin?${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          setCategories(
            res?.data?.result?.filter((item) => item?.hidden == "0")
          );
        } else if (res.data.status == "error") {
          setCategories([]);
        } else {
          setCategories([]);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  const [data, setData] = useState({});
  const [subCatData, setSubCatData] = useState({});
  const [productData, setProdcutData] = useState({});
  const [imagesUrl, setImagesUrl] = useState([]);
  const [hasOptions, setHasOptions] = useState(false);

  useEffect(() => {
    getCategories();
    getAllProducts();
    console.log(subCatId);
  }, []);

  const getSubCategories = async (id) => {
    // /categories/category_subcategories_for_admin/1?

    const token = localStorage.getItem("SediHishamAdmindata");
    await axios
      .get(
        `${BASE_URL}categories/category_subcategories_for_admin/${id}?${token}`
      )
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setSubCategories(
            res?.data?.result?.filter((item) => item?.hidden == "0")
          );
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          setSubCategories([]);
        } else {
          setSubCategories([]);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  useEffect(() => {
    getSubCategories(data?.id);
  }, [data]);

  const getAllProducts = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    await axios
      .get(`${BASE_URL}products/getAll_for_admin?${token}`)
      .then((res) => {
        console.log(res);
        if (res?.data && res.data.status == "success") {
          setAllProducts(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  useEffect(() => {
    console.log(productData);

    console.log(products);
  }, [productData]);

  const [selectedOptions, setSelectedOptions] = useState(null);

  const handleMultiSelectChnage = (selectedOptions) => {
    const values = selectedOptions.map((option) => option.value);

    setSelectedOptions(values);
    console.log(values);
  };

  const [lang, setLang] = useState({
    prod_name: false,
    prod_desc: false,
  });

  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages([...selectedImages, ...files]);
    console.log(files);
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  const handleAddNewProduct = async () => {
    if (!newProd.title_ar) {
      toast.error("قم بإدخال إسم المنتج باللغة العربية");
      return;
    }
    if (!newProd.title_en) {
      toast.error("قم بإدخال إسم المنتج باللغة الإنجليزية");
      return;
    }
    if (!newProd.description_ar) {
      toast.error("قم بإدخال وصف المنتج باللغة العربية");
      return;
    }
    if (!newProd.description_en) {
      toast.error("قم بإدخال وصف المنتج باللغة الإنجليزية");
      return;
    }

    if (!newProd.price) {
      toast.error("قم بإدخال سعر المنتج ");
      return;
    }

    if (+newProd.price < 0) {
      toast.error(" لا يمكن أن يكون السعر بقيمة سالبة ");
      return;
    }

    if (newProd.discount && +newProd.discount > +newProd.price) {
      toast.error(" لا يمكن أن يكون  قيمة الخصم أكبر سعر المنتج ");
      return;
    }

    if (newProd.discount && +newProd.discount < 0) {
      toast.error(" لا يمكن أن يكون  الخصم بقيمة سالبة ");
      return;
    }

    // if (!subCatData) {
    //   toast.error("قم بإدخال فئة فرعية أولا ");
    //   return;
    // }

    if (imagesUrl.length == 0) {
      toast.error("قم بإضافة صور للمنتج");
      return;
    }

    if (hasOptions) {
      if (!arIngs.length >= 1) {
        toast.error("قم بإدخال مُكوِن واحد على الأقل");
        return;
      }

      const ingsHasNoValues = arIngs.find((item) => {
        if (item.ar_value == '' || item.en_value == '') {
          return item;
        }
      });

      const stepsHasNoValues = steps.find((item) => {
        if (item.ar_value == '' || item.en_value == '') {
          return item;
        }
      });
      const productsHasNoValues = products.find((item) => {
        if (!item.id || !item.count || !item.price) {
          return item;
        }
      });

      if (!arIngs.length >= 1) {
        toast.error("قم بإدخال مُكوِن واحد على الأقل");
        return;
      }
      if (!steps.length >= 1) {
        toast.error("قم بإدخال خطوة تحضير واحدة على الأقل");
        return;
      }

      if (!products.length >= 1) {
        toast.error("قم بإدخال منتج تحضير واحد على الأقل");
        return;
      }

      if (ingsHasNoValues) {
        toast.error(
          `المكون رقم ${ingsHasNoValues.id} مطلوب باللغة العربية والإنجليزية`
        );
        return;
      }

      if (stepsHasNoValues) {
        toast.error(
          `خطوة التحضير رقم ${stepsHasNoValues.id} مطلوبة باللغة العربية والإنجليزية`
        );
        return;
      }

      if (productsHasNoValues) {
        toast.error(
          ` أكمل بيانات منتج التحضير رقم  ${productsHasNoValues.id} `
        );
        return;
      }
    }

    const images = imagesUrl.join("**green**");
    const ingrediants_en = hasOptions
      ? arIngs.map((ing) => ing.en_value).join("**green**")
      : 'none';
    const ingrediants_ar = hasOptions
      ? arIngs.map((ing) => ing.ar_value).join("**green**")
      : 'none';
    const productsData = hasOptions
      ? products
          .map((prod) => {
            return `${prod.product_id}*green*${prod.price}*green*${prod.count}`;
          })
          .join("**green**")
      : 'none';

    const relatedProducts = selectedOptions.join("**green**");
    const policiesData = policies.map((pol) => pol.value).join('**green**');
    const steps_ar = hasOptions
      ? steps.map((step) => step.ar_value).join('**green**')
      : 'none';
    const steps_en = hasOptions
      ? steps.map((step) => step.en_value).join('**green**')
      : 'none';

    const dataSet = {
      title_ar: newProd.title_ar,
      title_en: newProd.title_en,
      description_ar: newProd.description_ar,
      description_en: newProd.description_en,
      price: newProd.price,
      discount: newProd.discount,
      images,
      subcategory_id: subCatId || subCatData.id,
      has_options: hasOptions ? '1' : '0',
      ingrediants_ar,
      ingrediants_en,
      steps_ar,
      steps_en,
      products: productsData,
      related_products: relatedProducts,
      policies: policiesData,
    };

    console.log(dataSet);

    const token = localStorage.getItem("SediHishamAdmindata");

    await axios
      .post(`${BASE_URL}products/add_new?${token}`, dataSet)
      .then((res) => {
        console.log(res);
        if (res?.data && res?.data?.status == "success") {
          toast.success("تم إضافة  منتج جديد بنجاح");
          // getCategories();
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error("هناك مشكلة ! حاول مجدداً");
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setNewCat({
        //   title_ar: "",
        //   title_en: "",
        //   color: "",
        // });
        // setSelectedFile(null);
      });
  };

  const handleUploadImages = async () => {
    const arr = [];
    for (let i = 0; i < selectedImages.length; i++) {
      const formData = new FormData();
      formData.append("image", selectedImages[i]);
      await axios
        .post(`${BASE_URL}upload_image`, formData)
        .then((res) => {
          console.log(res);
          if (res.data && res?.status == 201) {
            arr.push(res?.data?.result?.image);
            toast.success("تم رفع الصورة بنجاح");
          } else {
            toast.error("حدث خطأ ما");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {});
    }

    console.log(arr);

    setImagesUrl([...arr]);
  };

  return (
    <div className="add_product_container">
      <div className="d-flex mb-5 mt-3 align-items-center ">
        <h3 className="">إضافة فكرة جديدة</h3>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleAddNewProduct();
        }}
      >
        <div className="fields row gy-4">
          <div className="field_input  col-md-6">
            <div className="d-flex align-items-center gap-4">
              <label htmlFor="">
                اسم المنتج باللغة {lang.prod_name ? "الإنجليزية" : " العربية "}
              </label>

              <div class="toggle-switch">
                <input
                  class="toggle-input"
                  id="prod_name"
                  type="checkbox"
                  onChange={(e) => {
                    setLang({
                      ...lang,
                      prod_name: e.target.checked,
                    });
                  }}
                />
                <label class="toggle-label" for="prod_name"></label>
              </div>
            </div>
            <input
              type="text"
              value={lang.prod_name ? newProd.title_en : newProd.title_ar}
              onChange={(e) => {
                lang.prod_name
                  ? setNewProd({
                      ...newProd,
                      title_en: e.target.value,
                    })
                  : setNewProd({
                      ...newProd,
                      title_ar: e.target.value,
                    });
              }}
            />
          </div>
          <div className="field_input col-md-6">
            <div className="d-flex align-items-center gap-4">
              <label htmlFor="">
                وصف المنتج باللغة {lang.prod_desc ? "الإنجليزية" : " العربية "}
              </label>

              <div class="toggle-switch">
                <input
                  class="toggle-input"
                  id="prod_desc"
                  type="checkbox"
                  onChange={(e) => {
                    setLang({
                      ...lang,
                      prod_desc: e.target.checked,
                    });
                  }}
                />
                <label class="toggle-label" for="prod_desc"></label>
              </div>
            </div>

            <input
              type="text"
              value={
                lang.prod_desc ? newProd.description_en : newProd.description_ar
              }
              onChange={(e) => {
                lang.prod_desc
                  ? setNewProd({
                      ...newProd,
                      description_en: e.target.value,
                    })
                  : setNewProd({
                      ...newProd,
                      description_ar: e.target.value,
                    });
              }}
            />
          </div>

          <div className="field_input col-md-6">
            <label htmlFor="">سعر المنتج </label>
            <input
              type="number"
              onChange={(e) => {
                setNewProd({
                  ...newProd,
                  price: e.target.value,
                });
              }}
            />
          </div>
          <div className="field_input col-md-6">
            <label htmlFor="">قيمة الخصم</label>
            <input
              type="number"
              onChange={(e) => {
                setNewProd({
                  ...newProd,
                  discount: e.target.value,
                });
              }}
            />
          </div>
          {!subCatId ? (
            <>
              <div className="field_input col-md-6">
                <label htmlFor="">الفئة</label>

                <SelectWithSearch
                  options={categories}
                  getClientData={setData}
                />
              </div>
              <div className="field_input col-md-6">
                <label htmlFor="">الفئة الفرعية</label>
                <SelectWithSearch
                  options={subCategories}
                  getClientData={setSubCatData}
                />
              </div>
            </>
          ) : null}
        </div>

        <div className="images_container my-3">
          <h4>اختر صور المنتج</h4>

          <div className="images">
            <label className="image" htmlFor="mul_images">
              <img
                className="avatar"
                src={
                  "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                }
                alt=""
              />
            </label>
            <input
              type="file"
              multiple
              name=""
              id="mul_images"
              className="d-none"
              onChange={handleImageChange}
            />
            {selectedImages.length >= 1
              ? selectedImages.map((imageFile, index) => {
                  return (
                    <div className="image">
                      <img src={URL.createObjectURL(imageFile)} alt="" />
                      <div
                        className="delete_btn"
                        onClick={() => handleDeleteImage(index)}
                      >
                        {exitModal}
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
          <button
            onClick={() => handleUploadImages()}
            className="popup_agree_btn"
          >
            {addLoading ? <Loader /> : "رفع الصور"}
          </button>
        </div>

        <div className="my-4">
          <div className="d-flex align_items-center justify-content-between my-3">
            <h4>منتجات مشابهة لهذا المنتج</h4>
          </div>

          <Select
            isMulti
            name="colors"
            onChange={handleMultiSelectChnage}
            options={allProducts?.map((prod) => {
              return { value: prod.id, label: prod.title_ar };
            })}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>

        <div className="d-flex align_items-center justify-content-between">
          <h4>سياسات المنتج</h4>
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setPolicies([
                ...policies,
                { value: "", id: policies.length + 1 },
              ]);
            }}
          >
            {plus}
          </div>
        </div>

        {policies && policies.length >= 1
          ? policies.map((policy, index) => {
              return (
                <div className="ing_container mb-3">
                  <div
                    className="deleteIng"
                    onClick={() => {
                      setPolicies((prev) =>
                        prev.filter((item) => item.id != policy.id)
                      );
                    }}
                  >
                    {exitModal}
                  </div>

                  <div className="fields row w-100 ">
                    <div className="field_input col-sm-12">
                      <div className="d-flex align-items-center gap-4">
                        <label htmlFor="">
                          {/* باللغة {ing.englishLang ? "الإنجليزية" : " العربية "} */}
                          سياسة رقم ({index + 1})
                        </label>

                        {/* <div class='toggle-switch'>
                            <input
                              class='toggle-input'
                              id={`policy_${index}`}
                              type='checkbox'
                              onChange={(e) => {
                                setPolicies((prev) =>
                                  prev.map((item) =>
                                    item.id == ing.id
                                      ? {...item, englishLang: e.target.checked}
                                      : item
                                  )
                                );
                              }}
                            />
                            <label
                              class='toggle-label'
                              for={`policy_${index}`}
                            ></label>
                          </div> */}
                      </div>

                      <input
                        type="text"
                        value={policy.value}
                        onChange={(e) => {
                          // ing.englishLang
                          // ?
                          setPolicies((prev) =>
                            prev.map((item) =>
                              item.id == policy.id
                                ? { ...item, value: e.target.value }
                                : item
                            )
                          );
                          // : setArIngs((prev) =>
                          //     prev.map((item) =>
                          //       item.id == ing.id
                          //         ? {
                          //             ...item,
                          //             ar_value: e.target.value,
                          //           }
                          //         : item
                          //     )
                          //   );
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })
          : null}

        <hr />

        <div className="d-flex align-items-center gap-4">
          <label htmlFor="hasOptions">هل المنتج يحتوي على إضافات؟</label>

          <div class="toggle-switch">
            <input
              class="toggle-input"
              id="hasOptions"
              type="checkbox"
              onChange={(e) => {
                setHasOptions(e.target.checked);
              }}
            />
            <label class="toggle-label" for="hasOptions"></label>
          </div>
        </div>

        {hasOptions ? (
          <div className="ingrediants mt-4">
            <div className="d-flex align_items-center justify-content-between">
              <h4>المكونات</h4>
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setArIngs([
                    ...arIngs,
                    {
                      ar_value: "",
                      en_value: "",
                      englishLang: false,
                      id: arIngs.length + 1,
                    },
                  ]);
                  console.log(arIngs);
                }}
              >
                {plus}
              </div>
            </div>

            {arIngs && arIngs.length >= 1
              ? arIngs.map((ing, index) => {
                  return (
                    <div className="ing_container mb-3">
                      <div
                        className="deleteIng"
                        onClick={() => {
                          setArIngs((prev) =>
                            prev.filter((item) => item.id != ing.id)
                          );
                        }}
                      >
                        {exitModal}
                      </div>

                      <div className="fields row w-100 ">
                        <div className="field_input col-sm-12">
                          <div className="d-flex align-items-center gap-4">
                            <label htmlFor="">
                              باللغة{" "}
                              {ing.englishLang ? "الإنجليزية" : " العربية "}
                            </label>

                            <div class="toggle-switch">
                              <input
                                class="toggle-input"
                                id={`prod_desc_${index}`}
                                type="checkbox"
                                onChange={(e) => {
                                  setArIngs((prev) =>
                                    prev.map((item) =>
                                      item.id == ing.id
                                        ? {
                                            ...item,
                                            englishLang: e.target.checked,
                                          }
                                        : item
                                    )
                                  );
                                }}
                              />
                              <label
                                class="toggle-label"
                                for={`prod_desc_${index}`}
                              ></label>
                            </div>
                          </div>

                          <input
                            type="text"
                            value={
                              ing.englishLang ? ing.en_value : ing.ar_value
                            }
                            onChange={(e) => {
                              ing.englishLang
                                ? setArIngs((prev) =>
                                    prev.map((item) =>
                                      item.id == ing.id
                                        ? { ...item, en_value: e.target.value }
                                        : item
                                    )
                                  )
                                : setArIngs((prev) =>
                                    prev.map((item) =>
                                      item.id == ing.id
                                        ? {
                                            ...item,
                                            ar_value: e.target.value,
                                          }
                                        : item
                                    )
                                  );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}

            <div className="d-flex align_items-center justify-content-between">
              <h4>خطوات التحضير</h4>
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSteps([
                    ...steps,
                    {
                      ar_value: "",
                      en_value: "",
                      englishLang: false,
                      id: steps.length + 1,
                    },
                  ]);
                }}
              >
                {plus}
              </div>
            </div>

            {steps && steps.length >= 1
              ? steps.map((step, index) => {
                  return (
                    <div className="ing_container mb-3">
                      <div
                        className="deleteIng"
                        onClick={() => {
                          setSteps((prev) =>
                            prev.filter((item) => item.id != step.id)
                          );
                        }}
                      >
                        {exitModal}
                      </div>
                      <div className="fields row w-100 ">
                        <div className="field_input col-sm-12">
                          <div className="d-flex align-items-center gap-4">
                            <label htmlFor="">
                              الخطوة ({index + 1}) باللغة{" "}
                              {step.englishLang ? "الإنجليزية" : " العربية "}
                            </label>

                            <div class="toggle-switch">
                              <input
                                class="toggle-input"
                                id={`step_${index}`}
                                type="checkbox"
                                onChange={(e) => {
                                  setSteps((prev) =>
                                    prev.map((item) =>
                                      item.id == step.id
                                        ? {
                                            ...item,
                                            englishLang: e.target.checked,
                                          }
                                        : item
                                    )
                                  );
                                }}
                              />
                              <label
                                class="toggle-label"
                                for={`step_${index}`}
                              ></label>
                            </div>
                          </div>

                          <input
                            type="text"
                            value={
                              step.englishLang ? step.en_value : step.ar_value
                            }
                            onChange={(e) => {
                              step.englishLang
                                ? setSteps((prev) =>
                                    prev.map((item) =>
                                      item.id == step.id
                                        ? { ...item, en_value: e.target.value }
                                        : item
                                    )
                                  )
                                : setSteps((prev) =>
                                    prev.map((item) =>
                                      item.id == step.id
                                        ? { ...item, ar_value: e.target.value }
                                        : item
                                    )
                                  );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}

            <div className="d-flex align_items-center justify-content-between">
              <h4> الإضافات</h4>
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setProducts([
                    ...products,
                    {
                      product_id: "",
                      price: "",
                      count: "",
                      id: products.length + 1,
                    },
                  ]);
                }}
              >
                {plus}
              </div>
            </div>

            {products && products.length >= 1
              ? products.map((prod, index) => {
                  return (
                    <div className="prod_container">
                      <div
                        className="deleteIng"
                        onClick={() => {
                          setProducts((prev) =>
                            prev.filter((item) => item.id != prod.id)
                          );
                        }}
                      >
                        {exitModal}
                      </div>

                      <div className="field_input">
                        <label htmlFor="">المنتج</label>
                        <SelectWithSearch
                          options={allProducts}
                          getClientData={setProdcutData}
                          onChange={(value) => {
                            setProducts((prev) =>
                              prev.map((item) =>
                                item.id == prod.id
                                  ? { ...item, product_id: value.id }
                                  : item
                              )
                            );
                          }}
                        />
                      </div>

                      <div className="field_input">
                        <label htmlFor="">السعر</label>
                        <input
                          type="number"
                          onChange={(e) => {
                            setProducts((prev) =>
                              prev.map((item) =>
                                item.id == prod.id
                                  ? { ...item, price: e.target.value }
                                  : item
                              )
                            );
                          }}
                        />
                      </div>

                      <div className="field_input">
                        <label htmlFor="">الوزن/الكمية</label>
                        <input
                          type="number"
                          onChange={(e) => {
                            setProducts((prev) =>
                              prev.map((item) =>
                                item.id == prod.id
                                  ? { ...item, count: e.target.value }
                                  : item
                              )
                            );
                          }}
                        />
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        ) : null}

        <button className="popup_agree_btn">
          {addLoading ? <Loader /> : "إضافة"}
        </button>
      </form>
    </div>
  );
};

export default AddIdeaPage;
