import React from 'react';
import "./style.css";
import axios from 'axios';
import { BASE_URL } from '../Axios/base_uri';
import UseGeneral from '../../customHooks/useGeneral';
const Sats = ({ stats }) => {
  const { language } = UseGeneral();
  return (
    <div class="card-list">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
          <div class="card blue">
            <div class="title">
              {language == 'ar' ? 'Active Products' : "Active Products"}
            </div>
            <i class="zmdi zmdi-upload"></i>
            <div class="value">{stats?.active_products}</div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
          <div class="card green">
            <div class="title">
              {language == 'ar' ? 'عدد المستخدمين' : 'Number Of Users'}
            </div>
            <i class="zmdi zmdi-upload"></i>
            <div class="value">{stats?.customers}</div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
          <div class="card red">
            <div class="title">
              {language == 'ar' ? 'كل الطلبات' : 'All Orders'}
            </div>
            <i class="zmdi zmdi-download"></i>
            <div class="value">{stats?.all_Orders}</div>
         
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
          <div class="card red">
            <div class="title">
              {language == 'ar' ? 'أحدث الطلبات' : 'New Orders'}
            </div>
            <i class="zmdi zmdi-download"></i>
            <div class="value">{stats?.pending_Orders}</div>
        
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
          <div class="card red">
            <div class="title">
              {language == 'ar' ? 'الطلبات المدفوعة  ' : 'Paid Orders'}
            </div>
            <i class="zmdi zmdi-download"></i>
            <div class="value">{stats?.paid_Orders}</div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
          <div class="card red">
            <div class="title">
              {language == 'ar' ? 'الطلبات الملغية  ' : 'Canceled Orders'}
            </div>
            <i class="zmdi zmdi-download"></i>
            <div class="value">{stats?.canceled_Orders}</div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
          <div class="card blue">
            <div class="title">
              {language == 'ar' ? 'إجمالي المبيعات ' : 'Total Revenue'}
            </div>
            <i class="zmdi zmdi-download"></i>
            <div class="value">{stats?.revenue}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sats;
