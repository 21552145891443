import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { exitModal } from "../../assets/svgIcons";
import { BASE_URL } from "../../components/Axios/base_uri";
import TableLayout from "../../components/table";

import { Loader } from "rsuite";
import { Axios } from "../../components/Axios";
import PopUp from "../../components/popup";
import UseGeneral from "../../customHooks/useGeneral";
import ReactQuill from "react-quill";

const CategorysPage = () => {
  const { language } = UseGeneral();
  const navigate = useNavigate();
  const [subCategorys, setSubCategorys] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [showAddNewSubCategory, setShowAddNewSubCategory] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [updateModal, setUpdateModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(false);
  const [changeStatusloading, setChangeStatusloading] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [product_id, set_product_id] = useState({});
  const [loader, setLoader] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [productData, setProductData] = useState({});
  const [newCategory, setNewCategory] = useState({
    title_ar: "",
    title_en: "",
    category_id: 0,
  });

  const [img, setImg] = useState("");
  const setArrangeNumber = async (video_id, video_number) => {
    // console.log(video_number)
    // console.log(video_id)
    // return
    setLoader(true);
    if (video_number == currentNumber) {
      return setLoader(false);
    }
    await Axios({
      method: "POST",
      url: "subcategories/changer_sub_cat_order/" + video_id,
      data: { number: video_number },
    })
      .then(async (response) => {
        console.log(response);
        toast.success(response?.message);
        getSubCatsForCategorys();
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoader(false);
      });
  };

  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const subCategorysHeaders = [
    {
      label: language == 'ar' ? "اسم الفئة" : "Category Name",
      type: "children",
      children: ({ row }) => {
        return (
          <p>
            {row?.cooks_category_translations &&
            row?.cooks_category_translations?.length
              ? language == "ar"
                ? row?.cooks_category_translations[0]?.title
                : row?.cooks_category_translations[1]?.title
              : null}
          </p>
        );
      },
    },

    {
      label: language == 'ar' ? "الصورة" : "Image",
      dataIndex: "id",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="table_row_image">
            <img src={BASE_URL + row.image_url} alt="image" />
          </div>
        );
      },
    },

    // {
    //   label: language == 'ar' ? "حالة الظهور" : "Appearance Status",
    //   dataIndex: "num_order",
    //   type: "children",
    //   children: ({ row }) => {
    //     return (
    //       <div
    //         className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
    //       >
    //         {row.hidden == "0"
    //           ? language == 'ar'
    //             ? "ظاهر"
    //             : "apparent"
    //           : language == 'ar'
    //           ? "مخفي"
    //           : "hidden"}
    //       </div>
    //     );
    //   },
    // },

    // {
    //   label: "وقت الانشاء",
    //   dataIndex: "time",
    //   type: "children",
    //   children: ({ row }) => {
    //     return <div>{getOlnlyDate(row.created_at)}</div>;
    //   },
    // },

    {
      label: language == 'ar' ? " أوامر" : "Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            {/* <div
              className={`${
                row.hidden == "0" ? "text-danger" : "text-success"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              <button className="btn btn-success">
                {row.hidden == "0"
                  ? language == "ar"
                    ? "إخفاء"
                    : "Hide"
                  : language == "ar"
                  ? "إظهار"
                  : "Show"}
              </button>
            </div> */}
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData({
                  id: row?.id,
                  name_ar: row?.cooks_category_translations[0]?.title,
                  name_en: row?.cooks_category_translations[1]?.title,
                  description_en:
                    row?.cooks_category_translations[1]?.description,
                  description_ar:
                    row?.cooks_category_translations[0]?.description,
                  image_url: row?.image_url,
                });
                // setImgUrl(row.image)
                setImg(BASE_URL + row.image_url);
                // console.log(row.image)
                setShowUpdateModal(true);
              }}
            >
              <button className="btn btn-success">
                {language == "ar" ? "تعديل" : "Edit"}
              </button>
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/ideas/${row.id}`, {
                  state: { categoryData: row },
                });
                console.log(row);
              }}
            >
              <button className="btn btn-success">
                {language == "ar" ? "عرض" : "View"}
              </button>
            </div>
          </div>
        );
      },
    },
  ];

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImg(URL.createObjectURL(e.target.files[0]));
      setSelectedFile(e.target.files[0]);
    }
  };

  const getSubCatsForCategorys = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}cooks-categories/findall?${token}`)
      .then((res) => {
        console.log(res);
        setSubCategorys(res?.data);
        setOriginalData(res.data);
      })
      .catch((e) => {
        console.log(e);
        setSubCategorys([]);
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getSubCatsForCategorys();
  }, []);

  const handleAddNewSubCategory = async (image) => {
    // if (!newSubCat.title_ar) {
    //   toast.error("قم بإضافة اسم الفئة الفرعية باللغة العربية أولاً");
    //   return;
    // }
    // if (!newSubCat.title_en) {
    //   toast.error("قم بإضافة اسم الفئة الفرعية باللغة الإنجليزية أولاً");
    //   return;
    // }

    const dataset = {
      ...newCategory,
      image_url: image,
    };

    const token = localStorage.getItem("SediHishamAdmindata");
    await axios
      .post(`${BASE_URL}cooks-categories/create?${token}`, dataset, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        if (res?.status == 201) {
          toast.success("Added SuccessFully");
          getSubCatsForCategorys();
          setShowAddNewSubCategory(false);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        // setShowAddSubCatModal(false);
        // setAddLoading(false);
      });
  };

  const handleShow_hide = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    const dataset = {
      id: rowData.id,
    };

    setChangeStatusloading(true);
    await axios
      .post(`${BASE_URL}subcategories/change_status?${token}`, dataset, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        console.log(res);
        if (res?.data && res?.data?.status == "success") {
          toast.success(res.data.message);
        } else if (res.data.status == "error" || res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == 'ar' ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusloading(false);
        getSubCatsForCategorys();
        setRowData({});
      });
  };

  const handleAddFile = async () => {
    setAddLoading(true);
    // setImgLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    await axios
      .post(`${BASE_URL}categories/upload/category-image`, formData)
      .then((res) => {
        if (res?.status == 201) {
          toast.success("Added SuccessFully");
          handleAddNewSubCategory(res?.data?.imagePath);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setShowAddNewSubCategory(false);
        setAddLoading(false);
        setImg('');
        setSelectedFile(null);
      });
  };

  // updates
  // updates
  // updates
  // updates

  const handleUpdateCategoryImage = async () => {
    setUpdateLoading(true);
    if (selectedFile) {
      // setImgLoading(true);
      const formData = new FormData();
      formData.append("image", selectedFile);
      await axios
        .post(`${BASE_URL}upload_image`, formData)
        .then((res) => {
          if (res?.status == 201) {
            handleUpdateCategory(res?.data.imagePath);
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          // setImgLoading(false);
          setUpdateLoading(false);
          setImg('');
          setSelectedFile(null);
        });
    } else {
      handleUpdateCategory(rowData.image_url);
      setUpdateLoading(false);
      // setShowUpdateModal(false);
      // setImg('');
      setSelectedFile(null);
    }
  };

  const handleUpdateCategory = async (image) => {
    // if (!rowData.title_ar) {
    //   toast.error("قم بإضافة اسم الفئة الفرعية باللغة العربية أولاً");
    //   return;
    // }
    // if (!rowData.title_en) {
    //   toast.error("قم بإضافة اسم الفئة الفرعية باللغة الإنجليزية أولاً");
    //   return;
    // }

    const dataset = {
      ...rowData,

      id: rowData.id,
      image_url: image,
    };

    const token = localStorage.getItem("SediHishamAdmindata");

    // setEditLoading(true)
    await axios
      .post(`${BASE_URL}cooks-categories/update/${rowData?.id}`, dataset, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        console.log(res);

        if (res?.status == 201) {
          toast.success("Updated SuccessFully");
          getSubCatsForCategorys();
        } else {
          toast.error("Not Updated");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setEditLoading(false);
        setShowUpdateModal(false);

        setUpdateModal(false);
      });
  };

  // filterations
  // filterations
  // filterations

  // filteration

  useEffect(() => {
    if (originalData && originalData.length >= 1) {
      if (searchValue.length > 0) {
        console.log(searchValue);
        const newData = originalData.filter((category) => {
          if (
            searchValue.length >= 1 &&
            !category.title_ar?.toLowerCase()?.includes(searchValue) &&
            !category.title_en?.toLowerCase()?.includes(searchValue) &&
            !category.id.toString()?.toLowerCase()?.includes(searchValue)
          ) {
            return false;
          }
          return true;
        });
        setSubCategorys(newData);
      } else {
        setSubCategorys(originalData);
      }
    }
  }, [searchValue]);
  const [selectedRows, setSelectedRows] = useState(null);
  const [changeStatus, setChageStatus] = useState(false);
  const [deletedData, setDeleteData] = useState(false);
  const deleteCategory = async () => {
    setChageStatus(true);
    const token = localStorage.getItem("SediHishamAdmindata");
    if (selectedRows && selectedRows.length) {
      console.log(selectedRows);
      selectedRows.forEach(async (item) => {
        await axios
          .get(`${BASE_URL}cooks-categories/delete/${item.id}?${token}`)
          .then((res) => {
            toast.success(res.message);
            getSubCatsForCategorys();
          })
          .catch((e) => console.log(e))
          .finally(() => {
            setChageStatus(false);
          });
      });
    }
  };
  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5>{language == 'ar' ? "الفئات" : "Categorys"}</h5>
            <div
              onClick={() => setShowAddNewSubCategory(true)}
              className="btn btn-success"
            >
              {language == 'ar' ? 'إضافة فئة' : 'Add Category'}
            </div>
          </div>
          <div className="searchInput field_input">
            <input
              type="text"
              placeholder={language == 'ar' ? "بحث..." : "Search..."}
              onChange={(e) => {
                setSearchValue(e.target.value?.toLowerCase());
              }}
            />
          </div>
          {selectedRows && selectedRows?.length ? (
            <div className="rowDiv">
              {/* <button
                className="btn btn-primary"
                onClick={() => {
                  handleEditMulti();
                }}
              >
                {language == "ar" ? "تعديل" : "Edit"}
              </button>{" "}
              <button
                className="btn btn-danger"
                onClick={() => {
                  setHideMulti(true);
                }}
              >
                {language == "ar" ? "إخفاء" : "Hide"}
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  setChangeMultiQuantity(true);
                }}
              >
                {language == "ar" ? "تعديل الكمية" : "Change Quantity"}
              </button>{" "}
              <button
                className="btn btn-primary"
                onClick={() => {
                  setSubCatsMulti(true);
                }}
              >
                {language == "ar" ? "تعديل الفئات" : "Change Sub-Category"}
              </button>{" "} */}
              <button
                className="btn btn-danger"
                style={{ cursor: "pointer", margin: "20px" }}
                onClick={() => {
                  setDeleteData(true);
                }}
              >
                {language == "ar" ? "حذف" : "Delete"}
              </button>
            </div>
          ) : null}
          <PopUp
            open={deletedData}
            setOpen={setDeleteData}
            title={""}
            children={
              <div className="">
                <h5 className="">
                  {language == 'ar' ? 'هل تريد بالفعل ' : 'Do You Want'}{" "}
                  {rowData.hidden == "0"
                    ? language == 'ar'
                      ? " حذف "
                      : 'Delete'
                    : language == 'ar'
                    ? " حذف "
                    : " Delete "}
                  {language == 'ar' ? 'هذه المنتجات ' : "This Products"}
                </h5>
                {/* <p>{language == "ar" ? "كلمة السر" : "Password"}</p>
            <input type="text" onChange={(e) => setPassword(e.target.value)} /> */}
                <button
                  onClick={() => deleteCategory(true)}
                  className="popup_agree_btn"
                >
                  {changeStatusLoading ? (
                    <Loader />
                  ) : language == 'ar' ? (
                    "تأكيد"
                  ) : (
                    "Confirm"
                  )}
                </button>
              </div>
            }
          />{" "}
          {!subCategorys ? (
            <Loader size="md" />
          ) : subCategorys && subCategorys?.length ? (
            <TableLayout
              headers={subCategorysHeaders}
              data={subCategorys}
              setSelectedRows_t={setSelectedRows}
            />
          ) : null}
        </div>
      </div>
      <PopUp
        open={showAddNewSubCategory}
        setOpen={setShowAddNewSubCategory}
        title={language == 'ar' ? "إضافة فئة جديدة " : "Add New Category"}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddFile();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">
                  {language == 'ar'
                    ? "اسم الفئة باللغة العربية"
                    : " Arabic Category Name"}
                </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewCategory({
                      ...newCategory,
                      name_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {language == 'ar'
                    ? "اسم الفئة باللغة الإنجليزية"
                    : "English Category Name"}
                </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewCategory({
                      ...newCategory,
                      name_en: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="field_input">
                <label htmlFor="">
                  {language == 'ar'
                    ? "وصف الفئة  باللغة الإنجليزية"
                    : "English Category Description"}
                </label>

                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={newCategory?.description_en}
                  onChange={(e) => {
                    setNewCategory({
                      ...newCategory,
                      description_en: e,
                    });
                  }}
                  style={{
                    minHeight: "200px",
                    color: "black",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {language == 'ar'
                    ? "وصف الفئة  باللغة العربية"
                    : "Arabic Category Description"}
                </label>
                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={newCategory?.description_ar}
                  onChange={(e) => {
                    setNewCategory({
                      ...newCategory,
                      description_ar: e,
                    });
                  }}
                  style={{
                    minHeight: "200px",
                    color: "black",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {language == 'ar'
                    ? "اختر صورة الفئة"
                    : "Enter Category Image"}
                </label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image"}
                    className="w-100"
                  >
                    <img
                      width={100}
                      src={
                        img == ""
                          ? "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                          : img
                      }
                      alt=""
                    />
                  </label>
                  <input
                    id={"add-image"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onImageChange(e)}
                  />

                  {img && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setImg("");
                        setSelectedFile(null);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <button className="popup_agree_btn">
              {addLoading ? <Loader /> : language == 'ar' ? "إضافة" : "add"}
            </button>
          </form>
        }
      />

      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={
          language == 'ar' ? "تغيير حالة الظهور" : "Change Appearance Status"
        }
        children={
          <div className="">
            <h5 className="">
              {language == 'ar' ? "هل تريد بالفعل " : "Do you really want to "}{" "}
              {rowData.hidden == "0"
                ? language == 'ar'
                  ? " إخفاء "
                  : " hide "
                : language == 'ar'
                ? " إظهار "
                : 'Show'}{" "}
              {language == 'ar' ? 'هذه الفئة' : 'This Category'}
            </h5>

            <button
              onClick={() => {
                if (!changeStatusloading) {
                  handleShow_hide();
                }
              }}
              className="popup_agree_btn"
            >
              {changeStatusloading ? (
                <Loader />
              ) : language == 'ar' ? (
                "تأكيد"
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        }
      />

      <PopUp
        open={showUpdateModal}
        setOpen={setShowUpdateModal}
        title={`${language == 'ar' ? 'تعديل الفئة ' : 'Edit Category '}`}
        onClose={() => {
          console.log('closed');
        }}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateCategoryImage();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">
                  {language == 'ar'
                    ? 'اسم الفئة باللغة العربية'
                    : 'Arabic Category Name'}
                </label>
                <input
                  type="text"
                  value={rowData.name_ar}
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      name_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {language == 'ar'
                    ? "اسم الفئة باللغة الإنجليزية"
                    : "English Category Name"}
                </label>
                <input
                  value={rowData.name_en}
                  type="text"
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      name_en: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="field_input">
                <label htmlFor="">
                  {language == 'ar'
                    ? "وصف الفئة  باللغة الإنجليزية"
                    : "English Category Description"}
                </label>
                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={rowData?.description_en}
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      description_en: e,
                    });
                  }}
                  style={{
                    minHeight: "200px",
                    color: "black",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                />

              </div>
              <div className="field_input">
                <label htmlFor="">
                  {language == 'ar'
                    ? "وصف الفئة  باللغة العربية"
                    : "Arabic Category Description"}
                </label>
                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={rowData?.description_ar}
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      description_ar: e,
                    });
                  }}
                  style={{
                    minHeight: "200px",
                    color: "black",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {language == 'ar'
                    ? "اختر صورة الفئة"
                    : "Enter Category Image"}
                </label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image"}
                    className="w-100"
                  >
                    <img width={100} src={img} alt="" />
                  </label>
                  <input
                    id={"add-image"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onImageChange(e)}
                  />

                  {img && img != rowData.image && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setImg(rowData.image);
                        console.log(rowData.image);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <button className="popup_agree_btn">
              {updateLoading ? <Loader /> : language == 'ar' ? "تعديل" : "Edit"}
            </button>
          </form>
        }
      />
    </>
  );
};

export default CategorysPage;
