import React, { useEffect, useState } from "react";
import TableLayout from "../../components/table";
import { BASE_URL } from "../../components/Axios/base_uri";
import axios from "axios";
import toast from "react-hot-toast";
import imageAvatar from "../../assets/image_placeholder.png";
import {
  Menu,
  add,
  exitModal,
  eyeOff,
  eyeOn,
  search,
} from "../../assets/svgIcons";
import PopUp from "./../../components/popup/index";
import "./style.css";
import { edit } from "./../../assets/svgIcons/index";
import { Loader } from "rsuite";
import { useNavigate } from "react-router-dom";
import { set } from "rsuite/esm/utils/dateUtils";
import { Axios } from "../../components/Axios";
import UseGeneral from "../../customHooks/useGeneral";

const BannersPage = () => {
  const { language } = UseGeneral()
  const navigate = useNavigate();
  const [Banners, setBanners] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [showAddCatModal, setShowAddCatModal] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [updateModal, setUpdateModal] = useState(false);
  const [type] = useState(['box1', 'box2', 'left_box3', 'right_box3']);
  const [selectedType, setSelectedType] = useState('box1');
  const [imgUrl, setImgUrl] = useState("");
  const [imgbackUrl, setImgbackUrl] = useState("");
  const [searchValue,  setSearchValue] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);

  const [newBanner, setNewBanner] = useState({
    button_name: "",
    link: "",
    title_en: "",
    button_name_en: "",
    title_ar: "",
  });

  const [swBtns, setSwBtns] = useState({
    titleLang: false,
    buttonNameLang: false,
  });

  const [img, setImg] = useState("");
  const [backImg, setBackImg] = useState("");
  const [selectedImgFile, setSelectedImgFile] = useState(null);
  const [selectedBackFile, setSelectedBackFile] = useState(null);
  const [showUpdateBanner, setShowUpdateBanner] = useState(false);
  const [product_id, set_product_id] = useState({});
  const [loader, setLoader] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [productData, setProductData] = useState({});
  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const getAllBanners = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}banners/get_all_admin?${token}`, {
        headers: {
          lang: language
        }
      })
      .then((res) => {
        if (res.data.status == "success") {
          setBanners(res?.data?.result?.filter((item) => item?.hidden == "0"));
          setOriginalData(
            res?.data?.result?.filter((item) => item?.hidden == "0")
          );
          console.log(res.data.result);
        } else if (res.data.status == "error" || res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == 'ar' ? 'حدث خطأ ما' : 'Something Went Error');
        }
      })
      .catch((e) => {
        console.log(e);
        setBanners([]);
      })
      .finally(() => {
        setDataLoading(false);
      });
  };
  const [finalDraggedRow, setFinalDraggedRow] = useState(null);
  const [c_id, setId] = useState(null);
  const [draggedRow, setDraggedRow] = useState(null);
  useEffect(() => {
    if (finalDraggedRow && draggedRow) {
      const draggedIndex = Banners?.findIndex(
        (item) => item?.id == draggedRow?.id
      );
      const draggedFinalIndex = Banners?.findIndex(
        (item) => item?.id == finalDraggedRow?.id
      );
      const draggedID = Banners?.filter(
        (item) => item?.id == finalDraggedRow?.id
      )[0]?.id
      setArrangeNumber(c_id, draggedFinalIndex, draggedIndex);
      setArrangeNumber(draggedID, draggedFinalIndex+1);
    
    }
  }, [finalDraggedRow]);
  useEffect(() => {
    if (draggedRow) {
      setCurrentNumber(draggedRow?.number);
      setId(draggedRow?.id);
    }
  }, [draggedRow]);
  const setArrangeNumber = async (video_id, video_number, currentNumberTwo) => {
    // console.log(video_number)
    // console.log(video_id)
    // return
    const num = currentNumberTwo ? currentNumberTwo : currentNumber;
    setLoader(true);
    if (video_number == num) {
      // alert(video_number, num);
      return setLoader(false);
    }

    await Axios({
      method: "POST",
      url: "banners/change_number/" + video_id,
      data: { number: video_number },
    })
      .then(async (response) => {
        console.log(response);
        toast.success(response?.message);
        getAllBanners();
        setFinalDraggedRow(null);
        setDraggedRow(null);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoader(false);
      });
  };


  useEffect(() => {
    getAllBanners();
  }, []);

  const bannersHeaders = [
    // {
    //   label: "#",
    //   dataIndex: "id",
    // },
    // {
    //   label: "Number",
    //   dataIndex: "number",
    //   search: true,
    //   sort: true,
    //   type: "children",
    //   children: ({ headers, row }) => {
    //     const handleBlur = async (product_id, product_number) => {
    //       set_product_id(null);
    //       setArrangeNumber(product_id, product_number);
    //     };

    //     const handleFocus = () => {
    //       setCurrentNumber(row?.number);
    //       set_product_id(row?.id);
    //     };

    //     const handleClick = () => {
    //       set_product_id(row?.id);
    //     };

    //     return (
    //       <div
    //         style={{ width: "fit-content", cursor: "pointer" }}
    //         onClick={handleClick}
    //       >
    //         <input
    //           style={{ width: "120px", cursor: "pointer" }}
    //           type="text"
    //           onChange={(e) =>
    //             setProductData({ id: row?.id, number: e.target.value })
    //           }
    //           onFocus={() => handleFocus()}
    //           defaultValue={
    //             productData?.id == row?.id ? productData?.number : row?.number
    //           }
    //           value={
    //             productData?.id == row?.id ? productData?.number : row?.number
    //           }
    //           disabled={loader}
    //           className={
    //             product_id === row?.id
    //               ? "MR_input_form_element active"
    //               : "MR_input_form_element lazy"
    //           }
    //           onBlur={async (e) => {
    //             await handleBlur(row?.id, e.target.value);
    //           }}
    //         />
    //       </div>
    //     );
    //   },
    // },
    {
      label: language == 'ar' ? "العنوان" : "Title",
      dataIndex: language == 'ar' ? "title_ar" : "title_en",
    },
    // {
    //   label: "الصورة",
    //   dataIndex: "id",
    //   type: "children",
    //   children: ({ row }) => {
    //     return (
    //       <div className="table_row_image">
    //         <img src={row.image} alt="image" />
    //       </div>
    //     );
    //   },
    // },
    {
      label: language == 'ar' ? "صورة الخلفية" : "Background Image",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="table_row_image">
            <img src={row.background_image} alt="background_image" />
          </div>
        );
      },
    },
    {
      label: language == 'ar' ? "الرابط" : "Link",
      dataIndex: "link",
    },
    {
      label: language == 'ar' ? "اسم الزر" : "button name",
      dataIndex: "button_name",
    },

    // {
    //   label: "وقت الانشاء",
    //   dataIndex: "time",
    //   type: "children",
    //   children: ({ row }) => {
    //     return <div>{getOlnlyDate(row.created_at)}</div>;
    //   },
    // },
    {
      label: language == 'ar' ? "النوع" : 'type',
      dataIndex: "banner_type",
    },

    {
      label: language == 'ar' ? "الحالة" : 'status',
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
          >
            {row.hidden == "0" ? language == 'ar' ? "ظاهر" : 'showen' : language == 'ar' ? "مخفي" : "hidden"}
          </div>
        );
      },
    },
    {
      label: language == 'ar' ? " أوامر" : "Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${row.hidden == "0" ? "text-success" : "text-danger"
                }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              <button className="btn btn-success">{row.hidden == "0" ?language =="ar" ?"إخفاء":"Hide":language =="ar" ?"إظهار":"Show"}</button>
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                // setUpdateModal(true);
                setImgUrl(row.image);
                setImg(row.image);
                setBackImg(row.background_image);
                setShowUpdateBanner(true);
                console.log(row);
              }}
            >
              <button className="btn btn-success">{language =="ar" ?"تعديل":"Edit"}</button>
            </div>
          </div>
        );
      },
    },
    // {
    //   label: " تعديل",
    //   type: "children",
    //   children: ({row}) => {
    //     return (
    //       <div
    //         className='text-primary'
    //         style={{cursor: "pointer"}}
    //         onClick={() => {
    //           setRowData(row);
    //           setUpdateModal(true);
    //         }}
    //       >
    //         <button className="btn btn-success">{language =="ar" ?"تعديل":"Edit"}</button>
    //       </div>
    //     );
    //   },
    // },
  ];

  const onBannerImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImg(URL.createObjectURL(e.target.files[0]));
      console.log(URL.createObjectURL(e.target.files[0]));
      console.log("hello");
      setSelectedImgFile(e.target.files[0]);
      console.log(e.target.files);
    }
  };

  const onBannerBackChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setBackImg(URL.createObjectURL(e.target.files[0]));
      setSelectedBackFile(e.target.files[0]);
      console.log(e.target.files);
    }
  };

  const handleAddNewBanner = async (imageLink) => {
    // add banner image
    console.log("add file");

    // if (!newBanner.title_ar) {
    //   toast.error(" قم بإضافة اسم البانر باللغة العربية أولاً");
    //   return;
    // }

    // if (!newBanner.title_en) {
    //   toast.error(" قم بإضافة اسم البانر باللغة الإنجليزية أولاً");
    //   return;
    // }

    // if (!img) {
    //   toast.error("لم تقم بإضافة صورة البانر");
    //   return;
    // }

    // if(!backImg){
    //   toast.error("لم تقم بإضافة خلفية البانر");
    //   return;

    // }

    const handleAddFile = async () => {
      const formData = new FormData();
      formData.append("image", selectedImgFile);
      await axios
        .post(`${BASE_URL}upload_image`, formData)
        .then((res) => {
          console.log(res);

          if (res.data != "" && (res.status == 201 || res.status == 'success')) {
            setImgUrl(res?.data?.result.image);
            // handleAddNewCategory(res?.data?.result?.image);
          } else if (res.data == "") {
            toast.error(language == 'ar' ? "هناك مشكلة في رفع الصورة" : "Faild To Upload Image");
          } else {
            toast.error(language == 'ar' ? 'حدث خطأ ما' : "Something Went Error");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          // setImgLoading(false);
          // setShowAddCatModal(false);
          setAddLoading(false);
        });
    };

    handleAddFile();

    const handleAddBackgroundFile = async () => {
      const formData = new FormData();
      formData.append("image", selectedBackFile);
      await axios
        .post(`${BASE_URL}upload_image`, formData)
        .then((res) => {
          console.log(res);

          if (res.data != "" && (res.status == 201 || res.status == 'success')) {
            setImgbackUrl(res?.data?.result.image);
            // handleAddNewCategory(res?.data?.result?.image);
          } else if (res.data == "") {
            toast.error(language == 'ar' ? "هناك مشكلة في رفع الصورة" : "Faild To Upload Image");
          } else {
            toast.error(language == 'ar' ? 'حدث خطأ ما' : "Something Went Error");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          // setImgLoading(false);
          setShowAddCatModal(false);
          setAddLoading(false);
        });
    };

    handleAddBackgroundFile();
  };

  const handleAddNew = async () => {
    setAddLoading(true)
    if (!backImg) {
      toast.error(language == 'ar' ? "لم تقم بإضافة خلفية البانر" : 'Enter Background Image');
      return;
    }
    const token = localStorage.getItem("SediHishamAdmindata");

    const dataset = {
      ...newBanner,
      button_name: newBanner.button_name,
      link: newBanner.link,
      title_en: newBanner.title_en,
      button_name_en: newBanner.button_name_en,
      title_ar: newBanner.title_ar,
      image: imgUrl,
      background_image: imgbackUrl,
      banner_type: selectedType,
    };

    // if(true){
    //   console.log(dataset)
    //   return
    // }

    await axios
      .post(`${BASE_URL}banners/add_new?${token}`, dataset, {
        headers: {
          lang: language
        }
      })
      .then((res) => {
        console.log(res);

        if (res?.data && res?.data?.status == "success") {
          toast.success(res.data.message);
          getAllBanners();
        } else if (res.data.status == "error" || res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == 'ar' ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setNewBanner({
          title_ar: "",
          title_en: "",
          color: "",
        });
        setShowAddCatModal(false);
        setAddLoading(false);
        setImg("");
        setAddLoading(false)
        // setSelectedFile(null);
      });
  };

  const updateNewBanner = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    if (selectedBackFile && selectedImgFile) {
      const dataset = {
        ...rowData,
        button_name: rowData.button_name,
        link: rowData.link,
        title_en: rowData.title_en,
        button_name_en: rowData.button_name_en,
        title_ar: rowData.title_ar,
        image: imgUrl,
        background_image: imgbackUrl,
      };

      // if(true){
      //   console.log(dataset)
      //   return
      // }
      setUpdateLoading(true);
      await axios
        .post(
          `${BASE_URL}banners/update_banner/${rowData.id}?${token}`,
          dataset, {
          headers: {
            lang: language
          }
        }
        )
        .then((res) => {
          console.log(res);

          if (res?.data && res?.data?.status == "success") {
            toast.success(res?.data?.message);
            getAllBanners();
            console.log(res.data.result);
          } else if (res.data.status == "error" || res.data.status == "faild") {
            toast.error(res.data.message);
          } else {
            toast.error(language == 'ar' ? "حدث خطأ ما" : "Something Went Error");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setSelectedImgFile(null);
          setSelectedBackFile(null);
          setUpdateLoading(false);
          setRowData({});
          setBackImg('');
          setImg("");
          setShowUpdateBanner(false);
          setSwBtns({
            titleLang: false,
            buttonNameLang: false,
          });
        });
    } else if (selectedImgFile && !selectedBackFile) {
      const dataset = {
        ...rowData,
        button_name: rowData.button_name,
        link: rowData.link,
        title_en: rowData.title_en,
        button_name_en: rowData.button_name_en,
        title_ar: rowData.title_ar,
        image: imgUrl,
        background_image: rowData.background_image,
      };

      // if(true){
      //   console.log(dataset)
      //   return
      // }
      setUpdateLoading(true);

      await axios
        .post(
          `${BASE_URL}banners/update_banner/${rowData.id}?${token}`,
          dataset, {
          headers: {
            lang: language
          }
        }
        )
        .then((res) => {
          console.log(res);

          if (res?.data && res?.data?.status == "success") {
            toast.success(res.data.message);
            getAllBanners();
          } else if (res.data.status == "error" || res.data.status == "faild") {
            toast.error(res.data.message);
          } else {
            toast.error(language == 'ar' ? "حدث خطأ ما" : "Something Went Error");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setUpdateLoading(false);

          setRowData({});
          setSwBtns({
            titleLang: false,
            buttonNameLang: false,
          });
          setBackImg('');
          setImg("");
          setShowUpdateBanner(false);
          setSelectedImgFile(null);
          setSelectedBackFile(null);
        });
    } else if (!selectedImgFile && selectedBackFile) {
      const dataset = {
        ...rowData,
        button_name: rowData.button_name,
        link: rowData.link,
        title_en: rowData.title_en,
        button_name_en: rowData.button_name_en,
        title_ar: rowData.title_ar,
        image: rowData.image,
        background_image: imgbackUrl,
      };

      // if(true){
      //   console.log(dataset)
      //   return
      // }
      setUpdateLoading(true);

      await axios
        .post(
          `${BASE_URL}banners/update_banner/${rowData.id}?${token}`,
          dataset, {
          headers: {
            lang: language
          }
        }
        )
        .then((res) => {
          console.log(res);

          if (res?.data && res?.data?.status == "success") {
            toast.success(res.data.message);
            getAllBanners();
            console.log(res.data.result);
          } else if (res.data.status == "error" || res.data.status == "faild") {
            toast.error(res.data.message);
          } else {
            toast.error(language == 'ar' ? "حدث خطأ ما" : "Something Went Error");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setUpdateLoading(false);
          setSwBtns({
            titleLang: false,
            buttonNameLang: false,
          });

          setRowData({});
          setBackImg('');
          setImg("");
          setSelectedImgFile(null);
          setSelectedBackFile(null);
          setShowUpdateBanner(false);
        });
    } else {
      updateBannerDataWithoutImages();
    }
  };

  const updateBannerDataWithoutImages = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    const dataset = {
      ...rowData,
      button_name: rowData.button_name,
      link: rowData.link,
      title_en: rowData.title_en,
      button_name_en: rowData.button_name_en,
      title_ar: rowData.title_ar,
      image: rowData.image,
      background_image: rowData.background_image,
    };

    // if(true){
    //   console.log(dataset)
    //   return
    // }
    setUpdateLoading(true);

    await axios
      .post(
        `${BASE_URL}banners/update_banner/${rowData.id}?${token}`,
        dataset
        , {
          headers: {
            lang: language
          }
        }
      )
      .then((res) => {
        console.log(res);

        if (res?.data && res?.data?.status == "success") {
          toast.success(res.data.message);
          getAllBanners();
          console.log(res.data.result);
        } else if (res.data.status == "error" || res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == 'ar' ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setUpdateLoading(false);

        setRowData({});
        setBackImg('');
        setSwBtns({
          titleLang: false,
          buttonNameLang: false,
        });
        setShowUpdateBanner(false);
        setImg("");
      });
  };

  const handleUpdateBannersImages = async () => {
    const handleAddFile = async () => {
      const formData = new FormData();
      formData.append("image", selectedImgFile);
      setImageLoading(true);
      await axios
        .post(`${BASE_URL}upload_image`, formData)
        .then((res) => {
          console.log(res);

          if (res.data != "" && (res.status == 201 || res.status == 'success')) {
            setImgUrl(res?.data?.result.image);
            // handleAddNewCategory(res?.data?.result?.image);
            toast.success(language == 'ar' ? "   تم رفع الصورة بنجاح" : "Success To Uloadp");
          } else if (res.data == "") {
            toast.error(language == 'ar' ? "هناك مشكلة في رفع الصورة" : "Some Errors in uploading");
          } else {
            toast.error(language == 'ar' ? "هناك مشكلة في رفع الصورة" : "Some Errors in uploading");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setImageLoading(false);
        });
    };

    const handleAddBackgroundFile = async () => {
      const formData = new FormData();
      formData.append("image", selectedBackFile);
      setImageLoading(true);
      await axios
        .post(`${BASE_URL}upload_image`, formData)
        .then((res) => {
          console.log(res);

          if (res.data != "" && (res.status == 201 || res.status == 'susccess')) {
            setImgbackUrl(res?.data?.result.image);
            // handleAddNewCategory(res?.data?.result?.image);
            toast.success(language == 'ar' ? "   تم رفع الصورة بنجاح" : "Success To Uloadp");
          } else if (res.data == "") {
            toast.error(language == 'ar' ? "هناك مشكلة في رفع الصورة" : "Some Errors in uploading");
          } else {
            toast.error(language == 'ar' ? "هناك مشكلة في رفع الصورة" : "Some Errors in uploading");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          // setImgLoading(false);
          // setShowAddCatModal(false);
          // setAddLoading(false);
          setImageLoading(false);
        });
    };

    if (selectedImgFile && selectedBackFile) {
      handleAddFile();
      handleAddBackgroundFile();
    } else if (selectedBackFile == null && selectedImgFile) {
      handleAddFile();
    } else if (selectedImgFile == null && selectedBackFile) {
      handleAddBackgroundFile();
    }
  };

  const handleAddFile = async () => {
    //   console.log("add file");
    //   if (!newBanner.title_ar) {
    //     toast.error(" قم بإضافة اسم الفئة باللغة العربية أولاً");
    //     return;
    //   }
    //   if (!newBanner.title_en) {
    //     toast.error(" قم بإضافة اسم الفئة باللغة الإنجليزية أولاً");
    //     return;
    //   }
    //   if (newBanner.color == "") {
    //     toast.error(" قم بإضافة لون الفئة أولاً");
    //     return;
    //   }
    //   if (!img) {
    //     toast.error("لم تقم بإضافة صورة");
    //     return;
    //   }
    //   setAddLoading(true);
    //   // setImgLoading(true);
    //   const formData = new FormData();
    //   formData.append("image", selectedFile);
    //   await axios
    //     .post(`${BASE_URL}upload_image`, formData)
    //     .then((res) => {
    //       console.log(res);
    //       if (res.data != "" && res.status == 201) {
    //         // setImgUrl(res?.data?.message);
    //         handleAddNewCategory(res?.data?.result?.image);
    //       } else if (res.data == "") {
    //         toast.error("هناك مشكلة في رفع الصورة");
    //       } else {
    //         toast.error("هناك مشكلة في رفع الصورة");
    //       }
    //     })
    //     .catch((e) => console.log(e))
    //     .finally(() => {
    //       // setImgLoading(false);
    //       setShowAddCatModal(false);
    //       setAddLoading(false);
    //     });
  };

  const updateCategoryData = async (updatedImage) => {
    const token = localStorage.getItem("SediHishamAdmindata");

    const dataset = {
      ...rowData,
      image: updatedImage,
    };

    await axios
      .post(`${BASE_URL}categories/update_category?${token}`, dataset, {
        headers: {
          lang: language
        }
      })
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success(res.data.message);
          getAllBanners();
          console.log(res.data.result);
        } else if (res.data.status == "error" || res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == 'ar' ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setUpdateModal(false);
        setRowData({});

        setImg("");
        setImgUrl("");
        // setSelectedFile(null);
      });
  };

  const handleUpdateCategoryImage = async () => {
    //   if (!rowData.title_ar) {
    //     toast.error(" قم بإضافة اسم الفئة باللغة العربية أولاً");
    //     return;
    //   }
    //   if (!rowData.title_en) {
    //     toast.error(" قم بإضافة اسم الفئة باللغة الإنجليزية أولاً");
    //     return;
    //   }
    //   if (rowData.color == "") {
    //     toast.error(" قم بإضافة لون الفئة أولاً");
    //     return;
    //   }
    //   setUpdateLoading(true);
    //   console.log(img);
    //   if (selectedFile) {
    //     // setImgLoading(true);
    //     const formData = new FormData();
    //     formData.append("image", selectedFile);
    //     await axios
    //       .post(`${BASE_URL}upload_image`, formData)
    //       .then((res) => {
    //         console.log(res);
    //         if (res.data != "" && res.status == 201) {
    //           // setImgUrl(res?.data?.message);
    //           console.log(res?.data?.result?.image);
    //           updateCategoryData(res?.data?.result?.image);
    //         } else if (res.data == "") {
    //           toast.error("هناك مشكلة في رفع الصورة");
    //         } else {
    //           toast.error("هناك مشكلة في رفع الصورة");
    //         }
    //       })
    //       .catch((e) => console.log(e))
    //       .finally(() => {
    //         // setImgLoading(false);
    //       });
    //   } else {
    //     updateCategoryData(rowData.image);
    //   }
    //   setUpdateLoading(false);
  };

  const handleShow_hide = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    const dataset = {
      id: rowData.id,
    };

    setChangeStatusLoading(true);

    await axios
      .get(`${BASE_URL}banners/chage_status/${rowData.id}?${token}`, {
        headers: {
          lang: language
        }
      })
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success(
            res.data.message
          );
          getAllBanners();
        } else if (res.data.status == "error" || res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == 'ar' ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      });
  };

  // filteraiton part

  useEffect(() => {
    if (originalData && originalData.length >= 1) {
      if (searchValue.length > 0) {
        console.log(searchValue);
        const newData = originalData.filter((cat) => {
          if (
            searchValue.length >= 1 &&
            !cat.title_ar?.toLowerCase()?.includes(searchValue) &&
            !cat.title_en?.toLowerCase()?.includes(searchValue)
          ) {
            return false;
          }
          return true;
        });
        setBanners(newData);
      } else {
        setBanners(originalData);
      }
    }
  }, [searchValue]);

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5>{language == 'ar' ? 'البانرات' : 'Banners'}</h5>
            <div onClick={() => setShowAddCatModal(true)} className="btn btn-success" >{language == 'ar' ? 'إضافة بانر' : 'Add Banner'}</div>
          </div>

          <div className="searchInput field_input">
            <input
              type="text"
              placeholder={language == 'ar' ? "بحث..." : "Search..."}
              onChange={(e) => {
                setSearchValue(e.target.value?.toLowerCase());(e.target.value?.toLowerCase());
              }}
            />
          </div>

          {dataLoading ? (
            <Loader size="md" />
          ) : (
            <TableLayout headers={bannersHeaders} data={Banners}      finalDraggedRow={finalDraggedRow}
            setFinalDraggedRow={setFinalDraggedRow}
            draggedRow={draggedRow}
            setDraggedRow={setDraggedRow}/>
          )}
        </div>
      </div>

      <PopUp
        open={showAddCatModal}
        setOpen={setShowAddCatModal}
        title={language == 'ar' ? "إضافة بانر " : "Add Banner"}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="inputs">
              <div className="field_input w-100">
                <div className="d-flex align-items-center gap-4">
                  <label htmlFor="">
                    {language == 'ar' ? "عنوان البانر باللغة" : "Banner Title In"}{" "}
                    {swBtns.titleLang ? language == 'ar' ? "الإنجليزية" : " english " : language == 'ar' ? " العربية " : " arabic "}
                  </label>

                  <div class="toggle-switch">
                    <input
                      class="toggle-input"
                      id="title"
                      type="checkbox"
                      onChange={(e) => {
                        setSwBtns({
                          ...swBtns,
                          titleLang: e.target.checked,
                        });
                      }}
                    />
                    <label class="toggle-label" for="title"></label>
                  </div>
                </div>

                <input
                  type="text"
                  value={
                    swBtns.titleLang ? newBanner.title_en : newBanner.title_ar
                  }
                  onChange={(e) => {
                    swBtns.titleLang
                      ? setNewBanner({
                        ...newBanner,
                        title_en: e.target.value,
                      })
                      : setNewBanner({
                        ...newBanner,
                        title_ar: e.target.value,
                      });
                  }}
                />
              </div>

              <div className="field_input w-100">
                <div className="d-flex align-items-center gap-4">
                  <label htmlFor="">
                    {language == 'ar' ? ' اسم الزر ' : 'Button Name'} {swBtns.buttonNameLang ? language == 'ar' ? "الإنجليزية" : " english " : language == 'ar' ? " العربية" : " Arabic"}
                  </label>

                  <div class="toggle-switch">
                    <input
                      class="toggle-input"
                      id="button"
                      type="checkbox"
                      onChange={(e) => {
                        setSwBtns({
                          ...swBtns,
                          buttonNameLang: e.target.checked,
                        });
                      }}
                    />
                    <label class="toggle-label" for="button"></label>
                  </div>
                </div>

                <input
                  type="text"
                  value={
                    swBtns.buttonNameLang
                      ? newBanner.button_name_en
                      : newBanner.button_name
                  }
                  onChange={(e) => {
                    swBtns.buttonNameLang
                      ? setNewBanner({
                        ...newBanner,
                        button_name_en: e.target.value,
                      })
                      : setNewBanner({
                        ...newBanner,
                        button_name: e.target.value,
                      });
                  }}
                />
              </div>

              <div className="field_input">
                <label htmlFor="">{language == 'ar' ? 'الرابط' : "Link"}</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewBanner({
                      ...newBanner,
                      link: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="field_input">
              <label htmlFor="">{language == 'ar' ? 'نوع البانر' : 'Banner Type'}</label>
              <select
                name=""
                id=""
                value={selectedType}
                onChange={(e) => setSelectedType(e?.target?.value)}
              >
                {type?.map((item, index) => {
                  return <option value={item}>{item}</option>;
                })}
              </select>
            </div>
            <div className="d-flex align-items-center justify-content-around mt-3 flex-wrap">
              <div className="field_input">
                <label htmlFor="">{language == 'ar' ? 'اختر صورة الخلفية' : 'Enter Background Image'}</label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image"}
                    className="w-100"
                  >
                    <img
                      width={100}
                      src={
                        backImg == ""
                          ? "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                          : backImg
                      }
                      alt=""
                    />
                  </label>
                  <input
                    id={"add-image"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onBannerBackChange(e)}
                  />

                  {backImg && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setBackImg("");
                        setSelectedBackFile(null);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>
            {/* <button
              onClick={() => handleAddNewBanner()}
              className="popup_agree_btn"
            >
              {addLoading ? <Loader /> : language=='ar'?"رفع الصور":"Upload Image"}
            </button> */}

            <button onClick={() => handleAddNew()} className="popup_agree_btn">
              {addLoading ? <Loader /> : language == 'ar' ? "إضافة" : "Add"}
            </button>
          </form>
        }
      />

      <PopUp
        open={showUpdateBanner}
        setOpen={setShowUpdateBanner}
        title={language == 'ar' ? "تعديل بانر " : "Edit Banner"}
        onClose={() => {
          setSelectedBackFile(null);
          setSelectedImgFile(null);
        }}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="inputs">
              <div className="field_input w-100">
                <div className="d-flex align-items-center gap-4">
                  <label htmlFor="">
                    {language == 'ar' ? 'عنوان البانر باللغة' : 'Banner Title In '}{" "}
                    {swBtns.titleLang ? language == 'ar' ? "الإنجليزية" : " english " : language == 'ar' ? " العربية " : " arabic "}
                  </label>

                  <div class="toggle-switch">
                    <input
                      class="toggle-input"
                      id="title"
                      value={swBtns.titleLang}
                      type="checkbox"
                      onChange={(e) => {
                        setSwBtns({
                          ...swBtns,
                          titleLang: e.target.checked,
                        });
                      }}
                    />
                    <label class="toggle-label" for="title"></label>
                  </div>
                </div>

                <input
                  type="text"
                  value={swBtns.titleLang ? rowData.title_en : rowData.title_ar}
                  onChange={(e) => {
                    swBtns.titleLang
                      ? setRowData({
                        ...rowData,
                        title_en: e.target.value,
                      })
                      : setRowData({
                        ...rowData,
                        title_ar: e.target.value,
                      });
                  }}
                />
              </div>

              <div className="field_input w-100">
                <div className="d-flex align-items-center gap-4">
                  <label htmlFor="">
                    {language == 'ar' ? "اسم الزر " : "Button Name In"}{swBtns.buttonNameLang ? language == 'ar' ? " الإنجليزية " : " English " : language == 'ar' ? " العربية " : " Arabic "}
                  </label>

                  <div class="toggle-switch">
                    <input
                      value={swBtns.buttonNameLang}
                      class="toggle-input"
                      id="button"
                      type="checkbox"
                      onChange={(e) => {
                        setSwBtns({
                          ...swBtns,
                          buttonNameLang: e.target.checked,
                        });
                      }}
                    />
                    <label class="toggle-label" for="button"></label>
                  </div>
                </div>

                <input
                  type="text"
                  value={
                    swBtns.buttonNameLang
                      ? rowData.button_name_en
                      : rowData.button_name
                  }
                  onChange={(e) => {
                    swBtns.buttonNameLang
                      ? setRowData({
                        ...rowData,
                        button_name_en: e.target.value,
                      })
                      : setRowData({
                        ...rowData,
                        button_name: e.target.value,
                      });
                  }}
                />
              </div>

              <div className="field_input">
                <label htmlFor="">{language == 'ar' ? 'الرابط' : 'Link '}</label>
                <input
                  value={rowData.link}
                  type="text"
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      link: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="field_input">
              <label htmlFor="">{language == 'ar' ? ' نوع البانر' : 'Banner Type '}</label>
              <select
                name=""
                id=""
                value={rowData?.banner_type ? rowData?.banner_type : "box1"}
                onChange={(e) =>
                  setRowData({
                    ...rowData,
                    banner_type: e.target.value,
                  })
                }
              >
                {type?.map((item, index) => {
                  return <option value={item}>{item}</option>;
                })}
              </select>
            </div>
            <div className="d-flex align-items-center justify-content-around mt-3 flex-wrap">
              <div className="field_input">
                <label htmlFor="">{language == 'ar' ? 'اختر صورة الخلفية' : 'Enter Backgrount Image'}</label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image"}
                    className="w-100"
                  >
                    <img
                      width={100}
                      src={
                        backImg == ""
                          ? "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                          : backImg
                      }
                      alt=""
                    />
                  </label>
                  <input
                    id={"add-image"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onBannerBackChange(e)}
                  />
                </div>
              </div>
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                if (!imageLoading) {
                  handleUpdateBannersImages();
                }
              }}
              className="popup_agree_btn"
            >
              {imageLoading ? <Loader /> : language == 'ar' ? "رفع الصور" : "Upload Image"}
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                if (!updateLoading) {
                  updateNewBanner();
                }
              }}
              className="popup_agree_btn"
            >
              {updateLoading ? <Loader /> : language == 'ar' ? "تعديل" : 'Edit'}
            </button>
          </form>
        }
      />

      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={language == 'ar' ? "تغيير حالة الظهور" : "Change Appearance Status"}
        children={
          <div className="">
            <h5 className="">
              {language == 'ar' ? 'هل تريد بالفعل ' : 'Do you really want to '}{rowData.hidden == "0" ? language == 'ar' ? " إخفاء " : " Hide " : language == 'ar' ? " إظهار " : " Show "}
              {language == 'ar' ? ' هذا البانر ' : ' This Banner '}
            </h5>

            <button
              onClick={() => handleShow_hide()}
              className="popup_agree_btn"
            >
              {changeStatusLoading ? (
                <Loader />
              ) : rowData.hidden == "0" ? (
                language == 'ar' ? " إخفاء " : " hide "
              ) : (
                language == 'ar' ? " Show " : " إظهار "
              )}
            </button>
          </div>
        }
      />
    </>
  );
};

export default BannersPage;
