import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Axios } from "../../../components/Axios";
import { BASE_URL } from "../../../components/Axios/base_uri";

const initialState = {
  data: {},
  loading: false,
  loggedIn: 0,
};

let getLocalStorageData = localStorage.getItem("SediHishamAdmindata");
try {
  getLocalStorageData = JSON.parse(localStorage.getItem("SediHishamAdmindata"));
} catch (error) {
  getLocalStorageData = localStorage.getItem("SediHishamAdmindata");
}

export const fetchUserData = createAsyncThunk("user/fetchData", async () => {
  const fetchData = await Axios({
    url: "users/getoneuser/" + getLocalStorageData[0][1],
    fullUrl:"https://api.camp-coding.site/users/getoneuser/51",
    method: "GET",
  });
  return fetchData;
});

const userSlice = createSlice({
  initialState,
  name: "userData",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData?.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        if (action?.payload?.id) {
          state.data = action.payload;
          state.loading = false;
          state.loggedIn = 1;
        } else {
          state.loading = false;
          state.loggedIn = 0;
        }
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        state.loggedIn = 0;
      });
  },
});

export default userSlice?.reducer;
