export const links = (email) => {
  return [
    {
      to: "/",
      icon: "fas fa-qrcode",
      labelArabic: "الرئيسية",
      labelEnglish: "Home",
    },
    // {
    //   to: "/notifications",
    //   icon: "fa-regular fa-bell",
    //   labelArabic: "الإشعارات",
    //   labelEnglish: "Notification",
    // },
    {
      to: "/Messages",
      icon: "fa-regular fa-bell",
      labelArabic: "الرسائل",
      labelEnglish: "Messages",
    },
    {
      to: "/categories",
      icon: "fas fa-link",
      labelArabic: "الفئات",
      labelEnglish: "Categories",
    },
    {
      to: "/products",
      icon: "fas fa-stream",
      labelArabic: "المنتجات",
      labelEnglish: "Products",
    },
    {
      to: "/Orders",
      icon: "fas fa-calendar-week",
      labelArabic: "الطلبات",
      labelEnglish: "Orders",
    },
    // email == "admin@greentrees.com"
    //   ? {
    //       to: "/management",
    //       icon: "fa-solid fa-user-tie",
    //       labelArabic: "الإدارة",
    //       labelEnglish: "Management",
    //     }
    //   : {},
    // {
    //   to: "/Sections",
    //   icon: "fa-solid fa-user-tie",
    //   labelArabic: "السكاشن",
    //   labelEnglish: "Sections",
    // },
    // {
    //   to: "/about",
    //   icon: "far fa-question-circle",
    //   labelArabic: "عن الموقع",
    //   labelEnglish: "About",
    // },
    {
      to: "/ideas",
      icon: "fa-regular fa-lightbulb",
      labelArabic: "الطبخات",
      labelEnglish: "Cooks",
    },
    {
      to: "/Jops",
      icon: "fa-regular fa-lightbulb",
      labelArabic: "الوظائف",
      labelEnglish: "Jops",
    },
    {
      to: "/JopsManagment",
      icon: "fa-regular fa-lightbulb",
      labelArabic: "إدارة الوظائف",
      labelEnglish: "Jops Managment",
    },
    {
      to: "/coupons",
      icon: "fas fa-medal",
      labelArabic: "الكوبونات",
      labelEnglish: "Coupons",
    },
    {
      to: "/users",
      icon: "fa-solid fa-user",
      labelArabic: "المستخدمين الإداريين",
      labelEnglish: "Administrators",
    },
    {
      to: "/customers",
      icon: "fa-solid fa-user",
      labelArabic: "الزبائن",
      labelEnglish: "Customers",
    },
    {
      to: "/News",
      icon: "fa-solid fa-user",
      labelArabic: "الأخبار",
      labelEnglish: "News",
    },
    {
      to: "/Activity",
      icon: "fa-solid fa-user",
      labelArabic: "الفعاليات والنشاطات",
      labelEnglish: "Activities",
    }, // {
    //   to: "/banners",
    //   icon: "fas fa-images",
    //   labelArabic: "البانرات",
    //   labelEnglish: "Banners",
    // },
    // {
    //   to: "/brands",
    //   icon: "fa-solid fa-tag",
    //   labelArabic: "الماركات",
    //   labelEnglish: "Brands",
    // },
    // {
    //   to: "/rejions",
    //   icon: "fas fa-truck",
    //   labelArabic: "مناطق الوصول",
    //   labelEnglish: "Locations",
    // },
    // {
    //   to: "/footerdetails",
    //   icon: "fas fa-sort-down",
    //   labelArabic: "بيانات الفوتر",
    //   labelEnglish: "Footer Info",
    // },
    // {
    //   to: "/coupons",
    //   icon: "fas fa-medal",
    //   labelArabic: "الكوبونات",
    //   labelEnglish: "Coupons",
    // },
    // {
    //   to: "/commonquestions",
    //   icon: "fa-regular fa-circle-question",
    //   labelArabic: "الأسئلة الشائعة",
    //   labelEnglish: "Faq",
    // },
    // {
    //   to: "/contacts",
    //   icon: "far fa-envelope",
    //   labelArabic: "معلومات التواصل",
    //   labelEnglish: "Contact",
    // },
  ];
};

export const Archived = [
  {
    to: "/ArchivedCategories",
    icon: "fas fa-qrcode",
    labelArabic: "أرشيف الفئات",
    labelEnglish: "Archived Categories",
  },
  {
    to: "/ArchivedProducts",
    icon: "fas fa-qrcode",
    labelArabic: "أرشيف المنتجات",
    labelEnglish: "Archived Products",
  },
  {
    to: "/BrandsArchive",
    icon: "fas fa-qrcode",
    labelArabic: "أرشيف الماركات",
    labelEnglish: "Brands Archive",
  },
  {
    to: "/BannersArchive",
    icon: "fas fa-qrcode",
    labelArabic: "أرشيف البانرات",
    labelEnglish: "Banners Archive",
  },
  {
    to: "/SubCategoryArchived",
    icon: "fas fa-qrcode",
    labelArabic: "أرشيف الفئات الفرعية",
    labelEnglish: "Sub Categories Archive",
  },
  {
    to: "/SectionsArchive",
    icon: "fas fa-qrcode",
    labelArabic: "أرشيف السكاشن",
    labelEnglish: "Sections Archive",
  },
  {
    to: "/ArchivedIdeas",
    icon: "fas fa-qrcode",
    labelArabic: "أرشيف الأفكار",
    labelEnglish: "Archived Ideas",
  },
  {
    to: "/RejionsArchive",
    icon: "fas fa-qrcode",
    labelArabic: "أرشيف المناطق",
    labelEnglish: "Archived Regions",
  },
  {
    to: "/DistrictsArchive",
    icon: "fas fa-qrcode",
    labelArabic: "أرشيف الأحياء",
    labelEnglish: "Archived Disricts",
  },
];
