import React, { useEffect, useState } from 'react';
import Sats from '../../components/stats';
import TableLayout from '../../components/table';
import "./style.css";
import axios from 'axios';
import { BASE_URL } from '../../components/Axios/base_uri';
import toast from 'react-hot-toast';
import { Loader } from 'rsuite';
import UseGeneral from '../../customHooks/useGeneral';
import Orders from '../orders';

const Home = () => {
  const { language } = UseGeneral();
  const [categories, setCategoreis] = useState(null);
  const [dataLoading, setDataLoading] = useState(null);
  const getCategories = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}statistics`)
      .then((res) => {
        console.log(res)
          setCategoreis(
            res?.data
          );


      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  const pdfsHeaders = [
    
    {
      label: language=='ar'?"اسم المنتج":'Product Name',
      dataIndex: language=='ar'?"title_ar":"title_en",
    },
    {
      label: language=='ar'?"سعر المنتج":"Product Price",
      dataIndex: "price",
    },
    {
      label: language=='ar'?" الخصم":"Discount",
      dataIndex: "discount",
    },
  ];
  useEffect(() => {
    getCategories()
  }, [])
  return (
    <>{!categories ? <Loader size='lg' style={{margin:"30px"}} /> : categories? <div className="columnDiv">
      <Sats stats={{...categories}} />
      <div className="rowDiv flex-2-1">
        <div>
          {/* <h5 style={{textAlign:'start'}}>{language=='ar'?'إدارة الطلبات':'Orders Managment'}</h5> */}
          {/* <TableLayout headers={pdfsHeaders} data={categories?.last_products} /> */}
          <Orders hideFilters={true}/>
        </div>
      </div>
    </div> : language=='ar'?"فشل في جلب البيانات":"Faild To Get Data"}</>
  );
};

export default Home;
