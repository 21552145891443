import axios from 'axios';
import React, { useEffect, useState } from 'react';
import TableLayout from '../../components/table';
import UseGeneral from '../../customHooks/useGeneral';
import toast from 'react-hot-toast';
import PopUp from '../../components/popup';

export default function Customers() {
  const language = UseGeneral();
  const [isLoading, setIsLoading] = useState(false);
  const [allCustomers, setAllCustomers] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [rowData, setRowData] = useState({});
  const [isShowAddModal, setIsShowAddModal] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [isShowEditModal, setIsShowEditModal] = useState(false);
  const [delItem, setDelItem] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const headers = [
    {
      label: "#",
      dataIndex: "id",
    },
    {
      label: "Gender",
      dataIndex: "gender",
      type: "children",
      children: ({ row }) => {
        return <p>{row.customer.gender}</p>;
      },
    },
    {
      label: "Name",
      dataIndex: "name",
      type: "children",
      children: ({ row }) => {
        return <p>{`${row.customer.first_name} ${row.customer.last_name}`}</p>;
      },
    },
    {
      label: "Email",
      dataIndex: "email",
      type: "children",
      children: ({ row }) => {
        return (
          <a style={{ fontSize: "18px" }} href={`mailto:${row.customer.email}`}>
            {row.customer.email}
          </a>
        );
      },
    },
    {
      label: "Phone Number",
      dataIndex: "phone_number",
      type: "children",
      children: ({ row }) => {
        return <p>{row.customer.phone_number}</p>;
      },
    },
    {
      label: "Points",
      dataIndex: "points",
      type: "children",
      children: ({ row }) => {
        return <p>{row.customer.points}</p>;
      },
    },
    {
      label: "Level",
      dataIndex: "level",
      type: "children",
      children: ({ row }) => {
        return <p>{row.customer.level}</p>;
      },
    },
    {
      label: "City",
      dataIndex: "city",
    },
    {
      label: "Registered",
      dataIndex: "createdAt",
      type: "children",
      children: ({ row }) => {
        return <p>{row.customer.createdAt?.split("T")?.[0]}</p>;
      },
    },
    {
      label: "أوامر",
      type: "children",
      children: ({ row }) => {
        return (
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <button
              onClick={() => {
                setDelItem(row.id);
                setIsShowDeleteModal(true);
              }}
              style={{
                backgroundColor: "red",
                color: "white",
                borderRadius: "5px",
              }}
            >
              <svg
                style={{ width: "20px", height: "15px" }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="white"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                />
              </svg>
            </button>

            {/* <button
              onClick={() => {
                console.log(row);
                setRowData(row);
                setIsShowEditModal(true);
              }}
              style={{
                backgroundColor: "#146c43",
                color: "white",
                borderRadius: "5px",
              }}
            >
              <svg
                style={{ width: "20px", height: "15px" }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                />
              </svg>
            </button> */}
          </div>
        );
      },
    },
  ];

  function handleGetAllCustomers() {
    axios
      .get('https://api.camp-coding.site/users/allcustomers')
      .then((res) => {
        console.log(res.data);
        if (res.status == 200) {
          setAllCustomers(res.data);
          setOriginalData(res.data);
        } else {
          toast.error(
            language == "ar" ? "حدث خطأ ما!" : "Something went wrong!"
          );
        }
      })
      .catch((e) => console.log(e));
  }

  // function handleSubmit(e) {
  //     e.preventDefault();
  //     const data_send = {
  //         ...customerData,
  //     }
  // }

  function handleEdit(e) {
    e.preventDefault();

    const data_send = {
      ...rowData,
      password: rowData.customer.password,
      newPassword: rowData.customer.password,
      gender: rowData.customer.gender,
      phone: rowData.customer.phone_number,
      email: rowData.customer.email,
      firstName: rowData.customer.first_name,
      lastName: rowData.customer.last_name,
    };
    console.log(data_send);
    axios
      .post(
        `https://api.camp-coding.site/update/customer/${rowData?.customer?.id}`,
        data_send
      )
      .then((res) => {
        console.log(res);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    handleGetAllCustomers();
  }, []);

  useEffect(() => {
    if (searchValue.length > 0) {
      setAllCustomers(
        originalData.filter(
          (data) =>
            data?.customer?.email?.toLowerCase().inclues(searchValue) ||
            data?.customer?.level?.toLowerCase().inclues(searchValue) ||
            data?.customer?.gender?.toLowerCase().inclues(searchValue) ||
            data.customer.first_name.toLowerCase().inclues(searchValue) ||
            data.customer.last_name.toLowerCase().inclues(searchValue)
        )
      );
      return;
    } else {
      setAllCustomers(originalData);
    }
  }, [searchValue]);

  return (
    <div className="rowDiv flex-2-1 page_padding">
      <div>
        <div className="title_add">
          <h5>{language == "ar" ? "عملاء" : "Customers"}</h5>
          <div
            onClick={() => setIsShowAddModal(true)}
            className="btn btn-success"
          >
            {language == "ar" ? "إضافة عميل" : "Add Customer"}
          </div>
        </div>
      </div>

      <PopUp
        open={isShowEditModal}
        setOpen={setIsShowEditModal}
        title={language == "ar" ? "تعديل العميل" : "Edit Cutsomer"}
      >
        <form onSubmit={handleEdit}>
          <div className="inputs">
            <div className="field_input">
              <label>{language == "ar" ? "الاسم الأول" : "first Name"}</label>
              <input
                type="text"
                placeholder={language == "ar" ? "الاسم الأول" : "First Name"}
                onChange={(e) =>
                  setRowData({ ...rowData, firstName: e.target.value })
                }
              />
            </div>

            <div className="field_input">
              <label>{language == "ar" ? "الاسم الأخير" : "Last Name"}</label>
              <input
                type="text"
                placeholder={language == "ar" ? "الاسم الاخير" : "Last Name"}
                onChange={(e) =>
                  setRowData({ ...rowData, lastName: e.target.value })
                }
              />
            </div>

            <div className="field_input">
              <label>{language == "ar" ? "كلمه السر" : "Password"}</label>
              <input
                type="password"
                placeholder={language == "ar" ? "كلمه السر" : "Password"}
                onChange={(e) =>
                  setRowData({ ...rowData, password: e.target.value })
                }
              />
            </div>

            <div className="field_input">
              <label>{language == "ar" ? "البريد الالكتروني" : "Email"}</label>
              <input
                type="text"
                placeholder={language == "ar" ? "البريد الالكتروني" : "Email"}
                onChange={(e) =>
                  setRowData({ ...rowData, email: e.target.value })
                }
              />
            </div>

            <div className="field_input">
              <label>{language == "ar" ? "رقم الموبايل" : "Phone"}</label>
              <input
                type="text"
                placeholder={language == "ar" ? "رقم الموبايل" : "Phone"}
                onChange={(e) =>
                  setRowData({ ...rowData, phone: e.target.value })
                }
              />
            </div>

            <div className="field_input">
              <label>Gender</label>
              <select
                onChange={(e) =>
                  setRowData({ ...rowData, gender: e.target.value })
                }
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>

            <div style={{ display: "flex", gap: "10px" }}>
              <button className="popup_agree_btn">
                {isLoading ? "Loading..." : "Edit"}
              </button>
              <button
                onClick={() => setIsShowEditModal(false)}
                style={{
                  padding: "5px 10px",
                  marginTop: "20px",
                  borderRadius: "3px",
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </PopUp>
      <TableLayout headers={headers} data={allCustomers} />
    </div>
  );
}
