import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import "./style.css";
import { Loader } from "rsuite";
import axios from "axios";
import { BASE_URL } from "../../components/Axios/base_uri";
import SelectWithSearch from "../../components/selectWithSearch/SelectWithSearch";
import { addition, exitModal, plus } from "../../assets/svgIcons";
import Select from "react-select";
import imageAvatar from "../../assets/image_placeholder.png";

import toast from "react-hot-toast";
import UseGeneral from "../../customHooks/useGeneral";

const EditProductIdeasPage = () => {
  const {language}=UseGeneral();
  const location = useLocation();
  const { id } = useParams();
  const [productData, setProdcutData] = useState({});
  const [imagesLinks, setImagesLinks] = useState([]);

  const subCatId = location?.state?.subCat;
  console.log(subCatId)
  const subCatName = location?.state?.subCatName;
  const [addLoading, setAddLoading] = useState(false);
  const [selectedProds, setSelectedProd] = useState([]);

  const [defualtVals, setDefaultVasls] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);

  const [newProd, setNewProd] = useState({
    title_ar: productData?.title_ar,
    title_en: productData?.title_en,
    description_ar: productData?.description_ar,
    description_en: productData?.description_en,
    price: "",
    discount: "",
    hasOptions: 1,
    subCatId: '',
    // images:image1**green**image2**green**image3,
  });

  const [categories, setCategories] = useState(null);
  const [subCategories, setSubCategories] = useState(null);
  const [allProducts, setAllProducts] = useState(null);
  const [editLoading, setEditLoading] = useState(false);
  const [addImagesLoading, setAddImagesLoading] = useState(false);

  const [arIngs, setArIngs] = useState([
    {
      id: 1,
      ar_value: "",
      en_value: "",
      englishLang: false,
    },
  ]);

  const [steps, setSteps] = useState([
    {
      id: 1,
      ar_value: "",
      en_value: "",
      englishLang: false,
    },
  ]);

  const [products, setProducts] = useState([
    {
      id: 1,
      product_id: "",
      price: "",
      count: "",
    },
  ]);

  const [policies, setPolicies] = useState([
    {
      id: 1,
      value: "",
    },
  ]);

  const getCategories = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    await axios
      .get(`${BASE_URL}categories/get_categories_for_admin?${token}`,{
        headers:{
          lang:language
        }
      })
      .then((res) => {
        if (res.data.status == "success") {
          setCategories(
            res?.data?.result?.filter((item) => item?.hidden == "0")
          );
          console.log(res.data.result);
        } else if (res.data.status == "error"||res.data.status == "faild") {
          setCategories([]);
        } else {
          setCategories([]);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  const [data, setData] = useState({});
  const [subCatData, setSubCatData] = useState({});
  const [imagesUrl, setImagesUrl] = useState([]);
  const [hasOptions, setHasOptions] = useState(false);

  const getProductById = async () => {
    setDataLoading(true);
    await axios
      .post(`${BASE_URL}products/get_product_by_id/${id}`, { id: 1 },{
        headers:{
          lang:language
        }
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setProdcutData(res?.data?.result);
          setImagesLinks(res?.data?.result?.images);
          setHasOptions(res?.data?.result?.has_options == "1" ? true : false);
          console.log('ererer')
          const ingrediants =
            res?.data?.result?.ingrediants?.map((item) => {
              return {
                id: item.id,
                ar_value: item.text_ar,
                en_value: item.text_en,
                englishLang: false,
              };
            }) || [];

          setArIngs(ingrediants);

          const stepsArr =
            res?.data?.result?.steps?.map((item) => {
              return {
                id: item.id,
                ar_value: item.step_ar,
                en_value: item.step_en,
                englishLang: false,
              };
            }) || [];

          setSteps(stepsArr);

          const options =
            res?.data?.result?.options?.map((item, index) => {
              return {
                id: item.element_id,
                product_id: item.element_id,
                price: item?.price,
                count: item?.weight,
                title_ar: item?.product.title_ar,
                title_en: item?.product.title_en,
              };
            }) || [];

          const selectedOpts = res?.data?.result?.options?.map(
            (item, index) => item?.product?.id
          );
          console.log(selectedOpts);
          console.log(res?.data?.result?.options);

          setSelectedOptions(selectedOpts);

          setProducts(options);

          const policiesData = res?.data?.result?.returnpolicies?.map(
            (item, index) => {
              return {
                id: index + 1,
                value: item?.text,
              };
            }
          );

          setPolicies(policiesData);

          const relatedProds = res?.data?.result?.productrelateds?.map(
            (prod) => {
              return { value: +prod.product_id, label: prod.product.title_ar };
            }
          );

          console.log(relatedProds);
          setDefaultVasls([
            {
              value: 14,
              label: 'جديد',
            },
            {
              value: 15,
              label: 'جديد',
            },
          ]);

          const selects = allProducts?.map((prod) => {
            return { value: +prod.id, label: prod.title_ar };
          });
          console.log(selects);

          setNewProd({
            title_ar: res?.data?.result?.title_ar,
            title_en: res?.data?.result.title_en,
            description_ar: res?.data?.result.description_ar,
            description_en: res?.data?.result.description_en,
            price: res?.data?.result?.price,
            discount: res?.data?.result?.discount,
            // hasOptions: res?.data?.result?.has_options,
          });

          console.log(res.data.result);
        } else if (res.data.status == "error"||res.data.status == "faild") {
          setProdcutData([]);
        } else {
          setProdcutData([]);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getCategories();
    getAllProducts();
    console.log(subCatId);
    getProductById();
  }, []);

  useEffect(() => {
    // setNewProd({
    //   title_ar: productData?.title_ar,
    //   title_en: productData.title_en,
    //   description_ar: productData.description_ar,
    //   description_en: productData.description_en,
    //   price: productData?.price,
    //   discount: productData?.discount,
    //   // hasOptions: productData?.has_options,
    // });

    console.log(newProd);

    // console.log(productData?.images)

    console.log(policies);

    console.log(productData?.images);
  }, [productData]);

  const getSubCategories = async (id) => {
    // /categories/category_subcategories_for_admin/1?

    const token = localStorage.getItem("SediHishamAdmindata");
    await axios
      .get(
        `${BASE_URL}categories/category_subcategories_for_admin/${id}?${token}`,
        {
          headers:{
            lang:language
          }
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setSubCategories(
            res?.data?.result?.filter((item) => item?.hidden == "0")
          );
          console.log(res.data.result);
        } else if (res.data.status == "error"||res.data.status == "faild") {
          setSubCategories([]);
        } else {
          setSubCategories([]);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  useEffect(() => {
    getSubCategories(data?.id);
    setNewProd({
      ...newProd,
      subCatId: '',
    });
  }, [data]);

  useEffect(() => {
    getSubCategories(data?.id);
    setNewProd({
      ...newProd,
      subCatId: subCatData.id,
    });
  }, [subCatData]);

  const getAllProducts = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    await axios
      .get(`${BASE_URL}products/getAll_for_admin?${token}`,{
        headers:{
          lang:language
        }
      })
      .then((res) => {
        console.log(res);
        if (res?.data && res.data.status == "success") {
          setAllProducts(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error"||res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language=='ar'?"حدث خطأ ما":"Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  const [selectedOptions, setSelectedOptions] = useState(null);

  const handleMultiSelectChnage = (selectedOptions) => {
    const values = selectedOptions.map((option) => option.value);

    setSelectedOptions(values);
    console.log(values);
  };

  const [lang, setLang] = useState({
    prod_name: false,
    prod_desc: false,
  });

  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages([...selectedImages, ...files]);
    console.log(files);
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  const handleDeleteImageLink = (index) => {
    const updatedImages = [...imagesLinks];
    updatedImages.splice(index, 1);
    setImagesLinks(updatedImages);
    console.log(updatedImages);
  };

  const handleAddNewProduct = async () => {
    console.log(newProd?.title_ar);
    console.log(newProd?.description_ar);

    const allImages = imagesLinks.join("**green**");
    console.log(allImages);
    console.log(imagesLinks);
    const imgslinks = imagesLinks.map((item) => item.url);
    console.log([...imagesUrl, ...imgslinks]);
    const result = [...imagesUrl, ...imgslinks].join('**green**');

    // if(true){
    //   return;
    // }

    if (!newProd?.title_ar) {
      toast.error(language=='ar'?"قم بإدخال إسم المنتج باللغة العربية":"Enter Arabic Product Name");
      return;
    }
    if (!newProd?.title_en) {
      toast.error(language=='ar'?"قم بإدخال إسم المنتج باللغة الإنجليزية":"Enter English Arabic Name");
      return;
    }
    if (!newProd?.description_ar) {
      toast.error(language=='ar'?"قم بإدخال وصف المنتج باللغة العربية":"Enter Arabic Product Description");
      return;
    }
    if (!newProd?.description_en) {
      toast.error(language=='ar'?"قم بإدخال وصف المنتج باللغة الإنجليزية":"Enter English Product Description");
      return;
    }

    if (!newProd?.price) {
      toast.error("قم بإدخال سعر المنتج ");
      return;
    }

    if (!newProd?.price) {
      toast.error(language=='ar'?"قم بإدخال سعر الوحدة ":"Enter Unit Price");
      return;
    }

    if (+newProd.price < 0) {
      toast.error(" لا يمكن أن يكون السعر بقيمة سالبة ");
      return;
    }

    if (newProd.discount && +newProd.discount < 0) {
      toast.error(language=='ar'?" لا يمكن أن يكون السعر بقيمة سالبة ":"The price cannot be negative");
      return;
    }
    // if (!subCatData) {
    //   toast.error("قم بإدخال فئة فرعية أولا ");
    //   return;
    // }

    if (!result.length >= 1) {
      toast.error(language=='ar'?"قم بإضافة صور للمنتج":"Add Image To Product");
      return;
    }

    if (hasOptions) {
      if (!arIngs.length >= 1) {
        toast.error(language=='ar'?"قم بإدخال مُكوِن واحد على الأقل":"Enter one option at least");
        return;
      }

      const ingsHasNoValues = arIngs.find((item, index) => {
        if (item.ar_value == "" || item.en_value == "") {
          return index;
        }
      });

      const stepsHasNoValues = steps.find((item) => {
        if (item.ar_value == "" || item.en_value == "") {
          return item;
        }
      });
      const productsHasNoValues = products.find((item) => {
        if (!item.id || !item.count || !item.price) {
          return item;
        }
      });

      if (!arIngs.length >= 1) {
        toast.error(language=='ar'?"قم بإدخال مُكوِن واحد على الأقل":"Enter At Least One Ingrediant");
        return;
      }
      if (!steps.length >= 1) {
        toast.error(language=='ar'?"قم بإدخال خطوة تحضير واحدة على الأقل":"Enter At Least One Step");
        return;
      }

      if (!products.length >= 1) {
        toast.error(language=='ar'?"قم بإدخال منتج تحضير واحد على الأقل":"Enter At Least One related product");
        return;
      }

      if (ingsHasNoValues) {
        toast.error(language=='ar'?`هناك مكون  مطلوب باللغة العربية والإنجليزية`:"There Are One Ingrediant Required");
        return;
      }

      if (stepsHasNoValues) {
        toast.error(language=='ar'?`خطوة التحضير   مطلوبة باللغة العربية والإنجليزية`:'there are one step required');
        return;
      }

      if (productsHasNoValues) {
        toast.error(language=='ar'?` أكمل بيانات منتج التحضير  `:'Compelete Data');
        return;
      }
    }

    // const images = imagesUrl.join("**green**");

    const ingrediants_en = hasOptions
      ? arIngs.map((ing) => ing.en_value).join("**green**")
      : "";
    const ingrediants_ar = hasOptions
      ? arIngs.map((ing) => ing.ar_value).join("**green**")
      : "";

    // const relatedProducts = selectedOptions?.join("**green**");
    const policiesData = policies.map((pol) => pol.value).join("**green**");
    const steps_ar = hasOptions
      ? steps.map((step) => step.ar_value).join("**green**")
      : "";
    const steps_en = hasOptions
      ? steps.map((step) => step.en_value).join("**green**")
      : "";
console.log(hasOptions)
    const productsData = hasOptions
      ? products

          .map((prod) => {
            return `${prod.id}*green*${prod.price}*green*${prod.count}`;
          })
          .join("**green**")
      : "";

    console.log(productsData);
    // return

    const dataSet = {
      title_ar: newProd.title_ar,
      title_en: newProd.title_en,
      description_ar: newProd.description_ar,
      description_en: newProd.description_en,
      price: newProd.price,
      discount: newProd.discount,
      images: result,
      subcategory_id: subCatId || newProd.subCatId,
      has_options: 1,
      id: id,
      ingrediants_ar,
      ingrediants_en,
      steps_ar,
      steps_en,
      // subcategory_id:,
      products: productsData,
      // related_products: relatedProducts,
      policies: policiesData,
      price_for: "piece",
    };

    console.log(JSON.stringify(dataSet));
    setEditLoading(true);

    const token = localStorage.getItem("SediHishamAdmindata");

    await axios
      .post(`${BASE_URL}products/update_product?${token}`, dataSet,{
        headers:{
          lang:language
        }
      })
      .then((res) => {
        console.log(res);
        if (res?.data && res?.data?.status == "success") {
          toast.success(res.data.message);
          // getCategories();
          console.log(res.data.result);
        } else if (res.data.status == "error"||res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language=='ar'?"حدث خطأ ما":"Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setEditLoading(false);

        // setNewCat({
        //   title_ar: "",
        //   title_en: "",
        //   color: "",
        // });
        // setSelectedFile(null);
      });
  };

  const handleUploadImages = async () => {
    if (selectedImages.length >= 1) {
      setAddImagesLoading(true);
      const arr = [];
      for (let i = 0; i < selectedImages.length; i++) {
        const formData = new FormData();
        formData.append("image", selectedImages[i]);
        await axios
          .post(`${BASE_URL}upload_image`, formData)
          .then((res) => {
            console.log(res);
            if (res.data && (res?.status == 201||res.status=='success')) {
              arr.push(res?.data?.result?.image);
              // setImagesLinks([...imagesLinks, res?.data?.result?.image])
              // console.log([...imagesLinks, res?.data?.result?.image])
              toast.success(language=='ar'?"تم رفع الصورة بنجاح":"Success To Upload Image");
            } else {
              toast.error(language=='ar'?"حدث خطأ ما":"Something Went Error");
            }
          })
          .catch((e) => console.log(e))
          .finally(() => {
            setAddImagesLoading(false);
          });
      }
      setImagesUrl([...arr]);
    } else {
      toast.error(language=='ar'?" أنت لم تقم  بتعديل الصور  ":'You Did not edit the image');
    }
  };

  return (
    <div className="add_product_container">
      <div className="d-flex mb-5 mt-3 align-items-center ">
        <h3 className="">{language=='ar'?' تعديل منتج':'Edit Product'}</h3>

        {subCatName ? <h3> - {language=='ar'?' للفئة الفرعية  ':' For Subcategory '} ({subCatName})</h3> : ""}
      </div>

      {dataLoading ? (
        <Loader />
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleAddNewProduct();
          }}
        >
          <div className="fields row gy-4">
            <div className="field_input  col-md-6">
              <div className="d-flex align-items-center gap-4">
                <label htmlFor="">
                  {language=='ar'?"اسم المنتج باللغة":"Product Name In "} {lang.prod_name ? language=='ar'?" الإنجليزية":"English " : language=='ar'?" العربية": ' Arabic'}
                </label>

                <div class="toggle-switch">
                  <input
                    class="toggle-input"
                    id="prod_name"
                    type="checkbox"
                    onChange={(e) => {
                      setLang({
                        ...lang,
                        prod_name: e.target.checked,
                      });
                    }}
                  />
                  <label class="toggle-label" for="prod_name"></label>
                </div>
              </div>
              <input
                type="text"
                value={lang.prod_name ? newProd?.title_en : newProd?.title_ar}
                onChange={(e) => {
                  lang.prod_name
                    ? setNewProd({
                        ...newProd,
                        title_en: e.target.value,
                      })
                    : setNewProd({
                        ...newProd,
                        title_ar: e.target.value,
                      });
                }}
              />
            </div>
            <div className="field_input col-md-6">
              <div className="d-flex align-items-center gap-4">
                <label htmlFor="">
                  {language=='ar'?'وصف المنتج باللغة':'Product Description In '} {lang.prod_desc ? language=='ar'?" الإنجليزية ":" English " : language=='ar'?" العربية ":" Arabic "}
                </label>

                <div class="toggle-switch">
                  <input
                    class="toggle-input"
                    id="prod_desc"
                    type="checkbox"
                    onChange={(e) => {
                      setLang({
                        ...lang,
                        prod_desc: e.target.checked,
                      });
                    }}
                  />
                  <label class="toggle-label" htmlFor="prod_desc"></label>
                </div>
              </div>

              <input
                type="text"
                value={
                  lang.prod_desc
                    ? newProd.description_en
                    : newProd.description_ar
                }
                onChange={(e) => {
                  lang.prod_desc
                    ? setNewProd({
                        ...newProd,
                        description_en: e.target.value,
                      })
                    : setNewProd({
                        ...newProd,
                        description_ar: e.target.value,
                      });
                }}
              />
            </div>

            <div className="field_input col-md-6">
              <label htmlFor="">{language=='ar'?'سعر المنتج ':"Product Price"}</label>
              <input
                value={newProd.price}
                type="number"
                onChange={(e) => {
                  setNewProd({
                    ...newProd,
                    price: e.target.value,
                  });
                }}
              />
            </div>
            <div className="field_input col-md-6">
              <label htmlFor="">{language=='ar'?'قيمة الخصم':'Discount Value'}</label>
              <input
                value={newProd.discount}
                type="number"
                onChange={(e) => {
                  setNewProd({
                    ...newProd,
                    discount: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="images_container my-3">
            <h4>{language=='ar'?'اختر صور المنتج':'enter Product image'}</h4>

            <div className="images">
              <label className="image" htmlFor="mul_images">
                <img
                  className="avatar"
                  src={
                    "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                  }
                  alt=""
                />
              </label>
              <input
                type="file"
                multiple
                name=""
                id="mul_images"
                className="d-none"
                onChange={handleImageChange}
              />

              {imagesLinks?.map((image, index) => {
                return (
                  <div className="image">
                    <img src={image.url} alt="" />
                    <div
                      className="delete_btn"
                      onClick={() => handleDeleteImageLink(index)}
                    >
                      {exitModal}
                    </div>
                  </div>
                );
              })}

              {selectedImages.length >= 1
                ? selectedImages.map((imageFile, index) => {
                    return (
                      <div className="image">
                        <img src={URL.createObjectURL(imageFile)} alt="" />
                        <div
                          className="delete_btn"
                          onClick={() => handleDeleteImage(index)}
                        >
                          {exitModal}
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                if (!addImagesLoading) {
                  handleUploadImages();
                }
              }}
              className="popup_agree_btn"
            >
              {addImagesLoading ? <Loader /> : language=='ar'?"رفع الصور":'Upload Image'}
            </button>
          </div>

          <div className="d-flex align_items-center justify-content-between">
            <h4>{language=='ar'?'سياسات المنتج':'Product Policy'}</h4>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                setPolicies([
                  ...policies,
                  { value: "", id: policies.length + 1 },
                ]);
              }}
            >
              {plus}
            </div>
          </div>

          {policies && policies.length >= 1
            ? policies.map((policy, index) => {
                return (
                  <div className="ing_container mb-3">
                    <div
                      className={language=='ar'?"deleteIng":"deleteIng rev"}
                      onClick={() => {
                        setPolicies((prev) =>
                          prev.filter((item) => item.id != policy.id)
                        );
                      }}
                    >
                      {exitModal}
                    </div>

                    <div className="fields row w-100 ">
                      <div className="field_input col-sm-12">
                        <div className="d-flex align-items-center gap-4">
                          <label htmlFor="">
                            {/* باللغة {ing.englishLang ? "الإنجليزية" : " العربية "} */}
                            {language=='ar'?'سياسة رقم':'Policy Number '} ({index + 1})
                          </label>

                          {/* <div class='toggle-switch'>
                            <input
                              class='toggle-input'
                              id={`policy_${index}`}
                              type='checkbox'
                              onChange={(e) => {
                                setPolicies((prev) =>
                                  prev.map((item) =>
                                    item.id == ing.id
                                      ? {...item, englishLang: e.target.checked}
                                      : item
                                  )
                                );
                              }}
                            />
                            <label
                              class='toggle-label'
                              for={`policy_${index}`}
                            ></label>
                          </div> */}
                        </div>

                        <input
                          type="text"
                          value={policy.value}
                          onChange={(e) => {
                            // ing.englishLang
                            // ?
                            setPolicies((prev) =>
                              prev.map((item) =>
                                item.id == policy.id
                                  ? { ...item, value: e.target.value }
                                  : item
                              )
                            );
                            // : setArIngs((prev) =>
                            //     prev.map((item) =>
                            //       item.id == ing.id
                            //         ? {
                            //             ...item,
                            //             ar_value: e.target.value,
                            //           }
                            //         : item
                            //     )
                            //   );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : null}

          <hr />

          <div className="ingrediants mt-4">
            <div className="d-flex align_items-center justify-content-between">
              <h4>{language=='ar'?'المكونات':'ingrediants'}</h4>
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setArIngs([
                    ...arIngs,
                    {
                      ar_value: "",
                      en_value: "",
                      englishLang: false,
                      id: arIngs.length + 1,
                    },
                  ]);
                  console.log(arIngs);
                }}
              >
                {plus}
              </div>
            </div>

            {arIngs && arIngs.length >= 1
              ? arIngs.map((ing, index) => {
                  return (
                    <div className="ing_container mb-3">
                      <div
                        className={language=='ar'?"deleteIng":"deleteIng rev"}
                        onClick={() => {
                          setArIngs((prev) =>
                            prev.filter((item) => item.id != ing.id)
                          );
                        }}
                      >
                        {exitModal}
                      </div>

                      <div className="fields row w-100 ">
                        <div className="field_input col-sm-12">
                          <div className="d-flex align-items-center gap-4">
                            <label htmlFor="">
                              {language=='ar'?' باللغة ':'By Language '}{" "}
                              {ing.englishLang ? language=='ar'?"الإنجليزية":" english " : language=='ar'?" العربية ":" Arabic "}
                            </label>

                            <div class="toggle-switch">
                              <input
                                class="toggle-input"
                                id={`prod_desc_${index}`}
                                type="checkbox"
                                onChange={(e) => {
                                  setArIngs((prev) =>
                                    prev.map((item) =>
                                      item.id == ing.id
                                        ? {
                                            ...item,
                                            englishLang: e.target.checked,
                                          }
                                        : item
                                    )
                                  );
                                }}
                              />
                              <label
                                class="toggle-label"
                                for={`prod_desc_${index}`}
                              ></label>
                            </div>
                          </div>

                          <input
                            type="text"
                            value={
                              ing.englishLang ? ing.en_value : ing.ar_value
                            }
                            onChange={(e) => {
                              ing.englishLang
                                ? setArIngs((prev) =>
                                    prev.map((item) =>
                                      item.id == ing.id
                                        ? { ...item, en_value: e.target.value }
                                        : item
                                    )
                                  )
                                : setArIngs((prev) =>
                                    prev.map((item) =>
                                      item.id == ing.id
                                        ? {
                                            ...item,
                                            ar_value: e.target.value,
                                          }
                                        : item
                                    )
                                  );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}

            <div className="d-flex align_items-center justify-content-between">
              <h4>{language=='ar'?"خطوات التحضير":"Steps"}</h4>
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSteps([
                    ...steps,
                    {
                      ar_value: "",
                      en_value: "",
                      englishLang: false,
                      id: steps.length + 1,
                    },
                  ]);
                }}
              >
                {plus}
              </div>
            </div>

            {steps && steps.length >= 1
              ? steps.map((step, index) => {
                  return (
                    <div className="ing_container mb-3">
                      <div
                        className={language=='ar'?"deleteIng":"deleteIng rev"}
                        onClick={() => {
                          setSteps((prev) =>
                            prev.filter((item) => item.id != step.id)
                          );
                        }}
                      >
                        {exitModal}
                      </div>
                      <div className="fields row w-100 ">
                        <div className="field_input col-sm-12">
                          <div className="d-flex align-items-center gap-4">
                            <label htmlFor="">
                              {language=='ar'?" الخطوة ":" Step "} ({index + 1}) {language=='ar'?"باللغة":" By Language "}{" "}
                              {step.englishLang ? "الإنجليزية" : " العربية "}
                            </label>

                            <div class="toggle-switch">
                              <input
                                class="toggle-input"
                                id={`step_${index}`}
                                type="checkbox"
                                onChange={(e) => {
                                  setSteps((prev) =>
                                    prev.map((item) =>
                                      item.id == step.id
                                        ? {
                                            ...item,
                                            englishLang: e.target.checked,
                                          }
                                        : item
                                    )
                                  );
                                }}
                              />
                              <label
                                class="toggle-label"
                                for={`step_${index}`}
                              ></label>
                            </div>
                          </div>

                          <input
                            type="text"
                            value={
                              step.englishLang ? step.en_value : step.ar_value
                            }
                            onChange={(e) => {
                              step.englishLang
                                ? setSteps((prev) =>
                                    prev.map((item) =>
                                      item.id == step.id
                                        ? { ...item, en_value: e.target.value }
                                        : item
                                    )
                                  )
                                : setSteps((prev) =>
                                    prev.map((item) =>
                                      item.id == step.id
                                        ? { ...item, ar_value: e.target.value }
                                        : item
                                    )
                                  );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}

            <div className="d-flex align_items-center justify-content-between">
              <h4> {language=='ar'?"الإضافات":"options"}</h4>
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setProducts([
                    ...products,
                    {
                      product_id: "",
                      price: "",
                      count: "",
                      id: products.length + 1,
                    },
                  ]);
                }}
              >
                {plus}
              </div>
            </div>

            {products && products.length >= 1
              ? products.map((prod, index) => {
                  return (
                    <div className="prod_container">
                      <div
                        className="deleteIng"
                        onClick={() => {
                          setProducts((prev) =>
                            prev.filter((item) => item.id != prod.id)
                          );
                        }}
                      >
                        {exitModal}
                      </div>

                      <div className="field_input">
                        {/* <SelectWithSearch
                          options={allProducts}
                          getClientData={setProdcutData}
                          defaultValue={prod}
                          onChange={(value) => {
                            console.log(prod);
                            console.log(value);

                            // id:item?.product.id,
                            // product_id: item?.product?.id,
                            // price: item?.price,
                            // count: item?.weight,
                            // title_ar: item?.product.title_ar,
                            // title_en: item?.product.title_en,


                              const newData = selectedOptions.map((item, ind)=>{
                                if(index == ind ){
                                  item = 11
                                }
                                return item
                              })

                              setSelectedOptions(newData)
                              console.log(newData)



                          //   setProducts((prev) =>
                          //   prev.map((item) =>
                          //     item.id == prod.id
                          //       ? {...item, id:prod.id  , product_id:prod.id}
                          //       : item
                          //   )
                          // )


                            console.log(products)
                          }}
                        /> */}

                        <div className="field_input">
                          <label htmlFor="">{language=='ar'?'المنتج':"Product"}</label>

                          <select
                            name=""
                            id=""
                            value={prod.product_id}
                            onChange={(e) => {
                              console.log(prod);
                              console.log(e.target.value);

                              const newData = products?.map((item) => {
                                if (item.id == prod.id) {
                                  item.product_id = e.target.value;
                                  item.id = e.target.value;
                                }
                                return item;
                              });
                              console.log(newData);

                              setProducts(newData);
                            }}
                          >
                            {allProducts &&
                            allProducts.length >= 1 &&
                            Array.isArray(allProducts)
                              ? allProducts.map((prod, idnex) => {
                                  return (
                                    <option value={prod.id}>
                                      {prod.title_ar}
                                    </option>
                                  );
                                })
                              : null}
                          </select>
                        </div>
                      </div>

                      <div className="field_input">
                        <label htmlFor="">السعر</label>
                        <input
                          value={prod.price}
                          type="number"
                          onChange={(e) => {
                            setProducts((prev) =>
                              prev.map((item) =>
                                item.id == prod.id
                                  ? { ...item, price: e.target.value }
                                  : item
                              )
                            );
                          }}
                        />
                      </div>

                      <div className="field_input">
                        <label htmlFor="">{language=='ar'?'الوزن/الكمية':'Weight/quantity'}</label>

                        <input
                          value={prod.count}
                          type="number"
                          onChange={(e) => {
                            setProducts((prev) =>
                              prev.map((item) =>
                                item.id == prod.id
                                  ? { ...item, count: e.target.value }
                                  : item
                              )
                            );
                          }}
                        />
                      </div>
                    </div>
                  );
                })
              : null}
          </div>

          <button className="popup_agree_btn">
            {editLoading ? <Loader /> : language=='ar'?"تعديل":"Edit"}
          </button>
        </form>
      )}
    </div>
  );
};

export default EditProductIdeasPage;
