import React, { useEffect, useState } from "react";
import TableLayout from "../../components/table";
import { BASE_URL } from "../../components/Axios/base_uri";
import axios from "axios";
import toast from "react-hot-toast";
import imageAvatar from "../../assets/image_placeholder.png";
import {
  Menu,
  Preview,
  add,
  exitModal,
  eyeOff,
  eyeOn,
  search,
} from "../../assets/svgIcons";
import PopUp from "../../components/popup/index";
import "./style.css";
import { edit } from "../../assets/svgIcons/index";
import { Loader } from "rsuite";
import { useNavigate } from "react-router-dom";
import UseGeneral from "../../customHooks/useGeneral";

const ArchivedCategoriesPage = () => {
  const { language } = UseGeneral();
  const navigate = useNavigate();
  const [categories, setCategoreis] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [showAddCatModal, setShowAddCatModal] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [updateModal, setUpdateModal] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [searchValue,  setSearchValue] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);

  const [newCat, setNewCat] = useState({
    title_ar: "",
    title_en: "",
    color: "",
  });

  const [img, setImg] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const getCategories = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}categories/get_categories_for_admin?${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          setCategoreis(
            res?.data?.result?.filter((item) => item?.hidden == "1")
          );
          setOriginalData(
            res?.data?.result?.filter((item) => item?.hidden == "1")
          );
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getCategories();
  }, []);

  const categoriesHeader = [
    // {
    //   label: "#",
    //   dataIndex: "id",
    // },
    {
      label: language == 'ar' ? "الصورة" : "Image",
      dataIndex: "id",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="table_row_image">
            <img src={row.image || imageAvatar} alt="image" />
          </div>
        );
      },
    },
    {
      label: language == 'ar' ? "اسم الفئة" : "Category Name",
   dataIndex: language=='ar'?"title_ar":"title_en",
    },
    // {
    //   label: language == 'ar' ? "اللون" : "Color",
    //dataIndex: language=='ar'?"title_ar":"title_en",
    //   type: "children",
    //   children: ({ row }) => {
    //     if (!row.color) {
    //       return <div className=" text-danger"> لم تقم باختيار لون </div>;
    //     } else
    //       return (
    //         <div
    //           style={{
    //             backgroundColor: row.color,
    //             width: "23px",
    //             height: "23px",
    //             borderRadius: "50%",
    //             border: "1px solid #222",
    //           }}
    //         ></div>
    //       );
    //   },
    // },
    // {
    //   label: "وقت الانشاء",
    //   dataIndex: "time",
    //   type: "children",
    //   children: ({ row }) => {
    //     return <div>{getOlnlyDate(row.created_at)}</div>;
    //   },
    // },

    {
      label: language == 'ar' ? "الحالة" : "Status",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
          >
            {row.hidden == "0"
              ? language == 'ar'
                ? "ظاهر"
                : "Showen"
              : language == 'ar'
              ? "مخفي"
              : "hidden"}
          </div>
        );
      },
    },
    {
      label: language == 'ar' ? " أوامر" : "Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${
                row.hidden == "0" ? "text-danger" : "text-success"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              <button className="btn btn-success">
                {row.hidden == "0"
                  ? language == "ar"
                    ? "إخفاء"
                    : "Hide"
                  : language == "ar"
                  ? "إظهار"
                  : "Show"}
              </button>
            </div>
            {/* <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setUpdateModal(true);
                setImgUrl(row.image);
                setImg(row.image);
                console.log(row.image);
              }}
            >
              <button className="btn btn-success">{language =="ar" ?"تعديل":"Edit"}</button>
            </div> */}
            {/* <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/categories/${row.id}`, {
                  state: { categoryData: row },
                });
              }}
            >
              <button className="btn btn-success">{language =="ar" ?"عرض":"View"}</button>
            </div> */}
          </div>
        );
      },
    },
    // {
    //   label: " تعديل",
    //   type: "children",
    //   children: ({row}) => {
    //     return (
    //       <div
    //         className='text-primary'
    //         style={{cursor: "pointer"}}
    //         onClick={() => {
    //           setRowData(row);
    //           setUpdateModal(true);
    //         }}
    //       >
    //         <button className="btn btn-success">{language =="ar" ?"تعديل":"Edit"}</button>
    //       </div>
    //     );
    //   },
    // },
  ];

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImg(URL.createObjectURL(e.target.files[0]));
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleAddNewCategory = async (imageLink) => {
    const token = localStorage.getItem("SediHishamAdmindata");

    const dataset = {
      ...newCat,
      color: "sfda",
      image: imageLink,
    };

    await axios
      .post(`${BASE_URL}categories/add_new?${token}`, dataset)
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success("تم إضافة فئة جديدة بنجاح");
          getCategories();
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error("هناك مشكلة ! حاول مجدداً");
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setNewCat({
          title_ar: "",
          title_en: "",
          color: "",
        });

        setImg("");
        setSelectedFile(null);
      });
  };

  const handleAddFile = async () => {
    console.log("add file");

    if (!newCat.title_ar) {
      toast.error(" قم بإضافة اسم الفئة باللغة العربية أولاً");
      return;
    }

    if (!newCat.title_en) {
      toast.error(" قم بإضافة اسم الفئة باللغة الإنجليزية أولاً");
      return;
    }

    if (!img) {
      toast.error("لم تقم بإضافة صورة");
      return;
    }

    setAddLoading(true);
    // setImgLoading(true);
    const formData = new FormData();
    formData.append("image", selectedFile);
    await axios
      .post(`${BASE_URL}upload_image`, formData)
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.status == 201) {
          // setImgUrl(res?.data?.message);
          handleAddNewCategory(res?.data?.result?.image);
        } else if (res.data == "") {
          toast.error("هناك مشكلة في رفع الصورة");
        } else {
          toast.error("هناك مشكلة في رفع الصورة");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        setShowAddCatModal(false);
        setAddLoading(false);
      });
  };

  const updateCategoryData = async (updatedImage) => {
    setUpdateLoading(true);

    const token = localStorage.getItem("SediHishamAdmindata");

    const dataset = {
      ...rowData,
      color: "sfda",
      image: updatedImage,
    };

    await axios
      .post(`${BASE_URL}categories/update_category?${token}`, dataset)
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success("تم تعديل الفئة بنجاح");
          getCategories();
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error("هناك مشكلة ! حاول مجدداً");
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setUpdateModal(false);
        setRowData({});
        setUpdateLoading(false);
        setImg("");
        setImgUrl("");
        setSelectedFile(null);
      });
  };

  const handleUpdateCategoryImage = async () => {
    if (!rowData.title_ar) {
      toast.error(" قم بإضافة اسم الفئة باللغة العربية أولاً");
      return;
    }

    if (!rowData.title_en) {
      toast.error(" قم بإضافة اسم الفئة باللغة الإنجليزية أولاً");
      return;
    }

    setUpdateLoading(true);

    if (selectedFile) {
      // setImgLoading(true);
      const formData = new FormData();
      formData.append("image", selectedFile);
      await axios
        .post(`${BASE_URL}upload_image`, formData)
        .then((res) => {
          console.log(res);
          if (res.data != "" && res.status == 201) {
            // setImgUrl(res?.data?.message);
            console.log(res?.data?.result?.image);
            updateCategoryData(res?.data?.result?.image);
          } else if (res.data == "") {
            toast.error("هناك مشكلة في رفع الصورة");
          } else {
            toast.error("هناك مشكلة في رفع الصورة");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          // setImgLoading(false);
        });
    } else {
      updateCategoryData(rowData.image);
    }

    setUpdateLoading(false);
  };

  const handleShow_hide = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    const dataset = {
      id: rowData.id,
    };

    setChangeStatusLoading(true);

    await axios
      .post(
        `${BASE_URL}categories/update_category_status?${token}`,
        dataset,
        {
          headers: {
            lang: language,
          },
        }
      )
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success(res.data.message);
          getCategories();
        } else if (res.data.status == "error" || res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == 'ar' ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      });
  };

  // filteraiton part

  useEffect(() => {
    if (originalData && originalData.length >= 1) {
      if (searchValue.length > 0) {
        console.log(searchValue);
        const newData = originalData.filter((cat) => {
          if (
            searchValue.length >= 1 &&
            !cat.title_ar?.toLowerCase()?.includes(searchValue) &&
            !cat.title_en?.toLowerCase()?.includes(searchValue)
          ) {
            return false;
          }
          return true;
        });
        setCategoreis(newData);
      } else {
        setCategoreis(originalData);
      }
    }
  }, [searchValue]);

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5>{language == 'ar' ? "الفئات" : "Categories"}</h5>
            {/* <div onClick={() => setShowAddCatModal(true)} className="btn btn-success">{add}</div> */}
          </div>

          <div className="searchInput field_input">
            <input
              type="text"
              placeholder={language == 'ar' ? "بحث..." : "Search..."}
              onChange={(e) => {
                setSearchValue(e.target.value?.toLowerCase());
              }}
            />
          </div>

          {dataLoading ? (
            <Loader size="md" />
          ) : (
            <TableLayout headers={categoriesHeader} data={categories} />
          )}
        </div>
      </div>

      <PopUp
        open={showAddCatModal}
        setOpen={setShowAddCatModal}
        onClose={() => {
          setImg('');
          setImgUrl('');
          setSelectedFile(null);
        }}
        title={"إضافة فئة "}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">اسم الفئة باللغة العربية</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewCat({
                      ...newCat,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">اسم الفئة باللغة الإنجليزية</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewCat({
                      ...newCat,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-around mt-3 flex-wrap">
              {/* <div className="field_input">
                <label htmlFor="">لون الفئة</label>

                <input
                  value={newCat.color}
                  type="color"
                  className="color_picker"
                  onChange={(e) => {
                    setNewCat({
                      ...newCat,
                      color: e.target.value,
                    });
                  }}
                />
              </div> */}
              <div className="field_input">
                <label htmlFor="">اختر صورة الفئة</label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image"}
                    className="w-100"
                  >
                    <img
                      width={100}
                      src={
                        img == ""
                          ? "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                          : img
                      }
                      alt=""
                    />
                  </label>
                  <input
                    id={"add-image"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onImageChange(e)}
                  />

                  {img && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setImg("");
                        setSelectedFile(null);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <button
              onClick={() => (addLoading ? null : handleAddFile())}
              className="popup_agree_btn"
            >
              {addLoading ? <Loader /> : "إضافة"}
            </button>
          </form>
        }
      />

      <PopUp
        open={updateModal}
        setOpen={setUpdateModal}
        onClose={() => {
          setImg('');
          setImgUrl('');
          setSelectedFile(null);
        }}
        title={`تعديل الفئة : ${rowData.title_ar}`}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">اسم الفئة باللغة العربية</label>
                <input
                  type="text"
                  value={rowData.title_ar}
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">اسم الفئة باللغة الإنجليزية</label>
                <input
                  value={rowData.title_en}
                  type="text"
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-around mt-3 flex-wrap">
              {/* <div className="field_input">
                <label htmlFor="">لون الفئة</label>

                <input
                  value={rowData.color}
                  type="color"
                  className="color_picker"
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      color: e.target.value,
                    });
                  }}
                />
              </div> */}
              <div className="field_input">
                <label htmlFor="">اختر صورة الفئة</label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image"}
                    className="w-100"
                  >
                    <img width={100} src={img} alt="" />
                  </label>
                  <input
                    id={"add-image"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onImageChange(e)}
                  />

                  {img && img != rowData.image && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setImg(rowData.image);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <button
              onClick={() =>
                updateLoading ? null : handleUpdateCategoryImage()
              }
              className="popup_agree_btn"
            >
              {updateLoading ? <Loader /> : "تعديل"}
            </button>
          </form>
        }
      />
      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={
          language == 'ar' ? "تغيير حالة الظهور" : "Change Appearance Status"
        }
        children={
          <div className="">
            <h5 className="">
              {language == 'ar'
                ? '؟هل تريد حقا إظهار هذه الفئه'
                : 'Do you really want to show this category?'}
            </h5>

            <div className="modalButtons">
              <button
                onClick={() => (changeStatusLoading ? null : handleShow_hide())}
                className="btn btn-danger"
              >
                {changeStatusLoading ? (
                  <Loader />
                ) : language == 'ar' ? (
                  "تأكيد"
                ) : (
                  "Confirm"
                )}
              </button>
              <button
                onClick={() =>
                  changeStatusLoading ? null : setChangeStatusModal(false)
                }
                className="btn btn-success"
              >
                {language == 'ar' ? "إلغاء" : "Cancel"}
              </button>
            </div>
          </div>
        }
      />
    </>
  );
};

export default ArchivedCategoriesPage;
