import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import TableLayout from "../../components/table";
import UseGeneral from "../../customHooks/useGeneral";
import PopUp from "../../components/popup";
import toast from "react-hot-toast";

export default function Users() {
    const language = UseGeneral();
    const [isLoading , setIsLoading] = useState(false);
    const [isShowAddModal , setIsShowAddModal] = useState(false);
    const [isShowDeleteModal , setIsShowDeleteModal] = useState(false);
    const [delItem , setDelItem] = useState(null);
    const [searchValue , setSearchValue] = useState("");
    const [userData , setUserData] = useState({
         email:"",
         password:"",
         role:"Admin",
    })
    const [allUsers , setAllUsers] = useState([]);
    const [originalData , setOriginalData] = useState([]);

    const headers = [
        {
            label:"#",
            dataIndex :"id",
        },
        {
            label :"Email",
            dataIndex:"email",
            type:"children",
            children : ({row}) => {
                return (
                    <a style={{fontSize:"18px" , textDecoration:"none"}} href={`mailto:${row.email}`}>{row.email}</a>
                )
            }
        },
        {
            label:"Phone Number",
            dataIndex:"phone_number"
        },
        {
              label:"Role",
              dataIndex:"role",
              type:"children",
              children : ({row}) => {
                return (
                    <select value={row.role}>
                        <option>Admin</option>
                        <option>HR</option>
                        <option>Sales</option>
                        <option>Messaging</option>
                        <option>DataEntry</option>
                    </select>
                )
              }
        },
        {
            label:"Registred",
            dataIndex:"updatedAt",
            type:"children",
            children: ({row}) => {
               return (
                <p>{row?.updatedAt?.split("T")?.[0]}</p>
               )
            }
        },
        {
            label:"أوامر",
            type:"children",
            children : ({row}) => {
              return (
                <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                <button onClick={() => {
                  setDelItem(row.id)
                  setIsShowDeleteModal(true);
                }} style={{backgroundColor :"red"  , color:"white" , borderRadius:"5px"}}>
                <svg style={{width:"20px" , height:"15px"}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="size-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
  </svg>
                </button>  
   
            </div>
              )
            }
          }
    ]

    function handleGetAllUsers() {
        axios.get('https://api.camp-coding.site/users/allusers')
        .then(res => {
           setAllUsers(res.data);
           setOriginalData(res.data);
        })
        .catch(e => console.log(e))
    }

    function handleSubmit(e) {
        e.preventDefault();
        const data_send = {
            ...userData
        }
        setIsLoading(true);
        axios.post(`https://api.camp-coding.site/auth/user/signup`,data_send)
        .then(res => {
           if(res.status == 201) {
              setIsShowAddModal(false);
              toast.success(language == "ar" ? "تم اضافه المستخدم بنجاح":"User Added Successfully")
              handleGetAllUsers();
              setUserData({
                email:"",
                password:"",
                role:""
              })
           }else {
               toast.error(language == "ar" ? "حدث خطأ ما!" :"Something went wrong!")
           }
        })
        .finally(() => {
            setIsLoading(false);
        })
        .catch(e => console.log(e))
        console.log(userData);
    }

    function handleDelete() {
        setIsLoading(true);
        axios.get(`https://api.camp-coding.site/users/delete/${delItem}`)
        .then(res => {
            if(res.status == 200) {
                toast.success(language == "ar" ? "تم مسح المستخدم بنجاح":"User Deleted Successfullt")
                handleGetAllUsers();
                setIsShowDeleteModal(false);
            }else {
                toast.error(language == "ar" ? "حدث خطأ ما":"Something went wrong!")
            }
        })
        .finally(() => {
            setIsLoading(false);
        })
        .catch(e => console.log(e))
    }



    useEffect(() => { 
        handleGetAllUsers();
    } , [])

    useEffect(() => {
       if(searchValue.length > 0) {
          setAllUsers(originalData.filter(data => data.email.toLowerCase().includes(searchValue)));
          return;
       }
       setAllUsers(originalData)
    } , [searchValue])

  return (
    <div className="rowDiv flex-2-1 page_padding">
       <div>
       <div className="title_add">
             <h5>{language == "ar" ? "المستخدمين" : "Users"}</h5>
             <div
              onClick={() => setIsShowAddModal(true)}
              className="btn btn-success"
            >
              {language == "ar" ? "إضافة مستخدم" : "Add User"}
            </div>
            </div>

            <div className="searchInput field_input">
             <input
              type="text"
              placeholder={language == "ar" ? "بحث..." : "Search"}
              onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            />
          </div>
       </div>
       <PopUp open={isShowAddModal} setOpen={setIsShowAddModal} title={language == "ar" ? "اضافه مستخدم":"Add User"}>
          <form onSubmit={handleSubmit}>
             <div className="inputs">
                <div className="field_input">
                    <label>{language == "ar" ? "ايميل":"Email"}</label>
                    <input type="email" value={userData.email} placeholder={language == "ar" ? "بريدك الالكتروني":"Your Email"} onChange={(e) => setUserData({...userData , email : e.target.value})}/>
                </div>

                <div className="field_input">
                    <label>{language == "ar" ? "كلمه السر":"Password"}</label>
                    <input type="password" value={userData.password} placeholder={language == "ar" ? "كلمه السر":"Your password"} onChange={(e) => setUserData({...userData , password : e.target.value})}/>
                </div>

                <div className="field_input">
                    <label>{language == "ar" ? "":"Role"}</label>
                    <select value={userData.role} onChange={(e) => setUserData({...userData , role:e.target.value})}>
                        <option value="Admin">Admin</option>
                        <option value="DataEntry">Data Entry</option>
                        <option value="Messaging">Messaging</option>
                        <option value="HR">HR</option>
                        <option value="Sales">Sales</option>
                    </select>
                </div>

                <div style={{display:"flex" , gap:"10px"}}>
                        <button className='popup_agree_btn'>{isLoading ? "Loading..." :"Add"}</button>
                        <button onClick={() => setIsShowAddModal(false)} style={{padding:"5px 10px" , marginTop :"20px" ,borderRadius:"3px"}}>Cancel</button>
                </div>

             </div>
          </form>
       </PopUp>

       <PopUp open={isShowDeleteModal} setOpen={setIsShowDeleteModal}>
       <div style={{display:"flex" , flexDirection:"column" , justifyContent:"center"}}>
                <img style={{width:"120px" , height:"120px" , margin:"5px auto"}} src="/warning.png"/>
                 <h4 style={{textAlign:"center"}}>{language == "ar" ? "هل تريد مسح هذا العنصر؟":"Are you sure you want to delete this item?"}</h4>
                 <div style={{display:"flex",margin:"0px auto" , gap:"10px" , justifyContent:"between"}}>
                        <button onClick={() => setIsShowDeleteModal(false)} style={{padding:"5px 10px", color:"white" , marginTop :"20px" ,borderRadius:"3px",backgroundColor:"#0388CC"}}>Cancel</button>
                        <button style={{padding:"5px 10px", color:"white" , marginTop :"20px" ,borderRadius:"3px",backgroundColor:"red"}} onClick={handleDelete}>Delete</button>
                        </div>
                </div>
       </PopUp>
        <TableLayout headers={headers} data={allUsers}/>
    </div>
  )
}
