import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Loader } from "rsuite";
import { BASE_URL } from './../../components/Axios/base_uri';
import TableLayout from "../../components/table";
import Modal from "./modal";
import { formatDate } from "./utils";
import "./style.css";

const Messages = () => {
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const getAllMessages = async () => {
    setDataLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}messaging/allmessages`);
      if (response.data) {
        setData(response.data);
        setOriginalData(response.data);
      }
    } catch (err) {
      toast.error("Error fetching messages");
      console.error("Error fetching messages: ", err);
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    getAllMessages();
  }, []);

  useEffect(() => {
    if (originalData.length >= 1) {
      if (searchValue.length > 0) {
        const filteredData = originalData.filter((message) => {
          if (
            searchValue.length >= 1 &&
            !message.name.toLowerCase().includes(searchValue.toLowerCase()) &&
            !message.email.toLowerCase().includes(searchValue.toLowerCase()) &&
            !message.phone_number
              .toLowerCase()
              .includes(searchValue.toLowerCase()) &&
            !message.content.toLowerCase().includes(searchValue.toLowerCase())
          ) {
            return false;
          }
          return true;
        });
        setData(filteredData);
      } else {
        setData(originalData);
      }
    }
  }, [searchValue]);

  const handleShowDetails = (message) => {
    setSelectedMessage(message);
  };

  const handleCloseModal = () => {
    setSelectedMessage(null);
  };

  const messagesHeaders = [
    { label: "ID", dataIndex: "id" },
    { label: "Name", dataIndex: "name" },
    {
      label: "Actions",
      type: "children",
      children: ({ row }) => (
        <button className="view" onClick={() => handleShowDetails(row)}>
          Show Details
        </button>
      ),
    },
    {
      label: "Phone Number",
      dataIndex: "phone_number",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="spaceBetween">
            <a href={`tel:${row.phone_number}`} className="activeteIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-phone"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
              </svg>
            </a>
            <span>{row.phone_number}</span>
          </div>
        );
      },
    },
    {
      label: "Email",
      dataIndex: "email",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="spaceBetween">
            <a href={`mailto:${row.email}`} className="activeteIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-mail"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
                <path d="M3 7l9 6l9 -6" />
              </svg>
            </a>
            <span>{row.email}</span>
          </div>
        );
      },
    },
    {
      label: "Subject",
      dataIndex: "sujet",
      type: "children",
      children: ({ row }) =>
        row?.sujet?.length > 70
          ? `${row.sujet.substring(0, 70)}...`
          : row.sujet,
    },
    {
      label: "Content",
      dataIndex: "content",
      type: "children",
      children: ({ row }) =>
        row?.content?.length > 70
          ? `${row.content.substring(0, 70)}...`
          : row.content,
    },
    {
      label: "Created At",
      dataIndex: "createdAt",
      type: "children",
      children: ({ row }) => formatDate(row.createdAt),
    },
    {
      label: "Updated At",
      dataIndex: "updatedAt",
      type: "children",
      children: ({ row }) => formatDate(row.updatedAt),
    },
    {
      label: "Deleted At",
      dataIndex: "deletedAt",
      type: "children",
      children: ({ row }) => formatDate(row.deletedAt),
    },
  ];

  return (
    <div className="columnFlexDiv">
      <div className="searchInput field_input">
        <input
          type="text"
          placeholder="Search..."
          onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
        />
      </div>
      {dataLoading ? (
        <Loader size="md" />
      ) : (
        <TableLayout headers={messagesHeaders} data={data} />
      )}
      <Modal
        visible={selectedMessage}
        onClose={handleCloseModal}
        title="Message Details"
      >
        <div>
          <p>ID: {selectedMessage?.id}</p>
          <p>Name: {selectedMessage?.name}</p>
          <p>Phone Number: {selectedMessage?.phone_number}</p>
          <p>Email: {selectedMessage?.email}</p>
          <p>Subject: {selectedMessage?.sujet}</p>
          <p>Content: {selectedMessage?.content}</p>
          <p>Created At: {formatDate(selectedMessage?.createdAt)}</p>
          <p>Updated At: {formatDate(selectedMessage?.updatedAt)}</p>
          <p>Deleted At: {formatDate(selectedMessage?.deletedAt)}</p>
        </div>
      </Modal>
    </div>
  );
};

export default Messages;
