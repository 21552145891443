import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../components/Axios/base_uri";
import toast from "react-hot-toast";
import TableLayout from "../../components/table";
import {
  Menu,
  add,
  edit,
  exitModal,
  eyeOff,
  eyeOn,
} from "../../assets/svgIcons";
import { Loader } from "rsuite";
import PopUp from "../../components/popup";
import { Axios } from "../../components/Axios";
import UseGeneral from "../../customHooks/useGeneral";

const SubCategoryArchived = () => {
  const navigate = useNavigate();
  const { id, category_id } = useParams();

  const location = useLocation();
  const {language}=UseGeneral()
  const categoryData = location?.state?.categoryData;
  const [currentNumber, setCurrentNumber] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [subCats, setSubCAts] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [searchValue,  setSearchValue] = useState("");
  const [rowData, setRowData] = useState({});
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [changeStatusloading, setChangeStatusLoading] = useState(false);
  const [showAddSubCatModal, setShowAddSubCatModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  const [productData, setProductData] = useState({});
  const [editLoading, setEditLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [product_id, set_product_id] = useState({});
  const [loader, setLoader] = useState(false);
  const [newSubCat, setNewSubCat] = useState({
    title_ar: "",
    title_en: "",
    category_id: id,
  });

  const [img, setImg] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const getSubCatefories = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}subcategories/get_hidden_for_admin?${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          setSubCAts(res?.data?.result?.filter((item) => item?.hidden == "1"));
          setOriginalData(
            res?.data?.result?.filter((item) => item?.hidden == "1")
          );
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getSubCatefories();
  }, []);

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImg(URL.createObjectURL(e.target.files[0]));
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleAddFile = async () => {
    console.log("add file");

    if (!newSubCat.title_ar) {
      toast.error(" قم بإضافة اسم الفئة باللغة العربية أولاً");
      return;
    }

    if (!newSubCat.title_en) {
      toast.error(" قم بإضافة اسم الفئة باللغة الإنجليزية أولاً");
      return;
    }

    // if (!img) {
    //   toast.error("لم تقم بإضافة صورة");
    //   return;
    // }

    setAddLoading(true);
    // setImgLoading(true);
    const formData = new FormData();
    formData.append("image", selectedFile);
    await axios
      .post(`${BASE_URL}upload_image`, formData)
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.status == 201) {
          // setImgUrl(res?.data?.message);
          handleAddNewSubCat(res?.data?.result?.image);
        } else if (res.data == "") {
          toast.error("هناك مشكلة في رفع الصورة");
        } else {
          toast.error("هناك مشكلة في رفع الصورة");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        setShowAddSubCatModal(false);
        setAddLoading(false);
      });
  };
  const setArrangeNumber = async (video_id, video_number) => {
    // console.log(video_number)
    // console.log(video_id)
    // return
    setLoader(true);
    if (video_number == currentNumber) {
      return setLoader(false);
    }
    await Axios({
      method: "POST",
      url: "subcategories/changer_sub_cat_order/" + video_id,
      data: { number: video_number },
    })
      .then(async (response) => {
        console.log(response);
        toast.success(response?.message);
        getSubCatefories();
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoader(false);
      });
  };

  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const subCategoryHeader = [
    
    {
      label: language=='ar'?"الصورة":"Image",
      dataIndex: "id",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="table_row_image">
            <img src={row.image} alt="image" />
          </div>
        );
      },
    },

    {
      label: language=='ar'?"اسم  الفئة الفرعية":"Subcategory Name",
   dataIndex: language=='ar'?"title_ar":"title_en",
    },
    // {
    //   label: "وقت الانشاء",
    //   dataIndex: "time",
    //   type: "children",
    //   children: ({ row }) => {
    //     return <div>{getOlnlyDate(row.created_at)}</div>;
    //   },
    // },

    {
      label: language=='ar'?"الحالة":"Status",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
          >
            {language=='ar'?'مخفى':'Hidden'}
          </div>
        );
      },
    },
    {
      label: language=='ar'?" أوامر":"Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${
                row.hidden == "0" ? "text-success" : "text-danger"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              <button className="btn btn-success">
                {row.hidden == "0"
                  ? language == "ar"
                    ? "إخفاء"
                    : "Hide"
                  : language == "ar"
                  ? "إظهار"
                  : "Show"}
              </button>
            </div>
            {/* <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setUpdateModal(true);
                // setImgUrl(row.image)
                setImgUrl(row.image);
                setImg(row.image);
                console.log(row);
              }}
            >
              <button className="btn btn-success">{language =="ar" ?"تعديل":"Edit"}</button>
            </div> */}
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/subcategories/${row.id}/${row.category_id}`, {
                  state: { subCategoryData: row },
                });
              }}
            >
             <button className="btn btn-success">{language =="ar" ?"عرض":"View"}</button>
            </div>
          </div>
        );
      },
    },
  ];

  const handleShow_hide = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    const dataset = {
      id: rowData.id,
    };

    setChangeStatusLoading(true);
    await axios
      .post(`${BASE_URL}subcategories/change_status?${token}`, dataset)
      .then((res) => {
        console.log(res);
        if (res?.data && res?.data?.status == "success") {
          toast.success(
            res.data.message
          );
        } else if (res.data.status == "error"||res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language=='ar'?"حدث خطأ ما":"Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        getSubCatefories();
        setRowData({});
      });
  };

  const handleAddNewSubCat = async (image) => {
    // if (!newSubCat.title_ar) {
    //   toast.error("قم بإضافة اسم الفئة الفرعية باللغة العربية أولاً");
    //   return;
    // }
    // if (!newSubCat.title_en) {
    //   toast.error("قم بإضافة اسم الفئة الفرعية باللغة الإنجليزية أولاً");
    //   return;
    // }

    const dataset = {
      ...newSubCat,
      image: image,
    };

    const token = localStorage.getItem("SediHishamAdmindata");
    await axios
      .post(`${BASE_URL}subcategories/add_new?${token}`, dataset)
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success("تم إضافة الفئة الفرعية بنجاح");
          getSubCatefories();
        } else if (res.data == "") {
          toast.error("هناك مشكلة في رفع الصورة");
        } else {
          toast.error("هناك مشكلة في رفع الصورة");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        setShowAddSubCatModal(false);
        setAddLoading(false);
      });
  };

  const handleUpdateCategoryImage = async () => {
    if (!rowData.title_ar) {
      toast.error(" قم بإضافة اسم الفئة باللغة العربية أولاً");
      return;
    }

    if (!rowData.title_en) {
      toast.error(" قم بإضافة اسم الفئة باللغة الإنجليزية أولاً");
      return;
    }

    setUpdateLoading(true);

    console.log(selectedFile);
    console.log(img);
    if (selectedFile) {
      // setImgLoading(true);
      const formData = new FormData();
      formData.append("image", selectedFile);
      await axios
        .post(`${BASE_URL}upload_image`, formData)
        .then((res) => {
          console.log(res);
          if (res.data != "" && res.status == 201) {
            // setImgUrl(res?.data?.message);
            console.log(res?.data?.result?.image);
            handleUpdateSubCat(res?.data?.result?.image);
          } else if (res.data == "") {
            toast.error("هناك مشكلة في رفع الصورة");
          } else {
            toast.error("هناك مشكلة في رفع الصورة");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          // setImgLoading(false);
        });
    } else {
      handleUpdateSubCat(rowData.image);
    }

    setUpdateLoading(false);
  };

  const handleUpdateSubCat = async (image) => {
    // if (!rowData.title_ar) {
    //   toast.error("قم بإضافة اسم الفئة الفرعية باللغة العربية أولاً");
    //   return;
    // }
    // if (!rowData.title_en) {
    //   toast.error("قم بإضافة اسم الفئة الفرعية باللغة الإنجليزية أولاً");
    //   return;
    // }

    const dataset = {
      ...rowData,
      title_ar: rowData.title_ar,
      title_en: rowData.title_en,
      category_id: id,
      id: rowData.id,
      image: image,
    };

    const token = localStorage.getItem("SediHishamAdmindata");

    setEditLoading(true);
    await axios
      .post(
        `${BASE_URL}subcategories/update_sub_category?${token}`,
        dataset
      )
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success("تم تعديل الفئة الفرعية بنجاح");
          getSubCatefories();
        } else if (res.data == "") {
          toast.error("هناك مشكلة في عملية التعديل");
        } else {
          toast.error(" هناك مشكلة في عملية التعديل  ");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setEditLoading(false);
        setUpdateModal(false);
      });
  };

  // filteration

  useEffect(() => {
    if (originalData && originalData.length >= 1) {
      if (searchValue.length > 0) {
        console.log(searchValue);
        const newData = originalData.filter((subCat) => {
          if (
            searchValue.length >= 1 &&
            !subCat.title_ar?.toLowerCase()?.includes(searchValue) &&
            !subCat.title_en?.toLowerCase()?.includes(searchValue)
          ) {
            return false;
          }
          return true;
        });
        setSubCAts(newData);
      } else {
        setSubCAts(originalData);
      }
    }
  }, [searchValue]);

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            {language=='ar'?'أرشيف الفئات الفرعيه':"Subcategory archive"}
            {/* <div onClick={() => setShowAddSubCatModal(true)}>{add}</div>
            <div
              className="btn btn-success"
              onClick={() => navigate('/CategoryProducts/' + category_id)}
            >
              {"المنتجات"}
            </div> */}
            {/* category_id */}
          </div>

          <div className="searchInput field_input">
            <input
              type="text"
              placeholder={language=='ar'?"بحث...":"search..."}
              onChange={(e) => {
                setSearchValue(e.target.value?.toLowerCase());
              }}
            />
          </div>

          {dataLoading ? (
            <Loader />
          ) : (
            <TableLayout headers={subCategoryHeader} data={subCats} />
          )}
        </div>
      </div>

      <PopUp
        onClose={() => {
          console.log('close it');
        }}
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={language=='ar'?"تغيير حالة الظهور":"Change Appearance Status"}
        children={
          <div className="">
            <h5 className=" my-5">
              {language=='ar'?'هل تريد إظهار هذه الفئه الفرعيه':'Do you Want To Show This Subcategory'}
            </h5>

            <button
              onClick={() => (changeStatusloading ? null : handleShow_hide())}
              className="popup_agree_btn"
            >
              {changeStatusloading ? <Loader /> : language=='ar'?"تأكيد":"Confirm"}
            </button>
          </div>
        }
      />

      <PopUp
        open={showAddSubCatModal}
        setOpen={setShowAddSubCatModal}
        title={"إضافة فئة فرعية جديدة "}
        onClose={() => {
          setNewSubCat({
            ...newSubCat,
            title_ar: "",
            title_en: "",
          });

          setSelectedFile(null);
          setImg('');
        }}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddFile();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">اسم الفئة الفرعية باللغة العربية</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewSubCat({
                      ...newSubCat,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">اسم الفئة الفرعية باللغة الإنجليزية</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewSubCat({
                      ...newSubCat,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">اختر صورة الفئة الفرعية</label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image"}
                    className="w-100"
                  >
                    <img
                      width={100}
                      src={
                        img == ""
                          ? "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                          : img
                      }
                      alt=""
                    />
                  </label>
                  <input
                    id={"add-image"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onImageChange(e)}
                  />

                  {img && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setImg("");
                        setSelectedFile(null);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <button className="popup_agree_btn">
              {addLoading ? <Loader /> : "إضافة"}
            </button>
          </form>
        }
      />

      <PopUp
        open={updateModal}
        setOpen={setUpdateModal}
        title={`تعديل الفئة الفرعية : ${rowData.title_ar}`}
        onClose={() => {
          setImg('');
          setSelectedFile(null);
          console.log('closed');
        }}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateCategoryImage();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">اسم الفئة باللغة العربية</label>
                <input
                  type="text"
                  value={rowData.title_ar}
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">اسم الفئة باللغة الإنجليزية</label>
                <input
                  value={rowData.title_en}
                  type="text"
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">اختر صورة الفئة</label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image"}
                    className="w-100"
                  >
                    <img width={100} src={img} alt="" />
                  </label>
                  <input
                    id={"add-image"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onImageChange(e)}
                  />

                  {img && img != rowData.image && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setImg(rowData.image);
                        console.log(rowData.image);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <button className="popup_agree_btn">
              {editLoading ? <Loader /> : "تعديل"}
            </button>
          </form>
        }
      />
    </>
  );
};

export default SubCategoryArchived;
