import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../components/Axios/base_uri";
import toast from "react-hot-toast";
import TableLayout from "../../components/table";
import {
  Menu,
  Preview,
  add,
  edit,
  exitModal,
  eyeOff,
  eyeOn,
} from "../../assets/svgIcons";
import PopUp from "../../components/popup";
import { Loader } from "rsuite";
import SelectWithSearch from "./../../components/selectWithSearch/SelectWithSearch";
import Select from "react-select";
import UseGeneral from "../../customHooks/useGeneral";

const Notifications = () => {
  const { language } = UseGeneral();
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState(null);
  const [users, setUsers] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [searchValue,  setSearchValue] = useState("");
  const [rowData, setRowData] = useState({});
  const [showAddModal, setShowAddModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [changeStatusloading, setChangeStatusLoading] = useState(false);
  const [img, setImg] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [acualDate, setActualDate] = useState(null);

  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const formatDate = (e) => {
    const dateString = e.target.value;
    const pickedDate = new Date(dateString);
    console.log(dateString);
    setActualDate(e.target.value);

    const day = pickedDate.getDate();
    const month = pickedDate.getMonth() + 1;
    const year = pickedDate.getFullYear();
    console.log(`${day}-${month}-${year}`);
    setSelectedDate(`${day}-${month}-${year}`);
  };

  const selectUser = (selectedOption) => {
    setSelectedUser(selectedOption?.value);
  };

  const [newNote, setNewNote] = useState({
    description_ar: "",
    description_en: "", //anser
    title_en: "", // quesiton
    title_ar: "",
  });

  const [lang, setLang] = useState({
    engTitle: false,
    engDes: false,
  });

  const getNotes = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}notifications/get_all_notifications?${token}`,{
        headers:{
          lang:language
        }
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setData(res?.data?.result);
          setOriginalData(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error"||res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language=='ar'?"حدث خطأ ما":"Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };
  const getUsers = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}user/get_users?${token}`,{
        headers:{
          lang:language
        }
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setUsers(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error"||res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language=='ar'?"حدث خطأ ما":"Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getNotes();
    getUsers();
  }, []);

  const header = [
    
    {
      label: language=='ar'?"الأيقونة":'Icon',
      type: "children",
      children: ({ row }) => {
        return (
          <div className="table_row_image">
            <img src={row.icon} alt="image" />
          </div>
        );
      },
    },
    {
      label: language=='ar'?"المستخدم":'User',
      type: "children",
      children: ({ row }) => {
        return <div>{row.user.name}</div>;
      },
    },
    {
      label: language=='ar'?"البريد الإليكتروني":'Email',
      type: "children",
      children: ({ row }) => {
        return <div>{row.user.email}</div>;
      },
    },
    {
      label: language=='ar'?"العنوان":'Title',
   dataIndex: language=='ar'?"title_ar":"title_en",
    },
    {
      label: language=='ar'?"الوصف":'Description',
      dataIndex: "description_ar",
    },
    {
      label: language=='ar'?"تاريخ الإرسال":"Date Of Send",
      dataIndex: "description_ar",
      type: "children",
      children: ({ row }) => {
        return <div>{row.pushed_at}</div>;
      },
    },

    {
      label: language=='ar'?"تاريخ الانشاء":"Date Of Creating",
      dataIndex: "time",
      type: "children",
      children: ({ row }) => {
        return <div>{getOlnlyDate(row.created_at)}</div>;
      },
    },

    // {
    //   label: " أوامر",
    //   type: "children",
    //   children: ({row}) => {
    //     return (
    //       <div className='d-flex align-items-center gap-4'>
    //         <div
    //           className='text-primary'
    //           style={{cursor: "pointer"}}
    //           onClick={() => {
    //             setRowData(row);
    //             setEditModal(true);

    //             console.log(row);
    //           }}
    //         >
    //           <button className="btn btn-success">{language =="ar" ?"تعديل":"Edit"}</button>
    //         </div>
    //       </div>
    //     );
    //   },
    // },
  ];

  const handlePush = async () => {
    console.log("add file");

    const currentDate = new Date("12-1-2003");
    const determinedDate = new Date(acualDate);
    console.log(currentDate);
    console.log(determinedDate);
    console.log(selectedDate?.replaceAll("-", "/"));

    if (!newNote.title_ar) {
      toast.error(language=='ar'?"قم بكتابة العنوان باللغة العربية أولاً":"Enter Arabic Title");
      return;
    }

    if (!newNote.title_en) {
      toast.error(language=='ar'?"قم بكتابة العنوان باللغة الإنجليزية أولاً":"Enter English Title");
      return;
    }

    if (!newNote.description_ar) {
      toast.error(language=='ar'?"قم بكتابة الوصف  باللغة العربية أولاً":"Enter Arabic Description");
      return;
    }

    if (!newNote.description_en) {
      toast.error(language=='ar'?"قم بكتابة الوصف باللغة الإنجليزية أولاً":"Enter Englsh Description");
      return;
    }

    if (!selectedDate) {
      toast.error(language=='ar'?"قم   بتحديد تاريخ الارسال  أولاً":"Enter Date To Send");
      return;
    }

    if (determinedDate < currentDate) {
      toast.error(language=='ar'?"قم   بتحديد تاريخ مستقبلي  أولاً":"Don not enter Date Came Before");
      return;
    }

    if (!selectedUser) {
      toast.error(language=='ar'?"قم باختيار مستخدم أولاً":"Select User");
      return;
    }

    if (selectedFile) {
      setAddLoading(true);
      // setImgLoading(true);
      const formData = new FormData();
      formData.append("image", selectedFile);
      await axios
        .post(`${BASE_URL}upload_image`, formData)
        .then((res) => {
          if (res.data != "" && res.status == 201) {
            handlePushNote(res?.data?.result?.image);
          } else if (res.data == "") {
            toast.error(language=='ar'?"هناك مشكلة في رفع الصورة":"There Are Problem");
          } else {
            toast.error(language=='ar'?"هناك مشكلة في رفع الصورة":"There Are Problem");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          // setImgLoading(false);
          setShowAddModal(false);
          setAddLoading(false);
          setSelectedDate(null);
          setSelectedFile(null);
          setSelectedUser(null);
          setActualDate(null);
          setNewNote({
            description_ar: "",
            description_en: "", //anser
            title_en: "", // quesiton
            title_ar: "",
          });
          setImg("");
          setLang({
            engQ: false,
            engAns: false,
          });
        });
    } else {
      handlePushNote(
        "https://res.cloudinary.com/dbzn1y8rt/image/upload/v1709250237/bell_xv8qzf.png"
      );
    }
  };

  const handlePushNote = async (image) => {
    const dataset = {
      ...newNote,
      pushed_at: selectedDate,
      user_id: selectedUser,
      icon: image,
    };

    console.log(dataset);

    setAddLoading(true);
    const token = localStorage.getItem("SediHishamAdmindata");
    await axios
      .post(`${BASE_URL}notifications/push_to_user?${token}`, dataset,{
        headers:{
          lang:language
        }
      })
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success("تم إرسال الإشعار بنجاح");
          getNotes();
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language=='ar'?'حدث خطأ ما':"Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        setShowAddModal(false);
        setAddLoading(false);
        setNewNote({
          description_ar: "",
          description_en: "", //
          title_en: "", //
          title_ar: "",
        });
        setSelectedUser(null);
        setSelectedDate(null);
        setActualDate(null);
        setSelectedFile(null);
        setImg("");
        setLang({
          engQ: false,
          engAns: false,
        });
      });
  };

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImg(URL.createObjectURL(e.target.files[0]));
      setSelectedFile(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (originalData && originalData.length >= 1) {
      if (searchValue.length > 0) {
        console.log(searchValue);
        const newData = originalData.filter((note) => {
          if (
            searchValue.length >= 1 &&
            !note.title_ar?.toLowerCase()?.includes(searchValue) &&
            !note.title_en?.toLowerCase()?.includes(searchValue) &&
            !note.description_ar?.toLowerCase()?.includes(searchValue) &&
            !note.description_en?.toLowerCase()?.includes(searchValue) &&
            !note.user.name?.toLowerCase()?.includes(searchValue) &&
            !note.user.email?.toLowerCase()?.includes(searchValue) &&
            !(note?.id).toString()?.toLowerCase()?.includes(searchValue)
          ) {
            return false;
          }
          return true;
        });
        setData(newData);
      } else {
        setData(originalData);
      }
    }
  }, [searchValue]);

  return (
    <div className="rowDiv flex-2-1 page_padding">
      <div>
        <div className="title_add">
          <h5> {language=='ar'?'الإشعارات':'Notification'} </h5>
          <div onClick={() => setShowAddModal(true)} className="btn btn-success">
            <img
              src={require('../../assets/add-reminder.png')}
              style={{ cursor: 'pointer' }}
              width={35}
              alt=""
            />
          </div>
        </div>

        <div className="d-flex align-items-center gap-4">
          <div className="searchInput field_input">
            <input
              type="text"
              onWheel={(e) => e.target.blur()}
              placeholder={language=='ar'?"بحث...":"Search...."}
              onChange={(e) => {
                setSearchValue(e.target.value?.toLowerCase());
              }}
            />
          </div>
        </div>

        {dataLoading ? (
          <Loader />
        ) : (
          <TableLayout headers={header} data={data} />
        )}
      </div>

      <PopUp
        open={showAddModal}
        setOpen={setShowAddModal}
        onClose={() => {
          setShowAddModal(false);
          setAddLoading(false);
          setSelectedDate(null);
          setSelectedFile(null);
          setSelectedUser(null);
          setActualDate(null);
          setNewNote({
            description_ar: "",
            description_en: "", //anser
            title_en: "", // quesiton
            title_ar: "",
          });
          setImg("");
          setLang({
            engQ: false,
            engAns: false,
          });
        }}
        title={language=='ar'?"إضافة إشعار جديد":"Add New Notification"}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();

              handlePush();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <div className="d-flex align-items-center gap-4">
                  <label htmlFor="">
                    {language=='ar'?"العنوان باللغة ":"Title In "}{lang.engTitle ? language=='ar'?"الإنجليزية":" english " : language=='ar'?" العربية ":" arabic "}
                  </label>

                  <div class="toggle-switch">
                    <input
                      class="toggle-input"
                      id="ques"
                      type="checkbox"
                      onChange={(e) => {
                        setLang({
                          ...lang,
                          engTitle: e.target.checked,
                        });
                      }}
                    />
                    <label class="toggle-label" for="ques"></label>
                  </div>
                </div>
                <input
                  type="text"
                  value={lang.engTitle ? newNote.title_en : newNote.title_ar}
                  onChange={(e) => {
                    lang.engTitle
                      ? setNewNote({
                          ...newNote,
                          title_en: e.target.value,
                        })
                      : setNewNote({
                          ...newNote,
                          title_ar: e.target.value,
                        });
                  }}
                />
              </div>
              <div className="field_input">
                <div className="d-flex align-items-center gap-4">
                  <label htmlFor="">
                    {language=='ar'?"وصف الإشعار باللغة ":"Notification Description In"} {lang.engDes ? language=='ar'?"الإنجليزية":" english " : language=='ar'?" العربية ":" arabic "}
                  </label>

                  <div class="toggle-switch">
                    <input
                      class="toggle-input"
                      id="desc"
                      type="checkbox"
                      onChange={(e) => {
                        setLang({
                          ...lang,
                          engDes: e.target.checked,
                        });
                      }}
                    />
                    <label class="toggle-label" for="desc"></label>
                  </div>
                </div>
                <input
                  type="text"
                  value={
                    lang.engDes
                      ? newNote.description_en
                      : newNote.description_ar
                  }
                  onChange={(e) => {
                    lang.engDes
                      ? setNewNote({
                          ...newNote,
                          description_en: e.target.value,
                        })
                      : setNewNote({
                          ...newNote,
                          description_ar: e.target.value,
                        });
                  }}
                />
              </div>
              <div className="field_input"></div>

              <div className="field_input">
                <label htmlFor="">{language=='ar'?"تاريخ إرسال الإشعار":"Date Of Sending"}</label>
                <input
                  value={rowData.title_en}
                  type="date"
                  onChange={formatDate}
                />
              </div>

              <div className="react_select">
                <label htmlFor="">{language=='ar'?"إختر مستخدم":"Select User"}</label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  isRtl={true}
                  isSearchable={true}
                  name="color"
                  options={users?.map((user) => {
                    return { value: user?.id, label: user?.name };
                  })}
                  onChange={selectUser}
                />
              </div>

              <div className="field_input">
                <label htmlFor="">{language=='ar'?"اختر أيقونة الإشعار":"Select Icon"}</label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image"}
                    className="w-100"
                  >
                    <img
                      width={100}
                      src={
                        img == ""
                          ? "https://res.cloudinary.com/dbzn1y8rt/image/upload/v1709250237/bell_xv8qzf.png"
                          : img
                      }
                      alt=""
                    />
                  </label>
                  <input
                    id={"add-image"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onImageChange(e)}
                  />

                  {img && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setImg("");
                        setSelectedFile(null);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <button className="popup_agree_btn">
              {addLoading ? <Loader /> : language=='ar'?"إضافة":"Add"}
            </button>
          </form>
        }
      />
    </div>
  );
};

export default Notifications;
