import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import { Axios } from "../../components/Axios";
import { BASE_URL } from "../../components/Axios/base_uri";
import TableLayout from "../../components/table";
import UseGeneral from "../../customHooks/useGeneral";
import PopUp from "./../../components/popup/index";
import "./style.css";

const RejionsPage = () => {
  const { language } = UseGeneral();
  const navigate = useNavigate();
  const [rejions, setRejions] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [showAddCatModal, setShowAddCatModal] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [updateModal, setUpdateModal] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [showAddRejionModal, setShowAddRejionModal] = useState(false);
  const [product_id, set_product_id] = useState({});
  const [loader, setLoader] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [productData, setProductData] = useState({});
  const [newRejion, setNewRejion] = useState({
    title_ar: "",
    title_en: "",
  });

  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const getRejions = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}regions/get_all_for_admin?${token}`, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        if (res.data.status == "success") {
          setRejions(res?.data?.result?.filter((item) => item?.hidden == "0"));
          setOriginalData(
            res?.data?.result?.filter((item) => item?.hidden == "0")
          );
          console.log(res.data.result);
        } else if (res.data.status == "error" || res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == 'ar' ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => {
        setRejions([]);
        console.log(e);
      })
      .finally(() => {
        setDataLoading(false);
      });
  };
  const [finalDraggedRow, setFinalDraggedRow] = useState(null);
  const [c_id, setId] = useState(null);
  const [draggedRow, setDraggedRow] = useState(null);
  useEffect(() => {
    if (finalDraggedRow && draggedRow) {
      console.log(finalDraggedRow, draggedRow);
      const draggedIndex = rejions?.findIndex(
        (item) => item?.id == draggedRow?.id
      );
      const draggedFinalIndex = rejions?.findIndex(
        (item) => item?.id == finalDraggedRow?.id
      );
      const draggedID = rejions?.filter(
        (item) => item?.id == finalDraggedRow?.id
      )[0]?.id
      setArrangeNumber(c_id, draggedFinalIndex, draggedIndex);
      setArrangeNumber(draggedID, draggedFinalIndex+1);
      // setArrangeNumber(
      //   finalDraggedRow?.id,
      //   draggedRow?.number,
      //   finalDraggedRow?.number
      // );
    }
  }, [finalDraggedRow]);
  useEffect(() => {
    if (draggedRow) {
      setCurrentNumber(draggedRow?.number);
      setId(draggedRow?.id);
    }
  }, [draggedRow]);
  const setArrangeNumber = async (video_id, video_number, currentNumberTwo) => {
    // console.log(video_number)
    // console.log(video_id)
    // return
    const num = currentNumberTwo ? currentNumberTwo : currentNumber;
    setLoader(true);
    if (video_number == num) {
      // alert(video_number, num);
      return setLoader(false);
    }
    await Axios({
      method: "POST",
      url: "regions/change_number/" + video_id,
      data: { number: video_number },
    })
      .then(async (response) => {
        console.log(response);
        toast.success(response?.message);
        getRejions();
        setFinalDraggedRow(null);
        setDraggedRow(null);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoader(false);
      });
  };
  // const setArrangeNumber = async (video_id, video_number) => {
  //   console.log(video_number);
  //   console.log(video_id);
  //   // return
  //   setLoader(true);
  //   if (video_number == currentNumber) {
  //     return setLoader(false);
  //   }
  //   await Axios({
  //     method: "POST",
  //     url: "regions/change_number/" + video_id,
  //     data: { number: video_number },
  //   })
  //     .then(async (response) => {
  //       console.log(response);
  //       toast.success(response?.message);
  //       getRejions();
  //     })
  //     .catch((error) => console.log(error))
  //     .finally(() => {
  //       setLoader(false);
  //     });
  // };


  
  useEffect(() => {
    getRejions();
  }, []);

  const rejionsHeaders = [
    // {
    //   label: "Number",
    //   dataIndex: "number",
    //   search: true,
    //   sort: true,
    //   type: "children",
    //   children: ({ headers, row }) => {
    //     const handleBlur = async (product_id, product_number) => {
    //       set_product_id(null);
    //       setArrangeNumber(product_id, product_number);
    //     };

    //     const handleFocus = () => {
    //       setCurrentNumber(row?.number);
    //       set_product_id(row?.id);
    //     };

    //     const handleClick = () => {
    //       set_product_id(row?.id);
    //     };

    //     return (
    //       <div
    //         style={{ width: "fit-content", cursor: "pointer" }}
    //         onClick={handleClick}
    //       >
    //         <input
    //           style={{ width: "120px", cursor: "pointer" }}
    //           type="text"
    //           onChange={(e) =>
    //             setProductData({ id: row?.id, number: e.target.value })
    //           }
    //           onFocus={() => handleFocus()}
    //           defaultValue={
    //             productData?.id == row?.id ? productData?.number : row?.number
    //           }
    //           value={
    //             productData?.id == row?.id ? productData?.number : row?.number
    //           }
    //           disabled={loader}
    //           className={
    //             product_id === row?.id
    //               ? "MR_input_form_element active"
    //               : "MR_input_form_element lazy"
    //           }
    //           onBlur={async (e) => {
    //             await handleBlur(row?.id, e.target.value);
    //           }}
    //         />
    //       </div>
    //     );
    //   },
    // },
    {
      label: language == 'ar' ? "اسم المنطقة" : "Region Name",
      dataIndex: language == "ar" ? "title_ar" : "title_en",
    },
    {
      label: language == 'ar' ? "سعر التوصيل" : "Delivery Prce",
      dataIndex: "price",
    },
    {
      label: language == 'ar' ? "الحالة" : "Status",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
          >
            {row.hidden == "0"
              ? language == 'ar'
                ? "ظاهر"
                : "Showen"
              : language == 'ar'
              ? "مخفي"
              : "Hidden"}
          </div>
        );
      },
    },
    {
      label: language == 'ar' ? " أوامر" : "Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${
                row.hidden == "0" ? "text-success" : "text-danger"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              <button className="btn btn-success">
                {row.hidden == "0"
                  ? language == "ar"
                    ? "إخفاء"
                    : "Hide"
                  : language == "ar"
                  ? "إظهار"
                  : "Show"}
              </button>
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setUpdateModal(true);
                console.log(row);
              }}
            >
              <button className="btn btn-success">
                {language == "ar" ? "تعديل" : "Edit"}
              </button>
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/rejions/${row.id}`, { state: { rejionData: row } });
                console.log(row);
              }}
            >
              <button className="btn btn-success">
                {language == "ar" ? "عرض" : "View"}
              </button>
            </div>
          </div>
        );
      },
    },
  ];

  const handleAddNewRejion = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    const dataset = {
      ...newRejion,
    };

    setAddLoading(true);
    await axios
      .post(`${BASE_URL}regions/add_new?${token}`, dataset, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success(res.data.message);
          getRejions();
          console.log(res.data.result);
        } else if (res.data.status == "error" || res.data.status == "faidl") {
          toast.error(res.data.message);
        } else {
          toast.error(language == 'ar' ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setShowAddRejionModal(false);
        setNewRejion({
          title_ar: "",
          title_en: "",
        });
        setAddLoading(false);
      });
  };

  const handleShow_hide = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    setChangeStatusLoading(true);

    await axios
      .get(`${BASE_URL}regions/change_status/${rowData.id}?${token}`, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success(res.data.message);
          getRejions();
        } else if (res.data.status == "error" || res.data.status == "faidl") {
          toast.error(res.data.message);
        } else {
          toast.error(language == 'ar' ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      });
  };

  const updateRejion = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    const dataset = {
      ...rowData,
    };

    setUpdateLoading(true);
    await axios
      .post(
        `${BASE_URL}regions/update_region/${rowData.id}?${token}`,
        dataset
      )
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success(res.data.message);
          getRejions();
          console.log(res.data.result);
        } else if (res.data.status == "error" || res.data.status == "faidl") {
          toast.error(res.data.message);
        } else {
          toast.error(language == 'ar' ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setUpdateModal(false);
        setRowData({});

        setUpdateLoading(false);
      });
  };

  // filterations
  // filteraiton part

  useEffect(() => {
    if (originalData && originalData.length >= 1) {
      if (searchValue.length > 0) {
        console.log(searchValue);
        const newData = originalData.filter((rej) => {
          if (
            searchValue.length >= 1 &&
            !rej.title_ar.toLowerCase().includes(searchValue.toLowerCase()) &&
            !rej.title_en.toLowerCase().includes(searchValue.toLowerCase()) &&
            !rej.id.toString()?.toLowerCase()?.includes(searchValue)
          ) {
            return false;
          }
          return true;
        });
        setRejions(newData);
      } else {
        setRejions(originalData);
      }
    }
  }, [searchValue]);

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5>{language == 'ar' ? "مناطق الوصول" : "Regions"}</h5>
            <div
              onClick={() => setShowAddRejionModal(true)}
              className="btn btn-success"
            >
              {language == 'ar' ? 'إضافة منطقة' : 'Add Region'}
            </div>
          </div>

          <div className="searchInput field_input">
            <input
              type="text"
              placeholder={language == 'ar' ? "بحث..." : "Search..."}
              onChange={(e) => {
                setSearchValue(e.target.value?.toLowerCase());
              }}
            />
          </div>

          {dataLoading ? (
            <Loader size="md" />
          ) : (
            <TableLayout headers={rejionsHeaders} data={rejions}  finalDraggedRow={finalDraggedRow}
            setFinalDraggedRow={setFinalDraggedRow}
            draggedRow={draggedRow}
            setDraggedRow={setDraggedRow}/>
          )}
        </div>
      </div>

      <PopUp
        open={showAddRejionModal}
        setOpen={setShowAddRejionModal}
        title={language == 'ar' ? "إضافة منطقة جديدة " : "Add New Region"}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">
                  {language == 'ar'
                    ? "اسم المنطقة باللغة العربية"
                    : "Arabic Region Name"}
                </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewRejion({
                      ...newRejion,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {language == 'ar'
                    ? "اسم المنطقة باللغة الانجليزية"
                    : "English Region Name"}
                </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewRejion({
                      ...newRejion,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="field_input">
              <label htmlFor="">
                {language == 'ar' ? "سعر التوصيل" : "Delivery Price"}
              </label>
              <input
                type="text"
                onChange={(e) => {
                  setNewRejion({
                    ...newRejion,
                    price: e.target.value,
                  });
                }}
              />
            </div>
            <button
              onClick={() => {
                if (!addLoading) {
                  handleAddNewRejion();
                }
              }}
              className="popup_agree_btn"
            >
              {addLoading ? <Loader /> : language == 'ar' ? "إضافة" : "Add"}
            </button>
          </form>
        }
      />

      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={
          language == 'ar' ? "تغيير حالة الظهور" : "Change Appearance Status"
        }
        children={
          <div className="">
            <h5 className="">
              {language == 'ar' ? "هل تريد بالفعل " : "Do You Really Want To "}
              {rowData.hidden == "0"
                ? language == 'ar'
                  ? "إخفاء "
                  : " Hide "
                : language == 'ar'
                ? "إظهار "
                : "Show "}{" "}
              {language == 'ar' ? 'هذه المنطقه' : 'This Region'}
            </h5>

            <button
              onClick={() => handleShow_hide()}
              className="popup_agree_btn"
            >
              {changeStatusLoading ? (
                <Loader />
              ) : language == 'ar' ? (
                "تأكيد"
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        }
      />

      <PopUp
        open={updateModal}
        setOpen={setUpdateModal}
        title={`${language == 'ar' ? 'تعديل المنطقة ' : 'Edit Region'}: ${
          language == 'ar' ? rowData.title_ar : rowData.title_en
        }`}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">
                  {language == 'ar'
                    ? 'اسم المنطقة باللغة العربية'
                    : "Arabic Region Name"}
                </label>
                <input
                  type="text"
                  value={rowData.title_ar}
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {language == 'ar'
                    ? 'اسم المنطقة باللغة الإنجليزية'
                    : 'English Region Name'}
                </label>
                <input
                  value={rowData.title_en}
                  type="text"
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="field_input">
              <label htmlFor="">
                {language == 'ar' ? 'سعر التوصيل' : 'Delivery Price'}
              </label>
              <input
                value={rowData.price}
                type="text"
                onChange={(e) => {
                  setRowData({
                    ...rowData,
                    price: e.target.value,
                  });
                }}
              />
            </div>
            <button
              onClick={() => {
                if (!updateLoading) {
                  updateRejion();
                }
              }}
              className="popup_agree_btn"
            >
              {updateLoading ? <Loader /> : language == 'ar' ? "تعديل" : "Edit"}
            </button>
          </form>
        }
      />
    </>
  );
};

export default RejionsPage;
