import moment from "moment";
import React, { useEffect, useState } from "react";
import { eyeOn } from "../../assets/svgIcons";
import { Axios } from "../../components/Axios";
import TableLayout from "../../components/table";
import { jopStatuses } from "./constants";
import PopUp from "../../components/popup";
import toast from "react-hot-toast";
import { Loader } from "rsuite";
import "./style.css";
import UseGeneral from "../../customHooks/useGeneral";
import { calculateAge, formatDate } from "../../utils";
import { BASE_URL } from './../../components/Axios/base_uri';
const Jops = () => {
  const { language } = UseGeneral();
  const [statusFilter, setStatusFilter] = useState("");
  const [dateFilters, setDateFilters] = useState({
    fromDate: null,
    toDate: null,
  });
  const [jopStatus, setJopStatus] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState({
    oldStatus: {
      id: null,
      status: null,
    },
    newStatus: {
      id: null,
      status: null,
    },
  });
  const [open, setOpen] = useState({ open: false });
  const [loading, setLoading] = useState({
    change: false,
  });
  const [jops, setJops] = useState(null);
  const [filteredJops, setFilteredJops] = useState(null);

  const headers = [
    {
      label: language == 'ar' ? "رقم الوظيفة" : "Jop Number",
      dataIndex: "id",
    },
    {
      label: language == 'ar' ? "اسم المتقدم" : "Name",
      dataIndex: "full_name",
    },
    {
      label: language == 'ar' ? "العمر" : "Age",
      dataIndex: "full_name",
    },
    {
      label: language == 'ar' ? "تاريخ الوظيفة" : "Jop Date",
      type: "children",
      children: ({ row }) => {
        const jopDate = new Date(row?.CreatedAt);
        return (
          <p className="text-dark">
            {moment(jopDate).format("YYYY-MM-DD HH:mm:ss")}
          </p>
        );
      },
    },

    {
      label: language == 'ar' ? "حالة الوظيفة" : "Jop status",
      type: "children",
      children: ({ row }) => {
        return (
          <select
            name=""
            id=""
            value={row?.status}
            onChange={(e) =>
              setStatus({
                oldStatus: { id: row?.id, status: row?.status },
                newStatus: { id: row?.id, status: e.target.value },
              })
            }
          >
            {jopStatuses?.map((item) => {
              return (
                <option key={item.id} value={item?.englishLabel}>
                  {language == 'ar' ? item?.label : item?.englishLabel}
                </option>
              );
            })}
          </select>
        );
      },
    },
    {
      label: language == 'ar' ? "تفاصيل الوظيفة" : "Jop Details",
      type: "children",
      children: ({ row }) => {
        return (
          <p
            className="text-dark"
            style={{ cursor: "pointer" }}
            onClick={() => setOpen({ ...open, open: row })}
          >
            <button className="btn btn-success">
              {language == "ar" ? "عرض" : "View"}
            </button>
          </p>
        );
      },
    },
  ];

  const ProductsHeaders = [
    {
      label: language == 'ar' ? "رقم المنتج" : "Product Number",
      dataIndex: "id",
    },
    {
      label: language == 'ar' ? "اسم المنتج" : "Product Name",
      type: "children",
      children: ({ row }) => {
        return <p className="text-dark">{row?.product?.title_ar}</p>;
      },
    },
    {
      label: language == 'ar' ? "الكمية المطلوبة " : "Required Quantity",
      dataIndex: "product_count",
    },
    {
      label: language == 'ar' ? "سعر المنتج " : "Product Price",
      dataIndex: "product_price",
    },
  ];

  useEffect(() => {
    getJops();
  }, []);

  const getJops = async () => {
    Axios({ method: "GET", url: "careers/jobapplication/findall" })
      .then((res) => {
        setJops(res?.reverse());
        setFilteredJops(res?.reverse()); // Set filtered jops initially
      })
      .catch((err) => err);
  };

  const applyFilters = () => {
    let filtered = jops;

    if (statusFilter) {
      filtered = filtered.filter((jop) => jop.jop_status === statusFilter);
    }

    if (dateFilters.fromDate && dateFilters.toDate) {
      filtered = filtered.filter((jop) =>
        moment(jop.created_at).isBetween(
          dateFilters.fromDate,
          dateFilters.toDate
        )
      );
    }

    if (searchQuery) {
      filtered = filtered.filter((jop) => jop.id == searchQuery);
    }

    setFilteredJops(filtered);
  };

  const resetFilters = () => {
    setDateFilters({
      fromDate: null,
      toDate: null,
    });
    setStatusFilter("");
    setSearchQuery("");
    setFilteredJops(jops);
  };

  const changeStatus = () => {
    setLoading({ ...loading, change: true });
    Axios({
      method: "GET",
      url:
        "careers/jobapplication/update/" +
        status?.newStatus?.id +
        "/" +
        status?.newStatus?.status,
      data: {},
    })
      .then((res) => {
        toast.success(res);
        getJops();
        setOpen(null);
      })
      .finally(() => setLoading({ ...loading, change: false }));
  };

  return (
    <>
      <div className="rowDivBtns">
        <h1></h1>
        <h1></h1>
        <h1>{language == "ar" ? "الوظائف" : "Jops"}</h1>
      </div>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <TableLayout headers={headers} data={filteredJops} />
        </div>
      </div>
      <PopUp
        title={language == 'ar' ? "تغيير حالة الوظيفة" : "Change Jop Status"}
        open={status?.newStatus?.id}
        setOpen={() => {
          setStatus({
            oldStatus: {
              id: null,
              status: null,
            },
            newStatus: {
              id: null,
              status: null,
            },
          });
        }}
      >
        <p>
          {language == 'ar'
            ? "هل أنت متأكد من تغيير حالة الوظيفة ؟"
            : "are You Suure To Change this jop status"}
        </p>
        <div className="modalButtons">
          <button
            className="btn btn-success"
            onClick={() => (loading.change ? null : changeStatus())}
          >
            {loading.change ? (
              <Loader />
            ) : language == 'ar' ? (
              " تأكيد"
            ) : (
              "Confirm"
            )}
          </button>
          <button
            className="btn btn-success"
            onClick={() => {
              setStatus({
                oldStatus: {
                  id: null,
                  status: null,
                },
                newStatus: {
                  id: null,
                  status: null,
                },
              });
            }}
          >
            {language == 'ar' ? "إلغاء" : "Cancel"}
          </button>
        </div>
      </PopUp>
      <PopUp
        title={language == 'ar' ? "تفاصيل الوظيفة" : "Jop Details"}
        open={open?.open}
        setOpen={() => {
          setOpen({ ...open, open: false });
        }}
      >
        {open?.open && (
          <>
            <p>
              <span>
                {language === 'ar' ? "رقم الوظيفة:" : "Job Number: "}{" "}
              </span>
              <span>{open?.open?.id}</span>
            </p>
            <p>
              <span>
                {language === 'ar' ? "حالة الوظيفة:" : "Job Status: "}{" "}
              </span>
              <span>{open?.open?.status}</span>
            </p>
            <p>
              <span>
                {language === 'ar' ? "تاريخ الوظيفة:" : "Job Date: "}{" "}
              </span>
              <span>{formatDate(open?.open?.CreatedAt)}</span>
            </p>
            <p>
              <span>{language === 'ar' ? "اسم الوظيفة:" : "Job Title: "} </span>
              <span>{open?.open?.job_management?.name_en}</span>
            </p>
            <p>
              <span>
                {language === 'ar' ? "اسم العميل:" : "Client Name: "}{" "}
              </span>
              <span>{open?.open?.full_name}</span>
            </p>
            <p>
              <span>
                {language === 'ar'
                  ? "رقم تليفون العميل:"
                  : "Client Phone Number: "}{" "}
              </span>
              <span>{open?.open?.phone_number}</span>
            </p>
            <p>
              <span>
                {language === 'ar' ? "عنوان التوصيل:" : "Delivery Address: "}{" "}
              </span>
              <span>{open?.open?.address}</span>
            </p>
            <p>
              <span>
                {language === 'ar' ? "البريد الإلكتروني:" : "Email: "}{" "}
              </span>
              <span>{open?.open?.email}</span>
            </p>
            <p>
              <span>{language === 'ar' ? "العمر:" : "Age: "} </span>
              <span>{calculateAge(open?.open?.birdthday)}</span>
            </p>
            <p>
              <span>{language === 'ar' ? "الملاحظات:" : "Notes: "} </span>
              <span>{open?.open?.notes}</span>
            </p>
            <p>
              <span>
                {language === 'ar'
                  ? "الرسالة التحفيزية:"
                  : "Motivation Letter: "}{" "}
              </span>
              <span>{open?.open?.motivation_lettre}</span>
            </p>
            <p>
              <span>
                {language === 'ar' ? "الحالة العائلية:" : "Family Status: "}{" "}
              </span>
              <span>{open?.open?.family_status}</span>
            </p>
            <p>
              <span>{language === 'ar' ? "الجنسية:" : "Nationality: "} </span>
              <span>{open?.open?.nationalty}</span>
            </p>
            <p>
              <span>
                {language === 'ar' ? "الهوية الوطنية:" : "National ID: "}{" "}
              </span>
              <span>{open?.open?.national_id}</span>
            </p>
            <p>
              <span>
                {language === 'ar' ? "مكان الولادة:" : "Birth Place: "}{" "}
              </span>
              <span>{open?.open?.birth_place}</span>
            </p>
            <p>
              <span>{language === 'ar' ? "الجنس:" : "Gender: "} </span>
              <span>{open?.open?.sex}</span>
            </p>
            <p>
              <span>{language === 'ar' ? "فاكس:" : "Fax: "} </span>
              <span>{open?.open?.fax_phone || 'N/A'}</span>
            </p>
            <p>
              <span>
                {language === 'ar'
                  ? "عنوان السكن الحالي:"
                  : "Current Residence Address: "}{" "}
              </span>
              <span>{open?.open?.address}</span>
            </p>
            <p>
              <span>
                {language === 'ar' ? "تاريخ الإنشاء:" : "Created At: "}{" "}
              </span>
              <span>{formatDate(open?.open?.CreatedAt)}</span>
            </p>
            <p>
              <span>
                {language === 'ar' ? "تاريخ التحديث:" : "Updated At: "}{" "}
              </span>
              <span>{formatDate(open?.open?.updatedAt)}</span>
            </p>
            <p>
              <span>
                {language === 'ar' ? "السيرة الذاتية:" : "CV: "} {" "}
                <a
                  href={BASE_URL+open?.open?.cv_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {language === 'ar' ? "عرض" : "View"}
                </a>
              </span>
            </p>
            <p>
              <span>
                {language === 'ar' ? "إدارة الوظيفة:" : "Job Management: "}{" "}
              </span>
              <span>{open?.open?.job_management?.location}</span>
            </p>
            <p>
              <span>
                {language === 'ar'
                  ? "الإجابات على الأسئلة:"
                  : "Question Answers: "}{" "}
              </span>
              <ul>
                {open?.open?.qst_answers?.map((answer) => (
                  <li key={answer?.id}>
                    <span>{answer?.qst}: </span>
                    <span>{answer?.answer}</span>
                  </li>
                ))}
              </ul>
            </p>
          </>
        )}

        {/* <TableLayout headers={ProductsHeaders} data={open?.open?.jopproducts} /> */}
      </PopUp>
    </>
  );
};

export default Jops;
