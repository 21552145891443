// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import toast from "react-hot-toast";
// import { useNavigate } from "react-router-dom";
// import { Loader } from "rsuite";
// import imageAvatar from "../../assets/image_placeholder.png";
// import { exitModal } from "../../assets/svgIcons";
// import { Axios } from "../../components/Axios";
// import { BASE_URL } from "../../components/Axios/base_uri";
// import TableLayout from "../../components/table";
// import UseGeneral from "../../customHooks/useGeneral";
// import PopUp from "./../../components/popup/index";
// import "./style.css";

// const CategoriesPage = () => {
//   const { language } = UseGeneral();
//   const navigate = useNavigate();
//   const [categories, setCategoreis] = useState(null);
//   const [originalData, setOriginalData] = useState(null);
//   const [showAddCatModal, setShowAddCatModal] = useState(false);
//   const [changeStatusModal, setChangeStatusModal] = useState(false);
//   const [rowData, setRowData] = useState({});
//   const [updateModal, setUpdateModal] = useState(false);
//   const [imgUrl, setImgUrl] = useState("");
//   const [iconUrl , setIconUrl] = useState("");
//   const [allProducts , setAllProducts] = useState([]);
//   const [productData, setProductData] = useState({});
//   const [product_id, set_product_id] = useState({});
//   const [currentNumber, setCurrentNumber] = useState(null);
//   // const [arrangeNumber, setArrangeNumber] = useState(null);
//   const [loader, setLoader] = useState(false);
//   // loader
//   // setCurrentNumber
//   // setProductData
//   //
//   const [searchValue, setSearchValue] = useState("");
//   const [dataLoading, setDataLoading] = useState(false);
//   const [addLoading, setAddLoading] = useState(false);
//   const [updateLoading, setUpdateLoading] = useState(false);
//   const [changeStatusLoading, setChangeStatusLoading] = useState(false);

//   const [newCat, setNewCat] = useState({
//     title_ar: "",
//     title_en: "",
//     color: "ghgdfs",
//   });

//   const [img, setImg] = useState("");
//   const [selectedFile, setSelectedFile] = useState(null);

//   // const getOlnlyDate = (date) => {
//   //   const dateTimeString = date;
//   //   const datePart = dateTimeString?.split("T")[0];
//   //   return datePart;
//   // };

//   function handleGetData() {
//     setDataLoading(true);
//     axios.get(`https://api.camp-coding.site/categories/findall`)
//     .then(res => {
//       console.log(res.data , res.status , res.data.status);
//       if(res.status == 200) {
//          setAllProducts(res.data)
//       }else if(res.status == "faild") {
//         toast.error(res.data.message);
//       }else {
//         toast.error(language == "ar" ? "حدث خطأ ما" : "Something went wrong")
//       }
//     }).finally(() => {
//       setDataLoading(false);
//     })
//     .catch(e => console.log(e))
//   }

//   function handleAddNewCategory() {

//   }

//   useEffect(() => {
//     handleGetData();
//     console.log(allProducts);
//   } , [])

//   // const getCategories = async () => {
//   //   const token = localStorage.getItem("SediHishamAdmindata");

//   //   setDataLoading(true);
//   //   await axios
//   //     .get(`${BASE_URL}categories/get_categories_for_admin?${token}`, {
//   //       headers: {
//   //         lang: language,
//   //       },
//   //     })
//   //     .then((res) => {
//   //       if (res.data.status == "success") {
//   //         setCategoreis(
//   //           res?.data?.result?.filter((item) => item?.hidden == "0")
//   //         );
//   //         setOriginalData(
//   //           res?.data?.result?.filter((item) => item?.hidden == "0")
//   //         );
//   //         console.log(res.data.result);
//   //       } else if (res.data.status == "error") {
//   //         toast.error(res.data.message);
//   //       } else {
//   //         toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
//   //       }
//   //     })
//   //     .catch((e) => console.log(e))
//   //     .finally(() => {
//   //       setDataLoading(false);
//   //     });
//   // };
//   const [finalDraggedRow, setFinalDraggedRow] = useState(null);
//   const [c_id, setId] = useState(null);
//   const [draggedRow, setDraggedRow] = useState(null);

//   // useEffect(() => {
//   //   if (finalDraggedRow && draggedRow) {
//   //     console.log(finalDraggedRow, draggedRow);
//   //     const draggedIndex = categories?.findIndex(
//   //       (item) => item?.id == draggedRow?.id
//   //     );

//   //     const draggedFinalIndex = categories?.findIndex(
//   //       (item) => item?.id == finalDraggedRow?.id
//   //     );
//   //     const draggedID = categories?.filter(
//   //       (item) => item?.id == finalDraggedRow?.id
//   //     )[0]?.id
//   //     setArrangeNumber(c_id, draggedFinalIndex, draggedIndex);
//   //     setArrangeNumber(draggedID, draggedFinalIndex+1);
//   //     // setArrangeNumber(
//   //     //   finalDraggedRow?.id,
//   //     //   draggedRow?.number,
//   //     //   finalDraggedRow?.number
//   //     // );
//   //   }
//   // }, [finalDraggedRow]);
//   // useEffect(() => {
//   //   if (draggedRow) {
//   //     setCurrentNumber(draggedRow?.number);
//   //     setId(draggedRow?.id);
//   //   }
//   // }, [draggedRow]);
//   // const setArrangeNumber = async (video_id, video_number, currentNumberTwo) => {
//   //   // console.log(video_number)
//   //   // console.log(video_id)
//   //   // return
//   //   const num = currentNumberTwo ? currentNumberTwo : currentNumber;
//   //   setLoader(true);
//   //   if (video_number == num) {
//   //     // alert(video_number, num);
//   //     return setLoader(false);
//   //   }
//   //   await Axios({
//   //     method: "POST",
//   //     url: "categories/changer_cat_order/" + video_id,
//   //     data: { number: video_number },
//   //   })
//   //     .then(async (response) => {
//   //       console.log(response);
//   //       toast.success(response?.message);
//   //       getCategories();
//   //       setFinalDraggedRow(null);
//   //       setDraggedRow(null);
//   //     })
//   //     .catch((error) => console.log(error))
//   //     .finally(() => {
//   //       setLoader(false);
//   //     });
//   // };
//   //   useEffect(()=>{
//   // alert(id)
//   //   },[id])

//   useEffect(() => {
//     getCategories();
//   }, []);

//   const categoriesHeader = [
//     // {
//     //   label: "#",
//     //   dataIndex: "id",
//     // },
//     // {
//     //   label: "Number",
//     //   dataIndex: "number",
//     //   search: true,
//     //   sort: true,
//     //   type: "children",
//     //   children: ({ headers, row }) => {
//     //     const handleBlur = async (product_id, product_number) => {
//     //       set_product_id(null);
//     //       setArrangeNumber(product_id, product_number);
//     //     };

//     //     const handleFocus = () => {
//     //       setCurrentNumber(row?.number);
//     //       set_product_id(row?.id);
//     //     };

//     //     const handleClick = () => {
//     //       set_product_id(row?.id);
//     //     };

//     //     return (
//     //       <div
//     //         style={{ width: "fit-content", cursor: "pointer" }}
//     //         onClick={handleClick}
//     //       >
//     //         <input
//     //           style={{ width: "120px", cursor: "pointer" }}
//     //           type="text"
//     //           onChange={(e) =>
//     //             setProductData({ id: row?.id, number: e.target.value })
//     //           }
//     //           onFocus={() => handleFocus()}
//     //           defaultValue={
//     //             productData?.id == row?.id ? productData?.number : row?.number
//     //           }
//     //           value={
//     //             productData?.id == row?.id ? productData?.number : row?.number
//     //           }
//     //           disabled={loader}
//     //           className={
//     //             product_id === row?.id
//     //               ? "MR_input_form_element active"
//     //               : "MR_input_form_element lazy"
//     //           }
//     //           onBlur={async (e) => {
//     //             await handleBlur(row?.id, e.target.value);
//     //           }}
//     //         />
//     //       </div>
//     //     );
//     //   },
//     // },
//     {
//       label: language == "ar" ? "الصورة" : "Image",
//       dataIndex: "image_url",
//       type: "children",
//       children: ({ row }) => {
//         return (
//           <div className="table_row_image">
//             <img src={row.image || imageAvatar} alt="image" />
//           </div>
//         );
//       },
//     },

//     {
//       label: language == "ar" ? "الصورة" : "Icon image",
//       dataIndex: "icon_url",
//       type: "children",
//       children: ({ row }) => {
//         return (
//           <div className="table_row_image">
//             <img src={row.image || imageAvatar} alt="image" />
//           </div>
//         );
//       },
//     },

//     {
//       label: language == "ar" ? "اسم الفئة" : "Category Name",
//       dataIndex: language == "ar" ? "name_ar" : "name_en",
//     },
//     // {
//     //   label: language=='ar'?"اللون":"Color",
//     //dataIndex: language=='ar'?"title_ar":"title_en",
//     //   type: "children",
//     //   children: ({ row }) => {
//     //     if (!row.color) {
//     //       return <div className=" text-danger"> {language=='ar'?"لم تقم باختيار لون ":"You Did Not Select Color"}</div>;
//     //     } else
//     //       return (
//     //         <div
//     //           style={{
//     //             backgroundColor: row.color,
//     //             width: "23px",
//     //             height: "23px",
//     //             borderRadius: "50%",
//     //             border: "1px solid #222",
//     //           }}
//     //         ></div>
//     //       );
//     //   },
//     // },
//     // {
//     //   label: "وقت الانشاء",
//     //   dataIndex: "time",
//     //   type: "children",
//     //   children: ({ row }) => {
//     //     return <div>{getOlnlyDate(row.created_at)}</div>;
//     //   },
//     // },

//     {
//       label: language == "ar" ? "الحالة" : "Status",
//       type: "children",
//       children: ({ row }) => {
//         return (
//           <div
//             className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
//           >
//             {row.hidden == "0"
//               ? language == "ar"
//                 ? "ظاهر"
//                 : "Showen"
//               : language == "ar"
//               ? "مخفي"
//               : "Disappeared"}
//           </div>
//         );
//       },
//     },
//     {
//       label: language == "ar" ? " أوامر" : "actions",
//       type: "children",
//       children: ({ row }) => {
//         return (
//           <div className="d-flex align-items-center gap-4">
//             <div
//               className={`${
//                 row.hidden == "0" ? "text-danger" : "text-success"
//               }`}
//               style={{ cursor: "pointer" }}
//               onClick={() => {
//                 setRowData(row);
//                 setChangeStatusModal(true);
//               }}
//             >
//               <button className="btn btn-success">
//                 {row.hidden == "0"
//                   ? language == "ar"
//                     ? "إخفاء"
//                     : "Hide"
//                   : language == "ar"
//                   ? "إظهار"
//                   : "Show"}
//               </button>
//             </div>
//             <div
//               className="text-primary"
//               style={{ cursor: "pointer" }}
//               onClick={() => {
//                 setRowData(row);
//                 setUpdateModal(true);
//                 setImgUrl(row.image);
//                 setImg(row.image);
//                 console.log(row.image);
//               }}
//             >
//               <button className="btn btn-success">
//                 {language == "ar" ? "تعديل" : "Edit"}
//               </button>
//             </div>
//             <div
//               className="text-primary"
//               style={{ cursor: "pointer" }}
//               onClick={() => {
//                 navigate(`/categories/${row.id}/${row?.id}`, {
//                   state: { categoryData: row },
//                 });
//               }}
//             >
//               <button className="btn btn-success">
//                 {language == "ar" ? "عرض" : "View"}
//               </button>
//             </div>
//           </div>
//         );
//       },
//     },
//     // {
//     //   label: " تعديل",
//     //   type: "children",
//     //   children: ({row}) => {
//     //     return (
//     //       <div
//     //         className='text-primary'
//     //         style={{cursor: "pointer"}}
//     //         onClick={() => {
//     //           setRowData(row);
//     //           setUpdateModal(true);
//     //         }}
//     //       >
//     //         <button className="btn btn-success">{language =="ar" ?"تعديل":"Edit"}</button>
//     //       </div>
//     //     );
//     //   },
//     // },
//   ];

//   // const onImageChange = (e) => {
//   //   if (e.target.files && e.target.files[0]) {
//   //     setImg(URL.createObjectURL(e.target.files[0]));
//   //     setSelectedFile(e.target.files[0]);
//   //   }
//   // };

//   // const handleAddNewCategory = async (imageLink) => {
//   //   const token = localStorage.getItem("SediHishamAdmindata");

//   //   const dataset = {
//   //     ...newCat,
//   //     image: imageLink,
//   //   };

//   //   await axios
//   //     .post(`${BASE_URL}categories/add_new?${token}`, dataset, {
//   //       headers: {
//   //         lang: language,
//   //       },
//   //     })
//   //     .then((res) => {
//   //       if (res?.data && res?.data?.status == "success") {
//   //         toast.success(res.data.message);
//   //         getCategories();
//   //         setShowAddCatModal(false);
//   //       } else if (res.data.status == "error" || res.data.status == "faild") {
//   //         toast.error(res.data.message);
//   //       } else {
//   //         toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
//   //       }
//   //     })
//   //     .catch((e) => console.log(e))
//   //     .finally(() => {
//   //       setNewCat({
//   //         title_ar: "",
//   //         title_en: "",
//   //         color: "",
//   //       });

//   //       setImg("");
//   //       setSelectedFile(null);
//   //     });
//   // };

//   // const handleAddFile = async () => {
//   //   console.log("add file");

//   //   if (!newCat.title_ar) {
//   //     toast.error(
//   //       language == "ar"
//   //         ? " قم بإضافة اسم الفئة باللغة العربية أولاً"
//   //         : "Add Category Arabic Name"
//   //     );
//   //     return;
//   //   }

//   //   if (!newCat.title_en) {
//   //     toast.error(
//   //       language == "ar"
//   //         ? " قم بإضافة اسم الفئة باللغة الإنجليزية أولاً"
//   //         : "Enter Category English name"
//   //     );
//   //     return;
//   //   }

//   //   // if (!img) {
//   //   //   toast.error("لم تقم بإضافة صورة");
//   //   //   return;
//   //   // }

//   //   setAddLoading(true);
//   //   // setImgLoading(true);
//   //   const formData = new FormData();
//   //   formData.append("image", selectedFile);
//   //   await axios
//   //     .post(`${BASE_URL}upload_image`, formData)
//   //     .then((res) => {
//   //       console.log(res);

//   //       if (res.data != "" && res.status == 201) {
//   //         // setImgUrl(res?.data?.message);
//   //         handleAddNewCategory(res?.data?.result?.image);
//   //       } else if (res.data == "") {
//   //         toast.error(
//   //           language == "ar" ? "هناك مشكلة في رفع الصورة" : "There Are Problem"
//   //         );
//   //       } else {
//   //         toast.error(
//   //           language == "ar" ? "هناك مشكلة في رفع الصورة" : "There Are Problem"
//   //         );
//   //       }
//   //     })
//   //     .catch((e) => console.log(e))
//   //     .finally(() => {
//   //       // setImgLoading(false);
//   //       setAddLoading(false);
//   //     });
//   // };

//   // const updateCategoryData = async (updatedImage) => {
//   //   setUpdateLoading(true);

//   //   const token = localStorage.getItem("SediHishamAdmindata");

//   //   const dataset = {
//   //     ...rowData,
//   //     image: updatedImage,
//   //   };
//   //   console.log(dataset);

//   //   await axios
//   //     .post(`${BASE_URL}categories/update_category?${token}`, dataset, {
//   //       headers: {
//   //         lang: language,
//   //       },
//   //     })
//   //     .then((res) => {
//   //       if (res?.data && res?.data?.status == "success") {
//   //         toast.success(res?.data?.message);
//   //         getCategories();
//   //         console.log(res.data.result);
//   //       } else if (res.data.status == "error" || res.data.status == "faild") {
//   //         toast.error(res?.data?.message);
//   //       } else {
//   //         toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
//   //       }
//   //     })
//   //     .catch((e) => console.log(e))
//   //     .finally(() => {
//   //       setUpdateModal(false);
//   //       setRowData({});
//   //       setUpdateLoading(false);
//   //       setImg("");
//   //       setImgUrl("");
//   //       setSelectedFile(null);
//   //     });
//   // };

//   // const handleUpdateCategoryImage = async () => {
//   //   if (!rowData.title_ar) {
//   //     toast.error(
//   //       language == "ar"
//   //         ? " قم بإضافة اسم الفئة باللغة العربية أولاً"
//   //         : "Add Category Arabic Name"
//   //     );
//   //     return;
//   //   }

//   //   if (!rowData.title_en) {
//   //     toast.error(
//   //       language == "ar"
//   //         ? " قم بإضافة اسم الفئة باللغة الإنجليزية أولاً"
//   //         : "Enter Category English name"
//   //     );
//   //     return;
//   //   }
//   //   // if (rowData.color == "") {
//   //   //   toast.error(language=='ar'?" قم بإضافة لون الفئة أولاً":"Select Color First");
//   //   //   return;
//   //   // }

//   //   setUpdateLoading(true);

//   //   if (selectedFile) {
//   //     // setImgLoading(true);
//   //     const formData = new FormData();
//   //     formData.append("image", selectedFile);
//   //     await axios
//   //       .post(`${BASE_URL}upload_image`, formData)
//   //       .then((res) => {
//   //         console.log(res);
//   //         if (res.data != "" && res.status == 201) {
//   //           updateCategoryData(res?.data?.result?.image);
//   //         } else if (res.data == "") {
//   //           toast.error(
//   //             language == "ar"
//   //               ? "هناك مشكلة في رفع الصورة"
//   //               : "There Are Problem"
//   //           );
//   //         } else {
//   //           toast.error(
//   //             language == "ar"
//   //               ? "هناك مشكلة في رفع الصورة"
//   //               : "There Are Problem"
//   //           );
//   //         }
//   //       })
//   //       .catch((e) => console.log(e))
//   //       .finally(() => {
//   //         // setImgLoading(false);
//   //       });
//   //   } else {
//   //     updateCategoryData(rowData.image);
//   //   }

//   //   setUpdateLoading(false);
//   // };

//   // const handleShow_hide = async () => {
//   //   const token = localStorage.getItem("SediHishamAdmindata");

//   //   const dataset = {
//   //     id: rowData.id,
//   //   };

//   //   setChangeStatusLoading(true);

//   //   await axios
//   //     .post(
//   //       `${BASE_URL}categories/update_category_status?${token}`,
//   //       dataset,
//   //       {
//   //         headers: {
//   //           lang: language,
//   //         },
//   //       }
//   //     )
//   //     .then((res) => {
//   //       if (res?.data && res?.data?.status == "success") {
//   //         toast.success(res.data.message);
//   //         getCategories();
//   //       } else if (res.data.status == "error" || res.data.status == "faild") {
//   //         toast.error(res.data.message);
//   //       } else {
//   //         toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
//   //       }
//   //     })
//   //     .catch((e) => console.log(e))
//   //     .finally(() => {
//   //       setChangeStatusModal(false);
//   //       setChangeStatusLoading(false);
//   //       setRowData({});
//   //     });
//   // };

//   // filteraiton part

//   useEffect(() => {
//     if (originalData && originalData.length >= 1) {
//       if (searchValue.length > 0) {
//         console.log(searchValue);
//         const newData = originalData.filter((cat) => {
//           if (
//             searchValue.length >= 1 &&
//             !cat.title_ar?.toLowerCase()?.includes(searchValue) &&
//             !cat.title_en?.toLowerCase()?.includes(searchValue)
//           ) {
//             return false;
//           }
//           return true;
//         });
//         setCategoreis(newData);
//       } else {
//         setCategoreis(originalData);
//       }
//     }
//   }, [searchValue]);
// // ======================---------------------- New Features ------------------------==================
// const [selectedRows, setSelectedRows] = useState(null);
//   const [password, setPassword] = useState(false);
//   const [deletedData, setDeleteData] = useState(false);
// const deleteCategory = async () => {
//   const token = localStorage.getItem("SediHishamAdmindata");
//   setLoader(true);
//   if (selectedRows && selectedRows.length) {
//     console.log(selectedRows);
//     selectedRows.forEach(async (item) => {
//       await axios
//         .get(`${BASE_URL}categories/delete/${item.id}?${token}`)
//         .then((res) => {
//           toast.success(res.message);
//           getCategories();
//         })
//         .catch((e) => console.log(e))
//         .finally(() => {
//           setLoader(false);
//         });
//     });
//   }
// };
// // ======================---------------------- New Features ------------------------==================

//   return (
//     <>
//       <div className="rowDiv flex-2-1 page_padding">
//         <div>
//           <div className="title_add">
//             <h5>{language == "ar" ? "الفئات" : "Categories"}</h5>
//             <div
//               onClick={() => setShowAddCatModal(true)}
//               className="btn btn-success"
//             >
//               {language == "ar" ? "إضافة فئة" : "Add Category"}
//             </div>
//           </div>

//           <div className="searchInput field_input">
//             <input
//               type="text"
//               placeholder={language == "ar" ? "بحث..." : "Search"}
//               onChange={(e) => {
//                 setSearchValue(e.target.value?.toLowerCase());
//               }}
//             />
//           </div>
//           {selectedRows && selectedRows?.length ? (
//             <div className="rowDiv">

//               <button
//                 className="btn btn-danger"
//                 style={{ cursor: "pointer", margin: "20px" }}
//                 onClick={() => {
//                   setDeleteData(true);
//                 }}
//               >
//                 {language == "ar" ? "حذف" : "Delete"}
//               </button>
//             </div>
//           ) : null}
//           <div className="loaderX">
//             {
//               loader ? <div className="loaderOverLay">
//                 <Loader size="lg"/>
//               </div> : null
//             }
//             {dataLoading ? (
//               <Loader size="md" />
//             ) : (
//               <TableLayout
//                 headers={categoriesHeader}
//                 data={allProducts}
//                 // data={categories}
//                 finalDraggedRow={finalDraggedRow}
//                 setFinalDraggedRow={setFinalDraggedRow}
//                 draggedRow={draggedRow}
//                 setDraggedRow={setDraggedRow}
//                 setSelectedRows_t={setSelectedRows}
//               />
//             )}
//           </div>
//         </div>
//       </div>

//       <PopUp
//         open={showAddCatModal}
//         setOpen={setShowAddCatModal}
//         onClose={() => {
//           setImg("");
//           setImgUrl("");
//           setSelectedFile(null);
//         }}
//         title={language == "ar" ? "إضافة فئة " : "Add Category"}
//         children={
//           <form
//             onSubmit={(e) => {
//               e.preventDefault();
//             }}
//           >
//             <div className="inputs">
//               <div className="field_input">
//                 <label htmlFor="">
//                   {language == "ar"
//                     ? "اسم الفئة باللغة العربية"
//                     : "Category Arabic Name"}
//                 </label>
//                 <input
//                   type="text"
//                   onChange={(e) => {
//                     setNewCat({
//                       ...newCat,
//                       title_ar: e.target.value,
//                     });
//                   }}
//                 />
//               </div>
//               <div className="field_input">
//                 <label htmlFor="">
//                   {language == "ar"
//                     ? "اسم الفئة باللغة الإنجليزية"
//                     : "Category English Name"}
//                 </label>
//                 <input
//                   type="text"
//                   onChange={(e) => {
//                     setNewCat({
//                       ...newCat,
//                       title_en: e.target.value,
//                     });
//                   }}
//                 />
//               </div>
//             </div>
//             <div className="d-flex align-items-center justify-content-around mt-3 flex-wrap">
//               {/* <div className="field_input">
//                 <label htmlFor="">{language=='ar'?"لون الفئة":"Category Color"}</label>

//                 <input
//                   value={newCat.color}
//                   type="color"
//                   className="color_picker"
//                   onChange={(e) => {
//                     setNewCat({
//                       ...newCat,
//                       color: e.target.value,
//                     });
//                   }}
//                 />
//               </div> */}
//               <div className="field_input">
//                 <label htmlFor="">
//                   {language == "ar" ? "اختر صورة الفئة" : "Category Image"}
//                 </label>
//                 <div className="position-relative">
//                   <label
//                     style={{ cursor: "pointer" }}
//                     htmlFor={"add-image"}
//                     className="w-100"
//                   >
//                     <img
//                       width={100}
//                       src={
//                         img == ""
//                           ? "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
//                           : img
//                       }
//                       alt=""
//                     />
//                   </label>
//                   <input
//                     id={"add-image"}
//                     type="file"
//                     style={{ display: "none" }}

//                     // onChange={(e) => onImageChange(e)}
//                   />

//                   {img && (
//                     <span
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         setImg("");
//                         setSelectedFile(null);
//                       }}
//                       className="position-absolute"
//                       style={{
//                         top: "10px",
//                         right: "20px",
//                         backgroundColor: "#d50c0cda",
//                         width: "30px",
//                         height: "30px",
//                         borderRadius: "50%",
//                         display: "flex",
//                         justifyContent: "center",
//                         alignItems: "center",
//                         cursor: "pointer",
//                         color: "#FFF",
//                       }}
//                     >
//                       {exitModal}
//                     </span>
//                   )}
//                 </div>
//               </div>
//             </div>

//             <button
//               onClick={() => (addLoading ? null : handleAddFile())}
//               className="popup_agree_btn"
//             >
//               {addLoading ? <Loader /> : language == "ar" ? "إضافة" : "Add"}
//             </button>
//           </form>
//         }
//       />

//       <PopUp
//         open={updateModal}
//         setOpen={setUpdateModal}
//         onClose={() => {
//           setImg("");
//           setImgUrl("");
//           setSelectedFile(null);
//         }}
//         title={`${language == "ar" ? "تعديل الفئة" : "Edit Category"}: ${
//           language == "ar" ? rowData.title_ar : rowData.title_en
//         }`}
//         children={
//           <form
//             onSubmit={(e) => {
//               e.preventDefault();
//             }}
//           >
//             <div className="inputs">
//               <div className="field_input">
//                 <label htmlFor="">
//                   {language == "ar"
//                     ? "اسم الفئة باللغة العربية"
//                     : "Category Arabic Name"}
//                 </label>
//                 <input
//                   type="text"
//                   value={rowData.title_ar}
//                   onChange={(e) => {
//                     setRowData({
//                       ...rowData,
//                       title_ar: e.target.value,
//                     });
//                   }}
//                 />
//               </div>
//               <div className="field_input">
//                 <label htmlFor="">
//                   {language == "ar"
//                     ? "اسم الفئة باللغة الإنجليزية"
//                     : "Category English Name"}
//                 </label>
//                 <input
//                   value={rowData.title_en}
//                   type="text"
//                   onChange={(e) => {
//                     setRowData({
//                       ...rowData,
//                       title_en: e.target.value,
//                     });
//                   }}
//                 />
//               </div>
//             </div>
//             <div className="d-flex align-items-center justify-content-around mt-3 flex-wrap">
//               {/* <div className="field_input">
//                 <label htmlFor="">{language=='ar'?'لون الفئة':"Category Color"}</label>

//                 <input
//                   value={rowData.color}
//                   type="color"
//                   className="color_picker"
//                   onChange={(e) => {
//                     setRowData({
//                       ...rowData,
//                       color: e.target.value,
//                     });
//                   }}
//                 />
//               </div> */}
//               <div className="field_input">
//                 <label htmlFor="">
//                   {language == "ar"
//                     ? "اختر صورة الفئة"
//                     : "Select Category Image"}
//                 </label>
//                 <div className="position-relative">
//                   <label
//                     style={{ cursor: "pointer" }}
//                     htmlFor={"add-image"}
//                     className="w-100"
//                   >
//                     <img width={100} src={img} alt="" />
//                   </label>
//                   <input
//                     id={"add-image"}
//                     type="file"
//                     style={{ display: "none" }}
//                     onChange={(e) => onImageChange(e)}
//                   />

//                   {img && img != rowData.image && (
//                     <span
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         setImg(rowData.image);
//                       }}
//                       className="position-absolute"
//                       style={{
//                         top: "10px",
//                         right: "20px",
//                         backgroundColor: "#d50c0cda",
//                         width: "30px",
//                         height: "30px",
//                         borderRadius: "50%",
//                         display: "flex",
//                         justifyContent: "center",
//                         alignItems: "center",
//                         cursor: "pointer",
//                         color: "#FFF",
//                       }}
//                     >
//                       {exitModal}
//                     </span>
//                   )}
//                 </div>
//               </div>
//             </div>

//             <button
//               onClick={() =>
//                 updateLoading ? null : handleUpdateCategoryImage()
//               }
//               className="popup_agree_btn"
//             >
//               {updateLoading ? <Loader /> : language == "ar" ? "تعديل" : "Edit"}
//             </button>
//           </form>
//         }
//       />
//       <PopUp
//         open={changeStatusModal}
//         setOpen={setChangeStatusModal}
//         title={
//           language == "ar" ? "تغيير حالة الظهور" : "Change Appearance Status"
//         }
//         children={
//           <div className="">
//             <h5 className="">
//               {language == "ar" ? "هل تريد بالفعل " : "Do You Want"}{" "}
//               {rowData.hidden == "0"
//                 ? language == "ar"
//                   ? " إخفاء "
//                   : "Hide"
//                 : language == "ar"
//                 ? " إظهار "
//                 : " Show "}
//               {language == "ar" ? "هذه الفئه" : "This Category"}
//             </h5>

//             <div className="modalButtons">
//               <button
//                 onClick={() => (changeStatusLoading ? null : handleShow_hide())}
//                 className="btn btn-danger"
//               >
//                 {changeStatusLoading ? (
//                   <Loader />
//                 ) : language == "ar" ? (
//                   "تأكيد"
//                 ) : (
//                   "Confirm"
//                 )}
//               </button>
//               <button
//                 onClick={() =>
//                   changeStatusLoading ? null : setChangeStatusModal(false)
//                 }
//                 className="btn btn-success"
//               >
//                 {language == "ar" ? "إلغاء" : "Cancel"}
//               </button>
//             </div>
//           </div>
//         }
//       />
//            <PopUp
//         open={deletedData}
//         setOpen={setDeleteData}
//         title={
//           ""
//         }
//         children={
//           <div className="">
//             <h5 className="">
//               {language == 'ar' ? 'هل تريد بالفعل ' : 'Do You Want'}{" "}
//               {rowData.hidden == "0"
//                 ? language == 'ar'
//                   ? " حذف "
//                   : 'Delete'
//                 : language == 'ar'
//                 ? " حذف "
//                 : " Delete "}
//               {language == 'ar' ? 'هذه المنتجات ' : "This Products"}
//             </h5>
//             <p>{language == "ar" ? "كلمة السر" : "Password"}</p>
//             <input type="text" onChange={(e) => setPassword(e.target.value)} />
//             <button
//               onClick={() =>
//                 password == "*green2024deletepassword*"
//                   ? deleteCategory(true)
//                   : toast.error(
//                       language == "ar"
//                         ? "كلمة السر خاطئة "
//                         : "Password  Is Wrong"
//                     )
//               }
//               className="popup_agree_btn"
//             >
//               {loader ? (
//                 <Loader />
//               ) : language == 'ar' ? (
//                 "تأكيد"
//               ) : (
//                 "Confirm"
//               )}
//             </button>
//           </div>
//         }
//       />{" "}
//     </>
//   );
// };

// export default CategoriesPage;

import React, { useEffect, useState } from 'react';
import TableLayout from '../../components/table';
import axios from 'axios';
import UseGeneral from '../../customHooks/useGeneral';
import PopUp from "./../../components/popup/index";
import toast from 'react-hot-toast';

export default function Categories() {
  const language = UseGeneral();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowAddModal, setIsShowAddModal] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);
  const [iconUrl, setIconUrl] = useState(null);
  const [delItem, setDelItem] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [categoriesData, setCategoriesData] = useState({
    image_url: "",
    name_ar: "",
    name_en: "",
    icon_url: "",
  });
  const [allCategories, setAllCategories] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  const headers = [
    {
      label: "#",
      dataIndex: "id",
    },
    {
      label: language == "ar" ? "Image" : "الصوره",
      dataIndex: "image_url",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="table_row_image">
            <img
              src={`https://api.camp-coding.site/${row.icon_url}`}
              alt="image"
            />
          </div>
        );
      },
    },
    {
      label: language == "ar" ? "اسم الفئة" : "Category Name",
      type: "children",
      children: ({ row }) => {
        return (
          <p>
            {language == "ar"
              ? row.category_translations?.[1]?.name
              : row.category_translations?.[0]?.name}
          </p>
        );
      },
    },
    {
      label: language == "ar" ? "المنتجات" : "Products",
      dataIndex: "products",
      type: "children",
      children: ({ row }) => {
        return <p>{row?.products?.length}</p>;
      },
    },
    {
      label: language == "ar" ? "اخر تحديث" : "Last Update",
      dataIndex: "updatedAt",
      type: "children",
      children: ({ row }) => {
        return <p>{row.updatedAt}</p>;
      },
    },
    {
      label: "أوامر",
      type: "children",
      children: ({ row }) => {
        return (
          <div>
            <button
              onClick={() => {
                setDelItem(row.id);
                setIsShowDeleteModal(true);
              }}
              style={{
                backgroundColor: "red",
                color: "white",
                borderRadius: "5px",
              }}
            >
              <svg
                style={{ width: "20px", height: "15px" }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="white"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                />
              </svg>
            </button>
          </div>
        );
      },
    },
  ];

  function handleGetAllCategories() {
    setIsLoading(true);
    axios
      .get('https://api.camp-coding.site/categories/findall')
      .then((res) => {
        if (res.status == 200) {
          setAllCategories(res.data);
          setOriginalData(res.data);
        } else {
          toast.error(
            res.data.message ||
              (language == "ar" ? "حدث خطأ ما!" : "something went wrong!")
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (imgUrl == null) {
      toast.warn(language == "ar" ? "ادخل صوره أولا!" : "Enter Image First!");
      return;
    }

    if (iconUrl == null) {
      toast.warn(language == "ar" ? "ادخل ايقون أولا!" : "Enter Icon First!");
      return;
    }

    const formData = new FormData();
    formData.append("file", imgUrl);
    const form = e.target;
    setIsLoading(true);
    axios
      .post(
        'https://api.camp-coding.site/categories/upload/category-image',
        formData
      )
      .then((resImg) => {
        if (resImg.status == 201) {
          const formDataIcon = new FormData();
          formDataIcon.append("file", iconUrl);
          setIsLoading(true);
          axios
            .post(
              'https://api.camp-coding.site/categories/upload/category-image',
              formDataIcon
            )
            .then((resIcon) => {
              console.log(resIcon)
              if (resIcon.status == 201) {
                const data_send = {
                  ...categoriesData,
                  image_url: resImg.data.imagePath,
                  icon_url: resIcon.data.imagePath,
                  parent_id: parseInt(categoriesData?.parent_id),
                };
                axios
                  .post(
                    'https://api.camp-coding.site/categories/create',
                    data_send
                  )
                  .then((res) => {
                    if (res.status == 200) {
                      handleGetAllCategories();
                      setIsShowAddModal(false);
                      form?.reset()
                      toast.success(
                        language == "ar"
                          ? "تم الاضافه بنجاح"
                          : "Added Successfully!"
                      );
                      setIconUrl(null);
                      setImgUrl(null);
                      setCategoriesData({
                        name_ar: "",
                        name_en: "",
                        image_url: "",
                        icon_url: "",
                      });
                    } else {
                      toast.error(
                        res.data.message ||
                          (language == "ar"
                            ? "حدث خطأ ما!"
                            : "An error occured!")
                      );
                    }
                  })
                  .finally(() => {
                    setIsLoading(false);
                  })
                  .catch((e) => console.log(e));
              } else {
                toast.error(
                  resIcon.data.message ||
                    (language == "ar"
                      ? "حدث خطأ ما أثناء رفع الايقون!"
                      : "Something went wrong While uploading icon!")
                );
              }
            })
            .finally(() => {
              setIsLoading(false);
            })
            .catch((e) => console.log(e));
        } else {
          toast.error(
            resImg.data.message ||
              (language == "ar"
                ? "حدث خطأ ما أثناء رفع الصوره!"
                : "Something went wrong While uploading image!")
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  }

  function handleDelete() {
    axios
      .get(`https://api.camp-coding.site/categories/delete/${delItem}`)
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          handleGetAllCategories();
        } else {
          toast.error(res.message);
        }
      })
      .finally(() => {
        setIsShowDeleteModal(false);
      })
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    console.log(
      originalData.filter((category) =>
        category.category_translations?.filter((item) => {
          return item.name.toLowerCase().includes(searchValue);
        })
      )?.length
    );
    if (searchValue.length > 0) {
      // const filteredData = allCategories.filter(category => category.category_translations[0].name.toLowerCase().includes(searchValue));
      setAllCategories(
        originalData.filter(
          (category) =>
            category.category_translations?.filter((item) => {
              console.log(item.name);
              return item.name.toLowerCase().includes(searchValue);
            })?.length
        )
      );
    } else {
      setAllCategories(originalData);
    }
  }, [searchValue]);

  useEffect(() => {
    handleGetAllCategories();
  }, []);

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div className="title_add">
          <h5>{language == "ar" ? "الفئات" : "Categories"}</h5>
          <div
            onClick={() => setIsShowAddModal(true)}
            className="btn btn-success"
          >
            {language == "ar" ? "إضافة فئة" : "Add Category"}
          </div>
        </div>

        <div className="searchInput field_input">
          <input
            type="text"
            placeholder={language == "ar" ? "بحث..." : "Search"}
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
          />
        </div>
      </div>

      <PopUp
        open={isShowAddModal}
        setOpen={setIsShowAddModal}
        onClose={() => {}}
        title={language == "ar" ? "اضافه فئة" : "Add Category"}
      >
        <form onSubmit={handleSubmit}>
          <div className="inputs">
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <div className="field_input">
                <label>Category Name</label>
                <input
                  type="text"
                  value={categoriesData.name_en}
                  placeholder="Category Name"
                  onChange={(e) =>
                    setCategoriesData({
                      ...categoriesData,
                      name_en: e.target.value,
                    })
                  }
                />
              </div>

              <div className="field_input">
                <label>اسم الفئة</label>
                <input
                  type="text"
                  value={categoriesData.name_ar}
                  placeholder="اسم الفئه"
                  onChange={(e) =>
                    setCategoriesData({
                      ...categoriesData,
                      name_ar: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="field_input col-md-6">
              <label htmlFor="">
                {language == 'ar' ? 'الفئة' : 'Category'}
              </label>
              <select
                name=""
                id=""
                onChange={(e) => {
                  setCategoriesData({
                    ...categoriesData,
                    parent_id: parseInt(e.target.value),
                  });
                }}
              >
                {allCategories?.map((item) => {
                  return (
                    <option value={parseInt(item?.id)}>
                      {language == "ar"
                        ? item?.category_translations[1]?.name
                        : item?.category_translations[0]?.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="field_input">
              <label>Image Url</label>
              <input
                type="file"
                accept="images/*"
                onChange={(e) => setImgUrl(e.target.files[0])}
              />
            </div>

            <div className="field_input">
              <label>Icon Url</label>
              <input
                type="file"
                accept="images/*"
                onChange={(e) => setIconUrl(e.target.files[0])}
              />
            </div>

            <div style={{ display: "flex", gap: "10px" }}>
              <button className="popup_agree_btn">Add</button>
              <button
                onClick={() => setIsShowAddModal(false)}
                style={{
                  padding: "5px 10px",
                  marginTop: "20px",
                  borderRadius: "3px",
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </PopUp>

      <PopUp open={isShowDeleteModal} setOpen={setIsShowDeleteModal}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {/* <img
            style={{ width: "120px", height: "120px", margin: "5px auto" }}
            src="/warning.png"
          /> */}
          <h4 style={{ textAlign: "center" }}>
            {language == "ar"
              ? "هل تريد مسح هذا العنصر؟"
              : "Are you sure you want to delete this item?"}
          </h4>
          <div
            style={{
              display: "flex",
              margin: "0px auto",
              gap: "10px",
              justifyContent: "between",
            }}
          >
            <button
              onClick={() => setIsShowAddModal(false)}
              style={{
                padding: "5px 10px",
                color: "white",
                marginTop: "20px",
                borderRadius: "3px",
                backgroundColor: "#0388CC",
              }}
            >
              Cancel
            </button>
            <button
              style={{
                padding: "5px 10px",
                color: "white",
                marginTop: "20px",
                borderRadius: "3px",
                backgroundColor: "red",
              }}
              onClick={handleDelete}
            >
              Delete
            </button>
          </div>
        </div>
      </PopUp>
      <TableLayout headers={headers} data={allCategories} />
    </>
  );
}
