import React, { useState } from 'react';
import './style.css'; // Import CSS file for styling
import UseGeneral from '../../customHooks/useGeneral';

function DashboardHeader() {
  const { userData, logOut, language } = UseGeneral();

  return (
    <div className="dashboard-header">
      {/* <div className="header-logo">
        <input type="text" placeholder={language=='ar'?"بحث  ":"Search..."} />
      </div> */}
      <div className="dropdown profile">
        <span>{userData?.data?.role}</span>
        <div
          className={
            language == 'ar' ? "dropdown-content" : "dropdown-content rev"
          }
        >
          <ul>
            <li
              className="btn btn-danger"
              style={{ fontSize: "12px" }}
              onClick={() => logOut()}
            >
              {language == 'ar' ? 'تسجيل الخروج' : 'log out'}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default DashboardHeader;
