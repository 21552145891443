import moment from "moment";
import React, { useEffect, useState } from "react";
import { Grid, Row, Col } from 'rsuite';
import { Panel } from 'rsuite';
import { Axios } from "../../components/Axios";
import TableLayout from "../../components/table";
import { statuses } from "./constants";
import PopUp from "../../components/popup";
import toast from "react-hot-toast";
import { Loader } from "rsuite";
import "./style.css";
import UseGeneral from "../../customHooks/useGeneral";
import { BASE_URL } from './../../components/Axios/base_uri';
const Orders = ({ hideFilters }) => {
  const { language } = UseGeneral();
  const [statusFilter, setStatusFilter] = useState("");
  const [dateFilters, setDateFilters] = useState({
    fromDate: null,
    toDate: null,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState({
    oldStatus: {
      id: null,
      status: null,
    },
    newStatus: {
      id: null,
      status: null,
    },
  });
  const [open, setOpen] = useState({ open: false });
  const [loading, setLoading] = useState({
    change: false,
  });
  const [orders, setOrders] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState(null);

  const headers = [
    {
      label: language == 'ar' ? "رقم الطلب" : "Order Number",
      dataIndex: "id",
    },
    {
      label: language == 'ar' ? "تاريخ الطلب" : "Order Date",
      type: "children",
      children: ({ row }) => {
        const orderDate = new Date(row?.CreatedAt);
        return (
          <p className="text-dark">
            {moment(orderDate).format("YYYY-MM-DD HH:mm:ss")}
          </p>
        );
      },
    },
    {
      label: language == 'ar' ? " اسم العميل" : "Customer Name",
      type: "children",
      children: ({ row }) => {
        return <p className="text-dark">{row?.receiver_name}</p>;
      },
    },
    {
      label: language == 'ar' ? "رقم تليفون  العميل" : "Customer Phone",
      type: "children",
      children: ({ row }) => {
        return <p className="text-dark">{row?.receiver_phone}</p>;
      },
    },
    {
      label: language == 'ar' ? "حالة الطلب" : "Order status",
      type: "children",
      children: ({ row }) => {
        return (
          <select
            name=""
            id=""
            value={row?.order_status}
            onChange={(e) =>
              setStatus({
                oldStatus: { id: row?.id, status: row?.order_status },
                newStatus: { id: row?.id, status: e.target.value },
              })
            }
          >
            {statuses?.map((item) => {
              return (
                <option key={item.id} value={item?.englishLabel}>
                  {language == 'ar' ? item?.label : item?.englishLabel}
                </option>
              );
            })}
          </select>
        );
      },
    },
    {
      label: language == 'ar' ? "تفاصيل الطلب" : "Order Details",
      type: "children",
      children: ({ row }) => {
        return (
          <p
            className="text-dark"
            style={{ cursor: "pointer" }}
            onClick={() => setOpen({ ...open, open: row })}
          >
            <button className="btn btn-success">
              {language == "ar" ? "عرض" : "View"}
            </button>
          </p>
        );
      },
    },
  ];

  const ProductsHeaders = [
    {
      label: language == 'ar' ? "رقم المنتج" : "Product Number",
      dataIndex: "id",
    },
    {
      label: language == 'ar' ? "اسم المنتج" : "Product Name",
      type: "children",
      children: ({ row }) => {
        return <p className="text-dark">{row?.product?.title_ar}</p>;
      },
    },
    {
      label: language == 'ar' ? "الكمية المطلوبة " : "Required Quantity",
      dataIndex: "product_count",
    },
    {
      label: language == 'ar' ? "سعر المنتج " : "Product Price",
      dataIndex: "product_price",
    },
  ];

  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = async () => {
    try {
      const response = await Axios({ method: "GET", url: "orders/findAll" });
      const orders = response;
      orders.sort((a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt));
      setOrders(orders);
      setFilteredOrders(orders);
    } catch (err) {
      console.error(err);
      setOrders([]);
    }
  };

  const applyFilters = () => {
    let filtered = orders;

    if (statusFilter) {
      filtered = filtered.filter(
        (order) => order.order_status === statusFilter
      );
    }

    if (dateFilters.fromDate && dateFilters.toDate) {
      filtered = filtered.filter((order) =>
        moment(order.created_at).isBetween(
          dateFilters.fromDate,
          dateFilters.toDate
        )
      );
    }

    if (searchQuery) {
      filtered = filtered.filter((order) => order.id == searchQuery);
    }

    setFilteredOrders(filtered);
  };

  const resetFilters = () => {
    setDateFilters({
      fromDate: null,
      toDate: null,
    });
    setStatusFilter("");
    setSearchQuery("");
    setFilteredOrders(orders);
  };

  const changeStatus = () => {
    setLoading({ ...loading, change: true });
    Axios({
      method: "GET",
      url:
        "orders/updatestate/" +
        status?.newStatus?.id +
        "/" +
        status?.newStatus?.status,
      data: {},
    })
      .then((res) => {
        toast.success(res.message);
        getOrders();
        setOpen(null);
      })
      .finally(() => setLoading({ ...loading, change: false }));
  };

  return (
    <>
      {hideFilters ? null : (
        <>
          <div className="data_filters">
            <div className="filter-item">
              <label htmlFor="searchInput">
                {language == 'ar'
                  ? "ابحث عن الطلب:"
                  : "Search About Order Here"}
              </label>
              <input
                type="text"
                id="searchInput"
                placeholder={
                  language == 'ar'
                    ? "ابحث عن الطلب:"
                    : "Search About Order Here"
                }
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="filter-item">
              <label htmlFor="statusSelect">
                {language == 'ar' ? "حالة الطلب:" : "Order Status: "}
              </label>
              <select
                id="statusSelect"
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                <option value="">{language == 'ar' ? "الكل" : "All"}</option>
                {statuses.map((status) => (
                  <option key={status.id} value={status.englishLabel}>
                    {language == 'ar' ? status.label : status.englishLabel}
                  </option>
                ))}
              </select>
            </div>
            <div className="filter-item">
              <label htmlFor="fromDateInput">
                {language == 'ar' ? "من تاريخ:" : "From Date : "}
              </label>
              <input
                type="date"
                id="fromDateInput"
                value={dateFilters.fromDate}
                onChange={(e) =>
                  setDateFilters({ ...dateFilters, fromDate: e.target.value })
                }
              />
            </div>
            <div className="filter-item">
              <label htmlFor="toDateInput">
                {language == 'ar' ? "إلى تاريخ:" : "To Date"}
              </label>
              <input
                type="date"
                id="toDateInput"
                value={dateFilters.toDate}
                onChange={(e) =>
                  setDateFilters({ ...dateFilters, toDate: e.target.value })
                }
              />
            </div>{" "}
          </div>
          <div className="rowDivBtns">
            <button className="btn btn-success" onClick={applyFilters}>
              {language == 'ar' ? "تطبيق الفلتر" : "Make Filtration"}
            </button>
            <button className="btn btn-danger" onClick={resetFilters}>
              {language == 'ar' ? "إلغاء الفلتر" : "Cancle Filtration"}
            </button>
          </div>
        </>
      )}
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <TableLayout headers={headers} data={filteredOrders} />
        </div>
      </div>
      <PopUp
        title={language == 'ar' ? "تغيير حالة الطلب" : "Change Order Status"}
        open={status?.newStatus?.id}
        setOpen={() => {
          setStatus({
            oldStatus: {
              id: null,
              status: null,
            },
            newStatus: {
              id: null,
              status: null,
            },
          });
        }}
      >
        <p>
          {language == 'ar'
            ? "هل أنت متأكد من تغيير حالة الطلب ؟"
            : "are You Suure To Change this order status"}
        </p>
        <div className="modalButtons">
          <button
            className="btn btn-success"
            onClick={() => (loading.change ? null : changeStatus())}
          >
            {loading.change ? (
              <Loader />
            ) : language == 'ar' ? (
              " تأكيد"
            ) : (
              "Confirm"
            )}
          </button>
          <button
            className="btn btn-success"
            onClick={() => {
              setStatus({
                oldStatus: {
                  id: null,
                  status: null,
                },
                newStatus: {
                  id: null,
                  status: null,
                },
              });
            }}
          >
            {language == 'ar' ? "إلغاء" : "Cancel"}
          </button>
        </div>
      </PopUp>

      <PopUp
        title={language === 'ar' ? "تفاصيل الطلب" : "Order Details"}
        open={open?.open}
        setOpen={() => {
          setOpen({ ...open, open: false });
        }}
      >
        <p>
          <span>{language === 'ar' ? "رقم الطلب:" : "Order Number: "} </span>
          <span>{open?.open?.id}</span>
        </p>
        <p>
          <span>{language === 'ar' ? "حالة الطلب:" : "Order Status : "} </span>
          <span>{open?.open?.order_status}</span>
        </p>
        <p>
          <span>{language === 'ar' ? "تاريخ الطلب:" : "Order Date : "} </span>
          <span>
            {moment(open?.open?.createdAt).format("YYYY-MM-DD HH:MM:SS")}
          </span>
        </p>
        <p>
          <span>{language === 'ar' ? "سعر الطلب:" : "Order Total Price:"}</span>
          <span>{open?.open?.total}</span>
        </p>{" "}
        <p>
          <span>
            {language === 'ar' ? "تعليق العميل:" : "Customer Comment: "}{" "}
          </span>
          <span>{open?.open?.customer_comment}</span>
        </p>
        <p>
          <span>{language === 'ar' ? "اسم العميل:" : "Customer Name: "} </span>
          <span>{open?.open?.receiver_name}</span>
        </p>
        <p>
          <span>
            {language === 'ar'
              ? "رقم تليفون العميل:"
              : "Customer Phone Number:"}{" "}
          </span>
          <span>{open?.open?.receiver_phone}</span>
        </p>
        <p>
          <span>
            {language === 'ar' ? 'عنوان التوصيل:' : 'Delivery Address:'}{" "}
          </span>
          <span>{open?.open?.shipping_add}</span>
        </p>{" "}
        <p>
          <span>{language === 'ar' ? 'سعر التوصيل:' : 'Delivery Price:'} </span>
          <span>{open?.open?.shipping_cost}</span>
        </p>
        {/* <p>
          <span>{language === 'ar' ? 'سعر التوصيل:' : 'Delivery Price:'} </span>
          <span>{open?.open?.shipping_cost}</span>
        </p> */}
        <p>
          <span>{language === 'ar' ? 'نوع الدفع:' : 'Pay Method:'} </span>
          <span>{open?.open?.payment_way}</span>
        </p>
        <Grid fluid>
          <Row gutter={16}>
            {open?.open?.order_items
              ?.sort((a, b) => parseInt(b?.product?.price) - parseInt(a?.product?.price))
              ?.map((item) => (
                <Col xs={24} sm={12} md={8} lg={6} key={item.id}>
                  <Panel
                    shaded
                    bordered
                    bodyFill
                    style={{ display: 'inline-block', width: '100%' }}
                  >
                    <img
                      src={BASE_URL + item.product_image}
                      alt={[item.product.product_translations[0]?.nameProduct]}
                      style={{
                        width: '100%',
                        height: '140px',
                        objectFit: "contain",
                      }}
                    />
                    <Panel
                      header={item.product.product_translations[1]?.nameProduct}
                    >
                      <p>
                        <b>
                          {language === 'ar' ? "الكمية:" : "Quantity:"}{" "}
                          {item.quantity}
                        </b>
                        <br />
                        <b>
                          {language === 'ar' ? "السعر:" : "Price:"}{" "}
                          {item.product.price}
                        </b>
                      </p>
                      {item.feature_names ? (
                        <>
                          {" "}
                          {item.feature_names.length > 0 && (
                            <p>
                              <small>
                                {language === 'ar' ? "ميزات:" : "Features:"}{" "}
                                {item.feature_names
                                  .map((f) => f.name)
                                  .join(', ')}
                              </small>
                            </p>
                          )}
                          <hr />
                        </>
                      ) : null}
                    </Panel>
                  </Panel>
                </Col>
              ))}
          </Row>
        </Grid>
      </PopUp>
    </>
  );
};

export default Orders;
